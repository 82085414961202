import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Web3 } from 'web3';
//import { useTranslation } from 'react-i18next';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Modal from 'react-bootstrap/Modal';
import BackButton from './Common/BackButton.js';
import moment from 'moment';
import { Table, Column, AutoSizer, InfiniteLoader } from 'react-virtualized';
import 'react-virtualized/styles.css';
import CancelButton from './Common/CancelButton.js';

// To implement the socket listeners here
import { socket } from './Config/Socket.js';
const Request = require('./Config/Request.js');



//xport default function AllBlockchain(props) {
const Explorer = (props) => {
    const { blockchainId, projectId } = useParams();
    let [errorCode, setErrorCode] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    let [transactions, setTransactions] = useState([]);
    let [blocks, setBlocks] = useState([]);
    let [events, setEvents] = useState([]);
    let [totalNodes, setTotalNodes] = useState(null);
    let [totalSmartContracts, setTotalSmartContracts] = useState(null);
    let [expandedBlock, setExpandedBlock] = useState(null);
    let [expandedTx, setExpandedTx] = useState(null);
    let [dataToView, setDataToView] = useState(null);
    let [project, setProject] = useState(null);
    let [expandedEvent,setExpandedEvent] = useState(null);
    //const { t, i18n } = useTranslation();

    useEffect(() => {
        if ( blockchainId && projectId ){
            props.setBlockchain(blockchainId);
            props.setProject(projectId);
        }
    }, [blockchainId,projectId]);

    const getApp = async () => {
        try {
            let app = await Request.h64HttpRequest("GET", "/getApplication/" + projectId, {});
            return app.application;
        } catch (err) {
            console.error(err);
        }
    }

    const hideExpandedEvent = () => {
        setExpandedEvent(null);
    }

    useEffect(() => {
        try {
            const fetch = async () => {
                let theProject = await getApp();
                if ( theProject ){
                    setProject(theProject);
                    console.log(">> " + JSON.stringify(theProject));

                    if ( theProject.production ) {

                        let blockchains = await setBlockchainToView();
                        let theEvents = await getProjectEvents();
                        let transactions = [];
                        if ( theEvents )
                            setEvents(theEvents);
                        if (typeof blockchains.blocks !== "undefined")
                            for (let i = 0, l = blockchains.blocks.length; i < l; ++i)
                                if (typeof blockchains.blocks[i].transactions !== "undefined")
                                    for (let j = 0, k = blockchains.blocks[i].transactions.length; j < k; ++j)
                                        transactions.push(blockchains.blocks[i].transactions[j]);
                        setTransactions(transactions);
                        setBlocks(blockchains.blocks);
                        setTotalNodes(blockchains.totalNodes);
                        setTotalSmartContracts(blockchains.totalSmartContracts);

                    } else {

                        let theEvents = await getProjectEvents();
                        if ( theEvents )
                            setEvents(theEvents);

                    }

                }

            }
            fetch();

        } catch (err) {
            console.error(err);
        }

    }, []);

    let hideDataToView = () => {
        setDataToView(null);
    }

    let optionsTx = {
        clickToEdit: true,
        clickToSelect: true,
        onRowClick: (row) => {
            setExpandedTx(row);
        }
    };

    let hideExpandedBlock = () => {
        setExpandedBlock(null);
    }

    let hideExpandedTx = () => {
        setExpandedTx(null);
    }

    let setBlockchainToView = async () => {
        try {
            let logs = await Request.h64HttpRequest("GET", "/getLatestBlockchainLogs/" + blockchainId, {});
            return logs.logs;
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    const getProjectEvents = async () => {
        try {
            let theEvents = await Request.h64HttpRequest("GET", "/getProjectEvents/" + projectId, {});
            if ( theEvents )
                if ( theEvents.errorCode !== undefined && theEvents.errorMessage !== undefined ) {
                    setErrorCode(theEvents.errorCode);
                    setErrorMessage(theEvents.errorMessage);
                    if ( theEvents.events ) 
                        return theEvents.events;
                }
        } catch (err) {
            console.error(err);
        }
    }

    const loadMoreEvents = async () => {
        try {
            let theEvents = await Request.h64HttpRequest("GET", "/getProjectEvents/" + projectId, {});
            if ( theEvents )
                if ( theEvents.errorCode !== undefined && theEvents.errorMessage !== undefined ) {
                    setErrorCode(theEvents.errorCode);
                    setErrorMessage(theEvents.errorMessage);
                    if ( theEvents.events ) 
                        setEvents([ ...events , theEvents.events ]);
                }
        } catch (err) {
            console.error(err);
        }
    }

    let getBlockTimestamp = (block) => {
        try {
            for (let i = 0, l = blocks.length; i < l; ++i)
                if (Number(blocks[i].number) === Number(block))
                    return blocks[i].timestamp;
        } catch (err) {
            console.error(err);
        }
    }

    const parseReturnedValues = (returnedValues) => {
        for ( let i in returnedValues )
            if ( typeof returnedValues[i] === "object" )
                if ( returnedValues[i] )
                    if ( returnedValues[i]._hex !== undefined )
                        returnedValues[i] = parseInt(returnedValues[i]._hex);
        return JSON.stringify(returnedValues);
    }

    let momentTransactionCreationDate = (cell, row) => {
        try {
            if (row)
                return <>{moment(getBlockTimestamp(row.blockNumber)).format("L HH:mm:ss")}</>
            return <></>;
        } catch (err) {
            console.error(err);
        }
    }

    let rowClicked = (event) => {
        setDataToView(event.rowData);
    }

    const eventRowClicked = (event) => {
        setExpandedEvent(event.rowData);
    }

    let loadMore = async () => {
        try {
            if (typeof blocks[blocks.length - 1] !== "undefined" && typeof blocks[blocks.length - 1].number !== "undefined") {
                let theBlocks = await Request.h64HttpRequest("GET", "/getCustomLatestBlockchainLogs/" + blockchainId + "/" + blocks[blocks.length - 1].number, {});
                if (typeof theBlocks.blocks !== "undefined") {
                    let newBlocks = [], newTransactions = [];
                    for (let i = 0, l = theBlocks.blocks.length; i < l; ++i) {
                        newBlocks.push(theBlocks.blocks[i]);
                        if (typeof theBlocks.blocks[i].transactions !== "undefined")
                            for (let j = 0, k = theBlocks.blocks[i].transactions.length; j < k; ++j)
                                newTransactions.push(theBlocks.blocks[i].transactions[j]);
                    }
                    setBlocks(blocks => [...blocks, ...newBlocks]);
                    setTransactions(transactions => [...transactions, ...newTransactions]);
                }
            }
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    return (
        <>
            <div className="page-header m-0 p-0 w-100 d-flex">
                <div className="w-100 page-header-title">Explorer</div>
            </div>

            <div className="container mt-2">

                <div className="mb-1 text-mini-1 pb-4 pt-4" >
                    <div className="row">
                        <div className="col-sm-2">
                            <BackButton />
                        </div>
                        <div className="col-sm-8 ml-4">
                            {blockchainId} <i className="fas fa-chevron-right ml-2 mr-2"></i> {projectId}
                            <div className="text-maxi-1" >
                                {
                                    project && 
                                    <>
                                        {
                                            project.production ? (
                                                <>
                                                    Blockchain history
                                                </>
                                            ) : (
                                                <>
                                                    Project history           
                                                </>
                                            )
                                        }   
                                    </>
                                }
                        </div>
                        </div>
                    </div>
                </div>

                <div className={"mt-5 mb-5" + (errorCode === null && errorMessage === null ? " d-none" : "")}>
                    <div className="alert alert-danger" role="alert">{errorMessage}</div>
                </div>

                {
                    project && 
                    <>
                        {
                            project.production ? (
                                <>
                                    <div className="m-0 w-100 pb-5">
                                        <div className="row justify-content-center text-center">


                                        <div className="col-sm-4 mt-3">
                                            <div className="card-explorer-infos mx-auto ">
                                                <p className="bc-title ">Last block</p>
                                                <p className="bc-subtitle-xl">
                                                    {
                                                        blocks && blocks.length > 0 &&
                                                        <>{blocks[0].number}</>
                                                    }
                                                </p>
                                            </div>
                                        </div>


                                            <div className="col-sm-4 mt-3">
                                                <div className="card-explorer-infos mx-auto ">
                                                    <p className="bc-title ">My events</p>
                                                    <p className="bc-subtitle-xl">
                                                        {
                                                            events &&
                                                            <>
                                                                <strong>
                                                                    {events.length}
                                                                </strong>
                                                            </>
                                                        }
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-4 mt-3">
                                                <div className="card-explorer-infos mx-auto ">
                                                    <p className="bc-title ">My contracts</p>
                                                    <p className="bc-subtitle-xl">

                                                        {
                                                            totalSmartContracts &&
                                                            <>{totalSmartContracts}</>
                                                        }
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                
                                </>
                            )
                        }
                    </>
                }

            </div>


            {
                project && 
                <>
                    {
                        project.production ? (
                            <>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="text-center bc-title" >Blocks</div>
                                            <InfiniteLoader
                                                isRowLoaded={({ index }) => blocks[index]}
                                                loadMoreRows={loadMore}
                                                rowCount={Number.MAX_SAFE_INTEGER}
                                            >
                                                {({ onRowsRendered, registerChild }) => (
                                                    <AutoSizer>
                                                        {({ width }) => (
                                                            <Table
                                                                ref={registerChild}
                                                                onRowsRendered={onRowsRendered}
                                                                rowClassName='text-dark mt-1 mb-1'
                                                                headerHeight={80}
                                                                width={width}
                                                                height={370}
                                                                onRowClick={rowClicked}
                                                                rowHeight={50}
                                                                rowCount={blocks.length}
                                                                rowGetter={({ index }) => blocks[index]}
                                                            >
                                                                <Column
                                                                    label='Blocks'
                                                                    dataKey='number'
                                                                    width={width * 0.3}
                                                                />
                                                                <Column
                                                                    label='Hash'
                                                                    dataKey='hash'
                                                                    width={width * 0.3}
                                                                />
                                                            </Table>
                                                        )}
                                                    </AutoSizer>
                                                )}
                                            </InfiniteLoader>
                                        </div>
                                        
                                        <div className="col-sm-4">
                                            <div className="text-center bc-title" >Transactions</div>
                                            <InfiniteLoader
                                                isRowLoaded={({ index }) => transactions[index]}
                                                loadMoreRows={loadMore}
                                                rowCount={Number.MAX_SAFE_INTEGER}
                                            >
                                                {({ onRowsRendered, registerChild }) => (
                                                    <AutoSizer>
                                                        {({ width }) => (
                                                            <Table
                                                                ref={registerChild}
                                                                onRowsRendered={onRowsRendered}
                                                                rowClassName='text-dark mt-1 mb-1'
                                                                headerHeight={80}
                                                                width={width}
                                                                onRowClick={rowClicked}
                                                                height={370}
                                                                rowHeight={50}
                                                                rowCount={transactions.length}
                                                                rowGetter={({ index }) => transactions[index]}
                                                            >
                                                                <Column
                                                                    label='Hash'
                                                                    dataKey='hash'
                                                                    width={width * 0.25}
                                                                />
                                                                <Column
                                                                    label='From'
                                                                    dataKey='from'
                                                                    width={width * 0.25}
                                                                />
                                                            </Table>
                                                        )}
                                                    </AutoSizer>
                                                )}
                                            </InfiniteLoader>   
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="text-center bc-title" >Events</div>
                                            <InfiniteLoader
                                                isRowLoaded={({ index }) => events[index]}
                                                loadMoreRows={loadMoreEvents}
                                                rowCount={Number.MAX_SAFE_INTEGER}
                                            >
                                                {({ onRowsRendered, registerChild }) => (
                                                    <AutoSizer>
                                                        {({ width }) => (
                                                            <Table
                                                                ref={registerChild}
                                                                onRowsRendered={onRowsRendered}
                                                                rowClassName='text-dark mt-1 mb-1'
                                                                headerHeight={80}
                                                                width={width}
                                                                height={370}
                                                                onRowClick={eventRowClicked}
                                                                rowHeight={50}
                                                                rowCount={events.length}
                                                                rowGetter={({ index }) => events[index]}
                                                            >
                                                                <Column
                                                                    label='Event'
                                                                    dataKey='event'
                                                                    width={width * 0.3}
                                                                />
                                                                <Column
                                                                    label='Smart Contract'
                                                                    dataKey='address'
                                                                    width={width * 0.3}
                                                                />
                                                                <Column
                                                                    label='Returned Parameters'
                                                                    cellDataGetter={({ dataKey , rowData }) => parseReturnedValues(rowData.returnValues)}
                                                                    dataKey='returnValues'
                                                                    width={width * 0.3}
                                                                />
                                                            </Table>
                                                        )}
                                                    </AutoSizer>
                                                )}
                                            </InfiniteLoader>
                                        </div>
                                    </div>
                                </div>

                                {
                                    expandedBlock &&
                                    <Modal size="lg" show={expandedBlock} onHide={hideExpandedBlock} >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Block ID #{expandedBlock._id}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="overflow-wrap-anywhere" >
                                            <p>Nonce : {expandedBlock.nonce}</p>
                                            <p>Transaction index : {expandedBlock.transactionIndex}</p>
                                            <p>Parent Hash : {expandedBlock.parentHash}</p>
                                            <p>SHA 3 Uncles : {expandedBlock.sha3Uncles}</p>
                                            <p>Miner : {expandedBlock.miner}</p>
                                            <p>State root : {expandedBlock.stateRoot}</p>
                                            <p>Transactions root : {expandedBlock.transactionsRoot}</p>
                                            <p>Receipts root : {expandedBlock.receiptsRoot}</p>
                                            <p>Logs bloom : {expandedBlock.logsBloom}</p>
                                            <p>Difficulty : {expandedBlock.difficulty}</p>
                                            <p>Total difficulty : {expandedBlock.totalDifficulty}</p>
                                            <p>Total transactions : {expandedBlock.transactions.length}</p>
                                            <div className="mt-5 mb-5">
                                                <p>Transactions : </p>
                                                <BootstrapTable data={expandedBlock.transactions} keyField='hash' striped hover pagination search options={optionsTx} >
                                                    <TableHeaderColumn dataField='hash' dataSort={true} >Hash</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='from' dataSort={true}>From</TableHeaderColumn>
                                                    <TableHeaderColumn dataFormat={momentTransactionCreationDate} >Creation date</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                            <p>Uncles : {String(JSON.stringify(expandedBlock.uncles)).slice(1, -1)}</p>
                                            <p>Size : {expandedBlock.size}</p>
                                            <p>Number : {expandedBlock.number}</p>
                                            <p>Gas limit : {expandedBlock.gasLimit}</p>
                                            <p>Gas used : {expandedBlock.gasUsed}</p>
                                            <p>Timestamp : {expandedBlock.timestamp}</p>
                                            <p>Extra data : {expandedBlock.extraData}</p>
                                            <p>Mix hash : {expandedBlock.mixHash}</p>
                                            <p>Nonce : {expandedBlock.nonce}</p>
                                            <p>Hash : {expandedBlock.hash}</p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <CancelButton style="float-left" onClick={hideExpandedBlock} label="Cancel" />
                                        </Modal.Footer>
                                    </Modal>
                                }

                                {
                                    expandedTx &&
                                    <Modal size="lg" show={expandedTx} onHide={hideExpandedTx}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Transaction ID #{expandedTx._id}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="overflow-wrap-anywhere" >
                                            <p>Block hash : {expandedTx.blockHash}</p>
                                            <p>Block number : {expandedTx.blockNumber}</p>
                                            <p>From : {expandedTx.from}</p>
                                            <p>Gas : {expandedTx.gas}</p>
                                            <p>Gas price : {expandedTx.gasPrice}</p>
                                            <p>Hash : {expandedTx.hash}</p>
                                            <p>Input : {expandedTx.input}</p>
                                            <p>Nonce : {expandedTx.nonce}</p>
                                            <p>To : {expandedTx.to}</p>
                                            <p>Transaction index : {expandedTx.transactionIndex}</p>
                                            <p>Value : {expandedTx.value}</p>
                                            <p>V : {expandedTx.v}</p>
                                            <p>R : {expandedTx.r}</p>
                                            <p>S : {expandedTx.s}</p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <CancelButton style="float-left" onClick={hideExpandedTx} label="Cancel"/>
                                        </Modal.Footer>
                                    </Modal>
                                }

                                {
                                    dataToView &&
                                    <Modal size="lg" show={dataToView} onHide={hideDataToView}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {
                                                    typeof dataToView.v !== "undefined" && typeof dataToView.r !== "undefined" && typeof dataToView.s !== "undefined" ? (
                                                        <>Transaction details</>
                                                    ) : (
                                                            <>Block details</>
                                                        )
                                                }
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="overflow-wrap-anywhere" >
                                            {
                                                typeof dataToView.v !== "undefined" && typeof dataToView.r !== "undefined" && typeof dataToView.s !== "undefined" ? (
                                                    <>
                                                        <p>Block hash : {dataToView.blockHash}</p>
                                                        <p>Block number : {dataToView.blockNumber}</p>
                                                        <p>From : {dataToView.from}</p>
                                                        <p>Gas : {dataToView.gas}</p>
                                                        <p>Gas price : {dataToView.gasPrice}</p>
                                                        <p>Hash : {dataToView.hash}</p>
                                                        <p>Input : {dataToView.input}</p>
                                                        <p>Nonce : {dataToView.nonce}</p>
                                                        <p>To : {dataToView.to}</p>
                                                        <p>Transaction index : {dataToView.transactionIndex}</p>
                                                        <p>Value : {dataToView.value}</p>
                                                        <p>V : {dataToView.v}</p>
                                                        <p>R : {dataToView.r}</p>
                                                        <p>S : {dataToView.s}</p>
                                                    </>
                                                ) : (
                                                        <>
                                                            <p>Parent hash : {dataToView.parentHash}</p>
                                                            <p>SHA3 uncles : {dataToView.sha3Uncles}</p>
                                                            <p>Miner : {dataToView.miner}</p>
                                                            <p>State root : {dataToView.stateRoot}</p>
                                                            <p>Transactions root : {dataToView.transactionsRoot}</p>
                                                            <p>Receipts root : {dataToView.receiptsRoot}</p>
                                                            <p>Logs bloom : {dataToView.logsBloom}</p>
                                                            <p>Difficulty : {dataToView.difficulty}</p>
                                                            <p>Number : {dataToView.number}</p>
                                                            <p>Gas limit : {dataToView.gasLimit}</p>
                                                            <p>Gas used : {dataToView.gasUsed}</p>
                                                            <p>Timestamp : {dataToView.timestamp}</p>
                                                            <p>Size : {dataToView.size}</p>
                                                            <p>Total difficulty : {dataToView.totalDifficulty}</p>
                                                            <p>Extra data : {dataToView.extraData}</p>
                                                            <p>Mix hash : {dataToView.mixHash}</p>
                                                            <p>Nonce : {dataToView.nonce}</p>
                                                            <p>Hash : {dataToView.hash}</p>
                                                            <p className="mt-5" >Total transactions : {dataToView.transactions.length}</p>
                                                            <div className="container">
                                                                {
                                                                    typeof dataToView.transactions !== "undefined" && dataToView.transactions.map((tx, id) =>
                                                                        <div className="mt-2 mb-2 ml-3">
                                                                            <p>Block hash : {tx.blockHash}</p>
                                                                            <p>Block number : {tx.blockNumber}</p>
                                                                            <p>Block from : {tx.from}</p>
                                                                            <p>Gas : {tx.gas}</p>
                                                                            <p>Gas price : {tx.gasPrice}</p>
                                                                            <p>Hash : {tx.hash}</p>
                                                                            <p>Input : {tx.input}</p>
                                                                            <p>Nonce : {tx.nonce}</p>
                                                                            <p>To : {tx.to}</p>
                                                                            <p>Transaction index : {tx.transactionIndex}</p>
                                                                            <p>Value : {tx.value}</p>
                                                                            <p>V : {tx.v}</p>
                                                                            <p>R : {tx.r}</p>
                                                                            <p>S : {tx.s}</p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                            }
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <CancelButton style="float-left" onClick={hideDataToView} label="Cancel"/>
                                        </Modal.Footer>
                                    </Modal>
                                }
                            </>
                        ) : (
                            <div className="container">
                                <div className="row">
                                    <div className="text-center bc-title" >Events</div>
                                    <InfiniteLoader
                                        isRowLoaded={({ index }) => events[index]}
                                        loadMoreRows={loadMoreEvents}
                                        rowCount={Number.MAX_SAFE_INTEGER}
                                    >
                                        {({ onRowsRendered, registerChild }) => (
                                            <AutoSizer>
                                                {({ width }) => (
                                                    <Table
                                                        ref={registerChild}
                                                        onRowsRendered={onRowsRendered}
                                                        rowClassName='text-dark mt-1 mb-1'
                                                        headerHeight={80}
                                                        width={width}
                                                        height={370}
                                                        onRowClick={eventRowClicked}
                                                        rowHeight={50}
                                                        rowCount={events.length}
                                                        rowGetter={({ index }) => events[index]}
                                                    >
                                                        <Column
                                                            label='Event'
                                                            dataKey='event'
                                                            width={width * 0.3}
                                                        />
                                                        <Column
                                                            label='Smart Contract'
                                                            dataKey='address'
                                                            width={width * 0.3}
                                                        />
                                                        <Column
                                                            label='Returned Parameters'
                                                            cellDataGetter={({ dataKey , rowData }) => parseReturnedValues(rowData.returnValues)}
                                                            dataKey='returnValues'
                                                            width={width * 0.3}
                                                        />
                                                    </Table>
                                                )}
                                            </AutoSizer>
                                        )}
                                    </InfiniteLoader>
                                </div>
                            </div>
                        )
                    }

                    {
                        expandedEvent &&
                        <Modal size="lg" show={expandedEvent} onHide={hideExpandedEvent}>
                            <Modal.Header closeButton>
                                <Modal.Title>{expandedEvent.event}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="overflow-wrap-anywhere" >
                                <p>Event ID : {expandedEvent._id}</p>
                                <p>Blockchain ID : {expandedEvent.blockchainId}</p>
                                <p>Bundle ID : {expandedEvent.bundleId}</p>
                                <p>Smart Contract ID : {expandedEvent.smartContractId}</p>
                                <p>Project ID : {expandedEvent.projectId}</p>
                                <p>Smart Contract provenance : {expandedEvent.address}</p>
                                <p>Block Number : {expandedEvent.blockNumber}</p>
                                <p>Transaction hash : {expandedEvent.transactionHash}</p>
                                <p>Transaction index : {expandedEvent.transactionIndex}</p>
                                <p>Block hash : {expandedEvent.blockHash}</p>
                                <p>Log index : {expandedEvent.logIndex}</p>
                                <p>Removed : {expandedEvent.removed}</p>
                                <p>Id : {expandedEvent.id}</p>
                                <p>Return values : {JSON.stringify(expandedEvent.returnValues,null,4)}</p>
                                <p>Event name : {expandedEvent.event}</p>
                                <p>Signature : {expandedEvent.signature}</p>
                                <p>Raw : {JSON.stringify(expandedEvent.raw,null,4)}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <CancelButton style="float-left" onClick={hideExpandedEvent} label="Cancel"/>
                            </Modal.Footer>
                        </Modal>
                    }
                </>
            }
        </>
    );
}

export default Explorer