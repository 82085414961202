import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Parameters from './Config/Parameters.js';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import PageTitle from './Common/pageTitle.js';
import ProjectFrame from './Project/ProjectFrame.js';
const Request = require('./Config/Request.js');


export default function Home(props) {

    const [applications, setApplications] = useState([]);
    const [projectToInteract, setProjectToInteract] = useState(null);
    const [isDeleting, setDeleting] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const history = useHistory();

    let displayRemoveProject = (project) => {
        setProjectToInteract(project);
        setDeleting(true);
    }

    let displayUpdateProject = (project) => {
        project.newApplicationName = project.name;
        setProjectToInteract(project);
        setUpdating(true);
    }

    let hideRemoveProject = () => {
        setProjectToInteract(null);
        setDeleting(false);
        setUpdating(false);
    }

    let getApps = async () => {
        try {
            let apps = await Request.h64HttpRequest("GET", "/getApplications", {});
            if ( apps )
                if ( apps.errorCode !== undefined && apps.errorMessage !== undefined )
                    if ( apps.errorCode === null && apps.errorMessage === null ){
                        props.setError(apps.errorMessage);
                        setApplications(apps.applications || []);
                    }
            return apps.applications || [];
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    let removeProject = async () => {
        try {

            let deleteProject = await Request.h64HttpRequest("DELETE", "/deleteApplication", { 
                applicationId: projectToInteract._id 
            });
            if ( deleteProject )
                if ( deleteProject.errorCode !== undefined && deleteProject.errorMessage !== undefined ){
                    props.setError(deleteProject.errorMessage);
                    if ( deleteProject.errorCode === null && deleteProject.errorMessage === null ) {
                        setApplications(applications.filter(_app => String(_app._id) !== String(projectToInteract._id)));
                        hideRemoveProject();
                    }
                }

        } catch (err) {
            console.error(err);
        }
    }

    let updateProject = async () => {
        try {

            let updateProject = await Request.h64HttpRequest("PATCH", "/updateApplication", { 
                applicationId: projectToInteract._id, 
                newApplicationName: projectToInteract.newApplicationName
            });
            if ( updateProject )
                if ( updateProject.errorCode !== undefined && updateProject.errorMessage !== undefined ){
                    props.setError(updateProject.errorMessage);
                    if (updateProject.errorCode === null && updateProject.errorMessage === null) {
                        setApplications(applications.map(_app => {
                            if ( String(_app._id) === String(projectToInteract._id) ){
                                _app.name = projectToInteract.newApplicationName;
                                _app.autoAccept = projectToInteract.newAutoAccept;
                            }
                            return _app;
                        }));
                        hideRemoveProject();
                    }
                }

        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        try {
            props.setBlockchain(null);
            props.setProject(null);
            const fetch = async () => {
                let activeApps = await getApps();
                setApplications(activeApps);
            }
            fetch();
        } catch (err) {
            console.error(err);
        }
    }, []);

    return (
        <div>
            <PageTitle title="Dashboard" button={{ path : "/projectWizard" , label : "Create a new project "}} />

            <div className="container mt-2">

                {
                    applications.map((app, id) =>
                        <ProjectFrame 
                            key={id}
                            project={app} 
                            user={props.user} 
                            displayUpdateProject={displayUpdateProject} 
                            displayRemoveProject={displayRemoveProject} 
                        />
                    )
                }

                {
                    applications.length == 0 &&
                    <div className="mt-5 mb-2">
                        <div className="d-flex justify-content-center  mb-2">
                        <i className="far fa-clock my-auto mr-2" aria-hidden="true"></i> Estimated creating time - 5 mn
                        </div>
                        <div className="d-flex justify-content-center  mb-2 mt-5">
                            <div className=" btn-add-project">
                            <Link to="/projectWizard">
                                <img src='/icons/add_folder_icon.svg' />
                            </Link>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3 add-project-title">No project… Let’s get started shall we? </div>
                    </div>
                }

        </div>

        {
            projectToInteract &&
            <Modal show={projectToInteract} onHide={hideRemoveProject}>
                <Modal.Header closeButton>
                    {
                        isDeleting &&
                        <Modal.Title className="card-title"  >Delete project ({projectToInteract.name})</Modal.Title>
                    }
                    {
                        isUpdating &&
                        <Modal.Title className="card-title"  >Change project name</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>

                    {
                        isDeleting &&
                        
                            <div className="card-subtitle-danger" >This will permanantly delete this project.</div>
                        
                    }

                    {
                        isUpdating && 
                        <Form.Group>
                            <div className="card-subtitle mb-1" >Project Name</div>
                            <Form.Control value={projectToInteract.newApplicationName} onChange={(e) => setProjectToInteract({ ...projectToInteract, newApplicationName: e.target.value })} />
                        </Form.Group>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-cancel-sumbit" onClick={hideRemoveProject}>Cancel</Button>
                    {
                        isDeleting &&
                        <Button className="btn-del-submit" onClick={removeProject} >Delete</Button>
                    }
                    {
                        isUpdating &&
                        <Button className="btn-ok-submit" onClick={updateProject} >Save</Button>
                    }
                </Modal.Footer>
            </Modal>
        }

        </div>
    );
}