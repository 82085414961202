import React from 'react';
import Typography from '@material-ui/core/Typography';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import Parameters from '../Config/Parameters.js';


export default function SmartContractMethodRequestExampleProvider(props) {

    const useStyles = makeStyles((theme) => (
        props.style ? 
            props.style 
        : 
            {
                typography: {
                    padding: theme.spacing(2)
                }
            }
    ));
    const user = props.user;
    const bundleId = props.bundleId;
    const smartContractId = props.smartContractId;
    const projectId = props.projectId;
    const method = props.method;


    return (
        <>
            <Typography className={useStyles}>
                <div className="mt-2 text-right" >
                    <CopyToClipboard 
                        text={
                            "const socket = new WebSocket(\"" + Parameters.SOCKET + "\");\n" + 
                            "socket.send(\n" +
                            "\tJSON.stringify({\n" + 
                                    "\t\tapi_key : \"" + user.key + "\",\n" +
                                    "\t\tprojectId : \"" + projectId + "\",\n" + 
                                    "\t\tbundleId : \"" + bundleId + "\",\n" + 
                                    "\t\tsmartContractId : \"" + smartContractId + "\",\n" + 
                                    "\t\tscMethod : " + JSON.stringify(method) + ",\n" + 
                                    "\t\tmethod : \"" + ( method.stateMutability === "view" || method.stateMutability === "pure" ? "/v2/transactions/call" : "/v2/transactions/send" ) + 
                                "\"\t})" + 
                            ");"
                        }
                    >
                        <Button className="mr-2 btn-copy-submit" >
                            <i className="fas fa-clipboard"></i>
                        </Button>
                    </CopyToClipboard>

                    <div className="bg-dark m-2 mb-4 text-left p-3">
                        <code className="text-white" >
                            const socket = new WebSocket("{Parameters.SOCKET}"); <br/> 
                            socket.send( <br/>
                                &nbsp;JSON.stringify({"{"} <br/> 
                                    &nbsp;&nbsp;"api_key" : "{user.key}", <br/>
                                    &nbsp;&nbsp;"projectId" : "{projectId}", <br/> 
                                    &nbsp;&nbsp;"bundleId" : "{bundleId}", <br/> 
                                    &nbsp;&nbsp;"smartContractId" : "{smartContractId}", <br/> 
                                    &nbsp;&nbsp;"scMethod" : {JSON.stringify(method)}, <br/> 
                                    &nbsp;&nbsp;"method" : "{( method.stateMutability === "view" || method.stateMutability === "pure" ? "/v2/transactions/call" : "/v2/transactions/send" )}" <br/>
                                &nbsp;{"}"}); <br/>
                            );
                        </code>
                    </div>
                </div>
            </Typography>
        </>
    );
}