import React from 'react';
import SmartContractConstructorParameterHandler from './SmartContractConstructorParameterHandler.js';
import ConstructorRadar from './ConstructorRadar.js';


export default function SmartContractConstructorHandler(props) {

    const contract = props.contract;
    const setSCToDeployProperty = props.setSCToDeployProperty;
    const addTheAddingSmartContract = props.addTheAddingSmartContract;
    const constructor = props.constructor;
    const contractIndex = props.contractIndex;

    return (
        <>
            <h3>{contract.name}</h3>
            <ConstructorRadar abi={JSON.parse(contract.abi)} constructorParameters={contract.constructorParameters} />
            
            <div className="mt-1 mb-2">
                {
                    contract.constructorParameters.map((constructor, coid) =>
                        <SmartContractConstructorParameterHandler 
                            key={coid}
                            constructor={constructor} 
                            setSCToDeployProperty={setSCToDeployProperty} 
                            addTheAddingSmartContract={addTheAddingSmartContract} 
                            contractIndex={contractIndex} 
                            contractName={contract.name}
                            constructorIndex={coid} 
                        />
                    )
                }
            </div>
        </>
    );

}