import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export default function Sidebar(props) {

    let logOut = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
    }

    return (
        <div className="vertical-sidebar overflow-auto  p-0 m-0 bg-white"  >
            <ul className="nav flex-column p-0 m-0 " id="nav-side">
                <li id="m-1" className="nav-item  d-flex align-items-center justify-content-center  border-top w-75 mx-auto mb-3" >

                </li>

                {
                    props.blockchain && props.project && 
                    <>
                        <li className="nav-item d-flex align-items-center justify-content-center"  >
                            <Link id="m-1" to={"/project/" + props.blockchain + "/" + props.project + "/infos"} 
                                className={props.activeMenu == 1 ? 'nav-link active-menu' : 'nav-link '}  >
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="menu-0" className="nav-tooltip">Management</Tooltip>}
                                >
                                    <div id="hover-manage" className="nav-side-btn   d-flex align-items-center justify-content-center ">
                                        <div id="ico-manage"></div>
                                    </div>
                                </OverlayTrigger>
                            </Link>
                        </li>

                        <li className="nav-item nav-item d-flex align-items-center justify-content-center" >
                            <Link id="m-2" to={"/explorer/" + props.blockchain + "/" + props.project} 
                            className={props.activeMenu == 2 ? 'nav-link active-menu' : 'nav-link'}  >
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="menu-1" className="nav-tooltip">Explorer</Tooltip>}
                                >
                                    <div id="hover-explore" className="nav-side-btn   d-flex align-items-center justify-content-center ">
                                        <div id="ico-explore"></div>
                                    </div>
                                </OverlayTrigger>
                            </Link>
                        </li>

                        <li className="nav-item  d-flex align-items-center justify-content-center" >
                            <Link to={"/smartContracts/" + props.blockchain + "/" + props.project} 
                                className={props.activeMenu == 3 ? 'nav-link active-menu' : 'nav-link'} >
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="menu-3" className="nav-tooltip">Smart Contracts</Tooltip>}
                                >
                                    <div id="hover-smart" className="nav-side-btn d-flex align-items-center justify-content-center">
                                        <div id="ico-smart"></div>
                                    </div>
                                </OverlayTrigger>
                            </Link>
                        </li>

                        <li className="nav-item  d-flex align-items-center justify-content-center" >
                            <Link id="m-5" to={"/members/" + props.blockchain + "/" + props.project} 
                                className={props.activeMenu == 4 ? 'nav-link active-menu' : 'nav-link'} >
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="menu-3" className="nav-tooltip">Members</Tooltip>}
                                >

                                    <div id="hover-members" className="nav-side-btn d-flex align-items-center justify-content-center">
                                        <div id="ico-members"></div>
                                    </div>
                                </OverlayTrigger>
                            </Link>
                        </li>


                    </>
                }


                <li className="nav-item  d-flex align-items-center justify-content-center " >
                    <a href="#" className="nav-link" >
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="menu-2" className="nav-tooltip">Documentation</Tooltip>}
                        >
                            <div className="nav-side-btn d-flex align-items-center justify-content-center">
                                <i className="fas fa-file-alt fa-lg"></i>
                            </div>
                        </OverlayTrigger>
                    </a>
                </li>

                <li className="nav-item  d-flex align-items-center justify-content-center " >
                    <a target="_blank" href="http://146.59.232.32:8020" className="nav-link" >
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="menu-2" className="nav-tooltip">Api</Tooltip>}
                        >
                            <div className="nav-side-btn d-flex align-items-center justify-content-center">
                                <i className="fas fa-cog fa-lg"></i>
                            </div>
                        </OverlayTrigger>
                    </a>
                </li>

                <li className="nav-item  d-flex align-items-center justify-content-center mt-3 border-top w-75 mx-auto" >
                </li>

                <li className="nav-item  d-flex align-items-center justify-content-center mt-3 " >
                    <Link to="" onClick={logOut} className="nav-link" >
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="menu-2" className="nav-tooltip">Log out</Tooltip>}
                        >
                            <div className="nav-side-btn d-flex align-items-center justify-content-center">
                                <i className="fas fa-sign-out-alt  fa-lg mr-1"></i>
                            </div>
                        </OverlayTrigger>
                    </Link>
                </li>

            </ul>

        </div>
    );
}