import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import IconButton from '@material-ui/core/IconButton';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
//import ConfirmationViewer from './ConfirmationViewer.js';
import TransactionViewer from './TransactionViewer.js';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      height: 224
    },
    typography: {
        padding: theme.spacing(2),
    }
}));


export default function Output(props) {

    const user = props.user;
    const blockchainId = props.blockchainId;
    const projectId = props.projectId;
    const smartContractId = props.smartContractId;
    const method = props.method;
    const notifications = props.notifications;
    const [value, setValue] = useState(0);
    const classes = useStyles();
    const theme = useTheme();

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleOutput = (output) => {
        switch ( typeof output ) {
            case "boolean":
                return <span className="ml-2">{output ? "true" : "false"}</span>

            case "object":
                let turnToArray = [];
                for ( let i in output )
                    turnToArray.push(output[i]);
                return <>
                    {
                        turnToArray.map((out,id) => 
                            <span className="ml-2">{handleOutput(out)}</span>
                        )
                    }
                </>;

            default:
                return <span className="ml-2">{output}</span>;
        }
    }

    return (
        <div className={classes.root + " overflow-hidden"}>
            <AppBar position="static" color="white" className="shadow-none">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {
                        notifications.map((tx,txId) => 
                            <Tab label={txId === 0 ? "Latest" : txId} key={txId} {...a11yProps(txId)} />
                        )
                    }
                </Tabs>
            </AppBar>

            {
                notifications.map((tx,txId) => 
                    <TabPanel value={value} index={txId} dir={theme.direction}>
                        <div className="row">
                            <div className="col-sm-10">
                                {
                                    tx.response !== undefined && 
                                    <p>Output : {handleOutput(tx.response)}</p>
                                }
                                {
                                    tx.error && 
                                    <>
                                        {
                                            tx.error.indexOf("{") !== -1 && tx.error.lastIndexOf("}") !== -1 ? (
                                                <>
                                                    <p className="text-danger" >{tx.error.substr(0,tx.error.indexOf("{")-2)}</p>
                                                    {/*
                                                        <p>{JSON.stringify(JSON.parse(tx.error.substr(tx.error.indexOf("{"),tx.error.lastIndexOf("}"))),null,4)}</p>
                                                    */}
                                                </>
                                            ) : (    
                                                <p className="text-danger" >
                                                    {tx.error}
                                                </p>
                                            )
                                        }
                                    </>
                                }

                                {
                                    tx.transactionHash && 
                                    <TransactionViewer blockchainId={blockchainId} transactionHash={tx.transactionHash} />
                                }
                            </div>

                            {/*
                                <div className="col-sm-2">
                                    {
                                        tx.confirmations && 
                                        <>
                                            <h5>Confirmations</h5>
                                            {
                                                tx.confirmations.map((conf,id) => 
                                                    <>
                                                        {
                                                            tx.transactionHash && 
                                                            <ConfirmationViewer blockchainId={blockchainId} transactionHash={conf.transactionHash} />
                                                        }
                                                    </>
                                                )
                                            }
                                        </>
                                    }
                                </div>
                            */}

                        </div>
                    </TabPanel>
                )
            }
        </div>
    );
}