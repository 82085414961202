import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import NodesInfos from './NodesInfos.js';
import ProgressBar from 'react-bootstrap/ProgressBar';
import BackButton from './Common/BackButton.js';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import GradientChart from './Common/GradientChart.js';
import DangerButton from './Common/DangerButton.js';
const Request = require('./Config/Request.js');


export default function ProjectBlockchain(props) {
    const { blockchainId, projectId } = useParams();
    let gradient1 = document.createElement('canvas').getContext("2d").createLinearGradient(500, 0, 100, 0);
    gradient1.addColorStop(0, "#fa4fdf");
    gradient1.addColorStop(0.5, "#fa4fdf");
    gradient1.addColorStop(1, "#fa994f");

    let gradient2 = document.createElement('canvas').getContext("2d").createLinearGradient(500, 0, 100, 0);
    gradient2.addColorStop(0, "#25ceda");
    gradient2.addColorStop(0.5, "#25ceda");
    gradient2.addColorStop(1, "#0ed198");

    let gradient3 = document.createElement('canvas').getContext("2d").createLinearGradient(500, 0, 100, 0);
    gradient3.addColorStop(0, "#fa4fdf");
    gradient3.addColorStop(0.5, "#fa4fdf");
    gradient3.addColorStop(1, "#fa994f");

    const [blockchain, setBlockchain] = useState({});
    const user = props.user;
    const [application, setApplication] = useState({});
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if ( blockchainId && projectId ){
            props.setBlockchain(blockchainId);
            props.setProject(projectId);
        }
    }, [blockchainId,projectId]);

    let getBlockchain = async () => {
        try {
            let getTheBlockchain = await Request.h64HttpRequest("GET", "/myBlockchain/" + blockchainId, {});
            if (getTheBlockchain)
                if (getTheBlockchain.blockchain)
                    return getTheBlockchain.blockchain || {};
            return {};
        } catch (err) {
            console.error(err);
            return {};
        }
    }

    let getProjectMembers = async () => {
        let projectMembers = await Request.h64HttpRequest("GET", "/getAllProjectMembers/" + projectId, {});
        return projectMembers;
    }

    let getApp = async () => {
        try {
            let app = await Request.h64HttpRequest("GET", "/getApplication/" + projectId, {});
            setApplication(app.application || {});
            return app.application || {};
        } catch (err) {
            console.error(err);
            return {};
        }
    }

    useEffect(() => {
        try {

            const fetch = async () => {
                let activeBlockchain = await getBlockchain();
                setBlockchain(activeBlockchain);

                let activeApp = await getApp();
                setApplication(activeApp);

                let projectMembers = await getProjectMembers();
                setUsers(projectMembers ? projectMembers.users : []);

            }
            fetch();

        } catch (err) {
            console.error(err);
        }
    }, []);

    let getChartInfosArrayProperty = (stats, property) => {
        let arrayToReturn = [];

        if ( blockchain.chartInfos ){
            //console.log(">> " + JSON.stringify(blockchain.chartInfos));
            for ( let i = 0 , l = blockchain.chartInfos[stats].length ; i < l ; ++i )
                arrayToReturn.push(blockchain.chartInfos[stats][i][property]);
        }

        //console.log("Returned array : " + arrayToReturn);
        return arrayToReturn;
    }

    let deleteUser = async (user) => {
        try {
            await Request.h64HttpRequest("DELETE", "/removeUserFromProject", {
                projectId : projectId , 
                accountAddress : user.accountAddress
            });
            setUsers(users.filter(_user => _user.accountAddress !== user.accountAddress));
        } catch (err) {
            console.error(err);
            return {};
        }
    }

    let requestsOperations = (cell, row) => {
        return <DangerButton onClick={() => deleteUser(row)} icon={<i className="fas fa-trash-alt"></i>} />;
    }

    return (
        <>
                
            <div className="page-header m-0 p-0 w-100 d-flex">
                <div className="w-100 page-header-title">Blockchain management</div>
            </div>

            <div className="container mt-2">

                {
                    (typeof projectId === "undefined" || projectId === null) &&
                    <div className="row">
                        <div className="w-100 text-center">
                            <Link to="/projectWizard" className="btn-action-sumbit ml-3 mt-2 mb-3">Create a new project</Link>
                        </div>
                    </div>
                }

                {
                    blockchain &&
                    <>
                        <div>
                            <div className="mb-3 ml-3 mr-3 mt-3">
                                <div className="mb-1 text-mini-1 pb-4 pt-4" >
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <BackButton />
                                        </div>
                                        <div className="col-sm-8 ml-4">
                                            {blockchain.name} 
                                            <i className="fas fa-chevron-right ml-2 mr-2"></i> 
                                            {
                                                application.blockchainUsed && 
                                                <>{application.blockchainUsed.id}</>
                                            }
                                            <div className="text-maxi-1" >
                                                {application.applicationName}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6 mb-3">
                                        <div className="card-bc-infos card-bc-infos-shadow ">
                                            <div className="bc-title">Blockchain informations</div>
                                            <div className="row mt-4 ">
                                                <div className="col-4 border-right">
                                                    <p className="bc-subtitle">
                                                    <img className="mr-1" src="/icons/shape1.svg" />
                                                        Network</p>
                                                    <p className="bc-subtitle-xl">{blockchain.networkId}</p>
                                                    <div className="bc-mini-progress bc-network"><ProgressBar now={50} /></div>
                                                </div>
                                                <div className="col-4 border-right">
                                                    <p className="bc-subtitle ">
                                                    <img className="mr-1" src="/icons/shape2.svg" />
                                                        Powered by</p>
                                                    <p className="bc-subtitle-xl">{blockchain.technology}</p>
                                                    <div className="bc-mini-progress bc-power"><ProgressBar now={50} /></div>
                                                </div>
                                                <div className="col-4">
                                                    <p className="bc-subtitle">
                                                    <img className="mr-1" src="/icons/shape3.svg" />
                                                        Created on</p>
                                                    <p className="bc-subtitle-xl">{moment(blockchain.creationData).format("L")}</p>
                                                    <div className="bc-mini-progress bc-create"><ProgressBar now={50} /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="card-bc-widget">
                                                    <p className="bc-subtitle mb-0">
                                                    <img className="mr-1" src="/icons/shape4.svg" />
                                                    Total mined blocks</p>
                                                    <p className="bc-subtitle-2xl">{blockchain.blocks}</p>
                                                    {
                                                        getChartInfosArrayProperty("blocks","date").length > 0 && 
                                                        <GradientChart
                                                            labels={getChartInfosArrayProperty("blocks","date")}
                                                            datasetsLabelTemplate={"blocks"}
                                                            datasetsData={getChartInfosArrayProperty("blocks","amount")}
                                                            isFill={true}
                                                            borderColor={[255, 99, 132, 0.2]}
                                                            gradient={gradient1}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="card-bc-widget">
                                                    <p className="bc-subtitle  mb-0">
                                                    <img className="mr-1" src="/icons/shape4.svg" />
                                                    Total transactions</p>
                                                    <p className="bc-subtitle-2xl">{blockchain.transactions}</p>
                                                    {
                                                        getChartInfosArrayProperty("transactions","date").length > 0 && 
                                                        <GradientChart
                                                            labels={getChartInfosArrayProperty("transactions","date")}
                                                            datasetsLabelTemplate={"transactions"}
                                                            datasetsData={getChartInfosArrayProperty("transactions","amount")}
                                                            isFill={true}
                                                            borderColor={[255, 99, 132, 0.2]}
                                                            gradient={gradient2}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="card-bc-widget">
                                                    <p className="bc-subtitle  mb-0">
                                                    <img className="mr-1" src="/icons/shape4.svg" />
                                                    Total users</p>
                                                    <p className="bc-subtitle-2xl">{blockchain.events}</p>
                                                    {
                                                        getChartInfosArrayProperty("events","date").length > 0 && 
                                                        <GradientChart
                                                            labels={getChartInfosArrayProperty("events","date")}
                                                            datasetsLabelTemplate={"events"}
                                                            datasetsData={getChartInfosArrayProperty("events","amount")}
                                                            isFill={true}
                                                            borderColor={[255, 99, 132, 0.2]}
                                                            gradient={gradient3}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 w-100">
                                    <h6>Current users on the project</h6>
                                </div>

                                {
                                    users && users.length > 0 ? (
                                        <BootstrapTable version='4' data={users} search
                                            hover
                                            tableContainerClass='table-nodes'
                                            tableBodyClass='table-nodes-body'
                                            headerContainerClass='table-nodes-header'>
                                            <TableHeaderColumn dataField='email' dataSort={true} isKey={true}>Email</TableHeaderColumn>
                                            <TableHeaderColumn dataField='name' dataSort={true}>Name</TableHeaderColumn>
                                            <TableHeaderColumn dataFormat={requestsOperations} >&nbsp;</TableHeaderColumn>
                                        </BootstrapTable>
                                    ) : (
                                        <div class="alert alert-info" role="alert">
                                            No users on this project
                                        </div>
                                    )
                                } 

                                <div className="mt-4 pl-3 pr-3">
                                    {
                                        application.blockchainUsed &&
                                        <NodesInfos blockchainId={application.blockchainUsed.id} i18={props.t} user={user} ></NodesInfos>
                                    }
                                </div>

                                
                            </div>
                        </div>
                    </>
                }

            </div>
        </>
    )
}