/* This will help using the same socket on any webpage we want using the same socket connection */
import Parameters from './Parameters.js';
const socket = new WebSocket(Parameters.SOCKET);

function sendSocketEvent(obj) {
    try {
        let api_key = sessionStorage.getItem('user') || localStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user') || localStorage.getItem('user')).key : undefined;
        socket.send(
            JSON.stringify({
                ...obj,
                api_key : api_key
            })
        );
    } catch (err) {
        console.error(err);
    }
}

export { socket };
export { sendSocketEvent };