import React from 'react';
import { Link } from 'react-router-dom';

export default function Notification(props) {

    return (
        <div className="mt-5 mb-2">
            <div className="d-flex justify-content-center  mb-2">
                <i className="far fa-clock my-auto mr-2" aria-hidden="true"></i>
                {props.errorMessage}
            </div>
            {
                props.goBackURL && 
                <div className="d-flex justify-content-center  mb-2 mt-5">
                    <div className=" btn-add-project">
                    <Link to={props.goBackURL}>
                        <img src='/icons/add_folder_icon.svg' />
                    </Link>
                    </div>
                </div>
            }
            {
                props.goBackMessage && 
                <div className="d-flex justify-content-center mt-3 add-project-title">
                    {props.goBackMessage}
                </div>
            }
        </div>
    );
}
