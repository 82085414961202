import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputLabel from '@material-ui/core/InputLabel';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import IconButton from '@material-ui/core/IconButton';
const Request = require('../Config/Request.js');


export default function TransactionViewer(props) {

    const blockchainId = props.blockchainId;
    const transactionHash = props.transactionHash;
    const [errorCode, setErrorCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [transaction, setTransaction] = useState(null);

    const getTransaction = async () => {
        let theBlock = await Request.h64HttpRequest("GET", "/v1/blockchain/" + blockchainId + "/getTransaction/" + transactionHash, {});
        if ( theBlock )
            if ( theBlock.errorCode !== undefined && theBlock.errorMessage !== undefined ){
                setErrorCode(theBlock.errorCode);
                setErrorMessage(theBlock.errorMessage);
                if (theBlock.errorCode === null && theBlock.errorMessage === null) {
                    if ( theBlock.block )
                        for (let i = 0, l = theBlock.block.transactions.length; i < l; ++i)
                            if (theBlock.block.transactions[i].hash === transactionHash){
                                setTransaction(theBlock.block.transactions[i]);
                                return;
                            }
                }
            }
    }

    const closeTransactionModal = () => {
        setTransaction(null);
    }

    return (
        <>
            <span>
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={getTransaction} >
                    <FingerprintIcon />
                </IconButton>
                <span className="ml-3">{transactionHash}</span>
            </span>
            {
                transaction && 
                <Modal show={transaction} onHide={closeTransactionModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className="break-word-class" >Transaction #{transaction.hash}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Group>
                            <InputLabel className="text-dark" >Block hash</InputLabel>
                            <Form.Control value={transaction.blockHash} readOnly />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Block number</InputLabel>
                            <Form.Control value={transaction.blockNumber} readOnly />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >From</InputLabel>
                            <Form.Control value={transaction.from} readOnly />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >To</InputLabel>
                            <Form.Control value={transaction.to} readOnly />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Gas</InputLabel>
                            <Form.Control value={transaction.gas} readOnly />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Gas price</InputLabel>
                            <Form.Control value={transaction.gasPrice} readOnly />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Input</InputLabel>
                            <Form.Control value={transaction.input} readOnly />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Nonce</InputLabel>
                            <Form.Control value={transaction.nonce} readOnly />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >r</InputLabel>
                            <Form.Control value={transaction.r} readOnly />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >s</InputLabel>
                            <Form.Control value={transaction.s} readOnly />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Transaction index</InputLabel>
                            <Form.Control value={transaction.transactionIndex} readOnly />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >v</InputLabel>
                            <Form.Control value={transaction.v} readOnly />
                        </Form.Group>

                        <Form.Group>
                            <InputLabel className="text-dark" >Value</InputLabel>
                            <Form.Control value={transaction.value} readOnly />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="float-left" onClick={closeTransactionModal}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}