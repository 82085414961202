import React, { useState, useEffect, useContext } from 'react';
//import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import BackButton from './Common/BackButton.js';
import Zoom from '@material-ui/core/Zoom';


const SmartContractMenu = (props) => {

    const { blockchainId, projectId } = useParams();
    let history = useHistory();
    
    return (
        <>
            <div className="page-header  m-0 p-0 w-100 d-flex page-header-min">
                <div className="w-100 page-header-title"> Smart Contracts</div>
            </div>

            <div className="container mt-3">

            <div className="mb-1 text-mini-1 pb-4 pt-4" >
                <div className="row">
                    <div className="col-sm-2   mr-4">
                        <BackButton></BackButton>
                    </div>
                    <div className="col-sm-8">
                        {blockchainId} <i className="fas fa-chevron-right ml-2 mr-2"></i> {projectId}
                        <div className="text-maxi-1" >
                            Add Smart contract
                    </div>
                    </div>
                </div>
            </div>

                <div className="row justify-content-center text-center">

                    <Zoom in={true} >
                        <div className="col-md-4 col-sm-12 ">
                            <div className="card-smart"  onClick={() => history.push("/smartContractsWizard/" + blockchainId + "/" + projectId )} >
                                <img src='/icons/sc_library.svg' />
                                <div className="project-title-label mt-5"> Blockadhoc Library</div>
                                <div className="project-mini-label mt-2">Access to our smart contracts library.</div>
                            </div>
                        </div>
                    </Zoom>

                    <Zoom in={true} >
                        <div className="col-md-4 col-sm-12" onClick={() => history.push("/deploySmartContract/" + blockchainId + "/" + projectId )} >
                            <div className="card-smart">
                                <img src='/icons/sc_upload.svg' />
                                <div className="project-title-label mt-5">Deploy your Smart Contract</div>
                                <div className="project-mini-label mt-2">Deploy your proprietary smart contract,
                                to share with other users,
                            or you can keep it confidential for your own use</div>
                            </div>
                        </div>
                    </Zoom>

                    <Zoom in={true} >
                        <div className="col-md-4 col-sm-12">
                            <div className="card-smart " onClick={() => history.push("/orderSmartContract/" + projectId )} >
                                <img src='/icons/sc_order.svg' />
                                <div className="project-title-label mt-5">Order Smart Contract</div>
                                <div className="project-mini-label mt-2">Your project may need a specific and more complex smart contract.
                            We are here to support you</div>
                            </div>
                        </div>
                    </Zoom>

                </div>

                <div className="row justify-content-center text-center">
                    <div className="col-sm-4">
                        
                    </div>
                    <Zoom in={true} >
                        <div className="col-md-4 col-sm-12 ">
                            <div className="card-smart"  onClick={() => history.push("/uploadSmartContract/" + blockchainId + "/" + projectId )} >
                                <img src='/icons/sc_upload.svg' />
                                <div className="project-title-label mt-5">Upload your Smart Contract</div>
                                <div className="project-mini-label mt-2">Upload your proprietary smart contract,
                                to share with other users,
                            or you can keep it confidential for your own use</div>
                            </div>
                        </div>
                    </Zoom>
                    <div className="col-sm-4">

                    </div>
                </div>

            </div>
        </>
    );
}

export default SmartContractMenu