import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Button from 'react-bootstrap/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import SmartContractConstructorHandler from './SmartContractConstructorHandler.js';
import IconNotification from '../Common/IconNotification.js';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
    }),
);

function Step1(props) {

    const classes = useStyles();
    const smartContract = props.smartContract;
    const user = JSON.parse(sessionStorage.getItem('user') || localStorage.getItem('user'));
    const setSCToDeployProperty = props.setSCToDeployProperty;
    const addTheAddingSmartContract = props.addTheAddingSmartContract;

    const getMainContract = () => {
        let mainContract = { abi : [] , contractIndex : smartContract.smartContracts.length-1 };

        if ( smartContract )
            if ( smartContract.smartContracts ){
                for ( let i = 0 , l = smartContract.smartContracts.length ; i < l ; ++i )
                    if ( JSON.stringify(mainContract.abi).length < JSON.stringify(smartContract.smartContracts[i].abi).length )
                        mainContract = { abi : smartContract.smartContracts[i].abi, contractIndex : i };
                return { ...smartContract.smartContracts[mainContract.contractIndex], contractIndex : mainContract.contractIndex };
            }
        
        return { ...smartContract.smartContracts[mainContract.contractIndex], contractIndex : mainContract.contractIndex };
    }

    return (
        <div className="container justify-content-center text-center">
            {
                getMainContract() && 
                <>
                    {
                        getMainContract().constructorParameters ? ( 
                            <>
                                {
                                    getMainContract().constructorParameters.length > 0 ? (
                                        <div className="card-smart-deploy justify-content-center text-center">
                                            <SmartContractConstructorHandler 
                                                contract={getMainContract()} 
                                                contractIndex={getMainContract().contractIndex} 
                                                setSCToDeployProperty={setSCToDeployProperty} 
                                                addTheAddingSmartContract={addTheAddingSmartContract} 
                                            />
                                        </div>
                                    ) : (
                                        <IconNotification  
                                            iconURL="/icons/add_folder_icon.svg"
                                            notificationMessage="Your smart contract doesn't need any parameter, proceed to the next step!"
                                        />
                                    )
                                }
                            </>
                        ) : (
                            <></>
                        )
                    }
                </>
            }
        </div>
    );

}

export default Step1;
