import React from 'react';

export default function Step4(props) {

    return (
        <>

            <div className="container mt-5 mb-5" >
                <div className="text-center project-title-label">Review your project informations</div>
                <div className="text-center project-mini-label">It's always good to take one more look before you make the step</div>


                <div className="mx-auto card-project-review mt-5 mb-5">
                    <div className="proj-wrap mt-3">
                    <div className="float-left mr-4">
                        <img src="/icons/review_project_folder.png" width="120" />
                        </div>
               
                            <div className="mt-0  review-title">Project name</div>
                            <div className="review-project mb-3">{props.name}</div>


                            <div className="row mt-4">
                                <div className="col-4 my-auto p-0">
                                    <div className="proj-wrap">
                                        <img className="proj-list-ico" src="/icons/proj_time_ico.png" alt="time" />
                                        <div className="float-left mt-1">
                                            <div className="review-list-title">Estimated time</div>
                                            <div className="review-list-subtitle">Instant</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4  my-auto p-0">
                                    <div className="proj-wrap">
                                        <img className="proj-list-ico" src="/icons/proj_proto_ico.png" alt="proto" />
                                        <div className="float-left mt-1">
                                            <div className="review-list-title">Protocol</div>
                                            <div className="review-list-subtitle">{props.protocol}</div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-4 my-auto p-0">
                                    <div className="proj-wrap">
                                        <img className="proj-list-ico" src="/icons/proj_host_ico.png" alt="host" />
                                        <div className="float-left mt-1">
                                            <div className="review-list-title">Hosting</div>
                                            <span className="review-list-subtitle">
                                                {props.blockchainName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 mb-3 review-list-title">
                                    <input type="checkbox" checked={props.terms} onChange={props.setTerms} /> I accept the terms and conditions
                                </div>
                            </div>
                        

                        </div>



                
                </div>

            </div>
        </>
    );

}