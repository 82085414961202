import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Parameters from '../Config/Parameters.js';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CancelButton from './CancelButton.js';
import VanillaButton from './VanillaButton.js';
import Alert from './Alert.js';
const Request = require('../Config/Request.js');


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
}));

export default function SmartContractCode(props) {

    const smartContracts = typeof props.smartContracts !== "undefined" ? props.smartContracts : [];
    const classInjector = typeof props.classInjector !== "undefined" ? props.classInjector : null;
    const templateId = typeof props.templateId !== "undefined" ? props.templateId : "";
    const [filesCode, setFilesCode] = useState([]);
    const [filesPath, setFilesPath] = useState([]);
    const history = useHistory();
    const classes = useStyles();
    const [value, setValue] = useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    let showSmartContractCode = async () => {
        try{

            for ( let i = 0 , l = smartContracts.length ; i < l ; ++i ){
                let path = Parameters.H64_API + "/public/templates/" + templateId + "/" + templateId + "_" + i + ".sol";
                let theContent = await Request.getURLFile(path);
                if ( !theContent.errorCode && !theContent.errorMessage ){
                    setFilesPath(filesPath => [ ...filesPath , "Untitled" + i + ".sol" ]);
                    setFilesCode(filesCode => [ ...filesCode , theContent ]);
                }
            }

        }catch(err){
            console.error(err);
        }
    }

    let hideCode = () => {
        setFilesCode([]);
    }

    return (
        <>
            <VanillaButton title="View source code" style={classInjector}  onClick={showSmartContractCode} icon={<i class="far fa-file-code"></i>} />
            <Modal show={filesCode.length > 0} size="lg" onHide={hideCode}>
                <Modal.Body>

                    <div className={classes.root}>
                        <AppBar position="static" color="default" className="shadow-none" style={{ backgroundColor : 'white' }}>
                            <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            >
                                {
                                    filesPath.map((path, cid) =>
                                        <Tab label={path} {...a11yProps(cid)} />
                                    )
                                }
                            </Tabs>
                        </AppBar>
                        {
                            filesCode.map((code, cid) =>
                                <TabPanel value={value} index={cid}>
                                    {
                                        typeof code === "string" ? (
                                            <>
                                                {
                                                    code.split("\n").map((i,key) => {
                                                        return <div key={key}>{i}</div>;
                                                    })
                                                }
                                            </>
                                        ) : (
                                            <>
                                                {
                                                    code.errorCode && code.errorMessage && 
                                                    <Alert severity="error" errorMessage={code.errorMessage} />
                                                }
                                            </>
                                        )
                                    }
                                </TabPanel>
                            )
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <CancelButton style="float-left" onClick={hideCode} label="Close" />
                </Modal.Footer>
            </Modal>
        </>
    );
}