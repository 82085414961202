import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { Link, useHistory } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import BackButton from './Common/BackButton.js';
import Alert from 'react-bootstrap/Alert';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BundleFrame from './Bundle/BundleFrame.js';
import IconNotification from './Common/IconNotification.js';
import SuccessButton from './Common/SuccessButton.js';
import DangerButton from './Common/DangerButton.js';
import CancelButton from './Common/CancelButton.js';
const Request = require('./Config/Request.js');


export default function SmartContractInfos(props) {
    const { blockchainId, projectId } = useParams();
    let [bundles, setBundles] = useState([]);
    let [bundle, setBundle] = useState(null);
    let [isDeleting, setDeleting] = useState(false);
    let [categoryFilter, setCategoryFilter] = useState([]);
    let [popperOpen, setPopperOpen] = useState(false);
    let [popperPlacement, setPopperPlacement] = useState();
    let [popperAnchorEl, setPopperAnchorEl] = useState(null);
    let [popperContent, setPopperContent] = useState([]);
    let [librarySmartContractPlugins,setLibrarySmartContractPlugins] = useState([]);
    let history = useHistory();

    useEffect(() => {
        if ( blockchainId && projectId ){
            props.setBlockchain(blockchainId);
            props.setProject(projectId);
        }
    }, [blockchainId,projectId]);
    
    let getBundles = async () => {
        try {
            let theBundles = await Request.h64HttpRequest("GET", "/getLibrarySmartContracts", {});
            return theBundles.smartContracts || [];
        } catch (err) {
            console.error(err);
        }
    }

    let requestDeleteBundle = (row) => {
        try {
            setBundle(row);
            setDeleting(true);
        } catch (err) {
            console.error(err);
        }
    }

    let getProjectBundles = async () => {
        try {
            console.log("Get bundles for project >> " + projectId);
            let bundles = await Request.h64HttpRequest("GET", "/getProjectBundles/" + projectId, {});
            console.log("The bundle is >> " + JSON.stringify(bundles));
            return bundles.bundles || [];
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    let hideDeleteModal = () => {
        setDeleting(false);
        setBundle(null);
    }


    let verifyPlugins = (plugins) => {
        if ( plugins )
            for ( let i = 0 , l = plugins.length ; i < l ; ++i )
                if ( plugins[i].length > 7 )
                    return true;
        return false;
    }

    let getBundlesPlugins = async () => {
        try {
            let theBundles = await Request.h64HttpRequest("GET", "/getLibrarySmartContractsPlugins", {});
            return theBundles.smartContracts || [];
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        try {
            const fetch = async () => {
                let theBundles = await getProjectBundles(projectId);
                setBundles(theBundles);
                /*
                contracts = await getLibrarySmartContracts();
                setBundles(contracts);
                let plugins = await getLibrarySmartContractsPlugins();
                setLibrarySmartContractPlugins(plugins);
                */
            }
            fetch();
        } catch (err) {
            console.error(err);
        }
    }, []);


    let deleteBundle = async () => {
        try {
            let deleteSC = await Request.h64HttpRequest("DELETE", "/deleteBundle/" + bundle._id, {});
            if ( deleteSC )
                if ( deleteSC.errorCode !== undefined && deleteSC.errorMessage !== undefined ){
                    props.setError(deleteSC.errorMessage);
                    if (deleteSC.errorCode === null && deleteSC.errorMessage === null) {
                        setBundles(bundles.filter(_bundle => String(_bundle._id) !== String(bundle._id)));
                        setDeleting(false);
                        setBundle(null);
                    }
                }
        } catch (err) {
            console.error(err);
        }
    }

    let getBlockchainCreationDate = (row) => {
        try {
            return <>{moment(row.creationDate).format("L HH:mm:ss")}</>
        } catch (err) {
            console.error(err);
            return <></>;
        }
    }

    let formatSubtypesTitle = (subtypes) => {
        if (typeof subtypes === "string")
            return subtypes;
        if (typeof subtypes === "object")
            if (subtypes.length <= 1)
                return subtypes[0];
            else {
                let toReturn = "";
                for (let i = 0, l = subtypes.length; i < l; ++i)
                    toReturn += subtypes[i] + (i < l - 1 ? "," : "");
                return toReturn;
            }
    }

    let getSmartContractsDependencies = async (id) => {
        let theDependencies = await Request.h64HttpRequest("PUT", "/getSmartContractDependencies", { smartContract: id });
        if (theDependencies) {
            props.setError(theDependencies.errorMessage);
            if (theDependencies.errorCode === null && theDependencies.errorMessage === null)
                return theDependencies;
        }
        return {};
    }

    let goToDeployThisContract = async (plugin) => {
        history.push("/smartContractWizard/smartContractConstructor");
    }

    return (
        <>

            <Popper open={popperOpen} anchorEl={popperAnchorEl} placement={popperPlacement} transition>
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                    <div className="container p-3">
                        <h4>For plugins</h4>
                        <div>
                            {
                                popperContent.plugins.map((plugin,id) => 
                                    <div key={id} class="container p-3">
                                        <div class="row mt-3">
                                            <p>{plugin.title}</p>
                                            <SuccessButton onClick={() => goToDeployThisContract(plugin)} label="Deploy" style="ml-4" />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    </Paper>
                </Fade>
                )}
            </Popper>

            <div className="page-header  m-0 p-0 w-100 d-flex page-header-min">
                <div class="w-100 page-header-title">
                    Smart contracts
                </div>
                <div class="flex-shrink-1 mb-4 mr-4">
                    <Link to={"/smartContractMenu/" + blockchainId + "/" + projectId} className="link-action-sumbit">
                        Add a Smart contract
                    </Link>
                </div>
            </div>

            <div className="container">
                <div className="mt-4">

                    <div className="mb-1 text-mini-1 pb-4 pt-4" >
                        <div className="row">
                            <div className="col-sm-2">
                                <BackButton />
                            </div>
                            <div className="col-sm-8 ml-4">
                                {blockchainId} <i className="fas fa-chevron-right ml-2 mr-2"></i> {projectId}
                                <div className="text-maxi-1" >
                                    Smart contracts deployed
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {
                            bundles &&
                            <>
                                {
                                    bundles.length > 0 &&
                                    <>


                                        <div className="mt-4 mb-2 w-100">
                                            <h6>Smart contracts from Blockadoc library</h6>
                                        </div>
                                        {
                                            bundles.map((bundle, id) => 
                                                <div key={id} >
                                                    {
                                                        bundle._userAccountId !== props.user.h64AccountID && 
                                                        <BundleFrame 
                                                            bundle={bundle} 
                                                            deleteBundle={requestDeleteBundle} 
                                                            blockchainId={blockchainId}
                                                            projectId={projectId}
                                                            plugins={false}
                                                        />
                                                    }
                                                </div>
                                            )
                                        }


                                        <div className="mt-4 mb-2 w-100">
                                            <h6>Your smart contracts</h6>
                                        </div>
                                        {
                                            bundles.map((bundle, id) =>
                                            <div key={id} >
                                                {
                                                    bundle._userAccountId === props.user.h64AccountID && 
                                                        <BundleFrame 
                                                            bundle={bundle} 
                                                            deleteBundle={requestDeleteBundle} 
                                                            blockchainId={blockchainId}
                                                            projectId={projectId}
                                                            plugins={false}
                                                        />
                                                    }
                                                </div>
                                            )
                                        }
                                    </>
                                }

                                {
                                    bundles.length === 0 && 
                                    <IconNotification 
                                        redirectTo={"/smartContractMenu/" + blockchainId + "/" + projectId} 
                                        iconURL="/icons/add_folder_icon.svg"
                                        notificationMessage="No smart contract... Let’s get started shall we?"
                                    />
                                }

                            </>
                        }
                    </div>

                    {
                        bundle && 
                        <Modal show={isDeleting} onHide={hideDeleteModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Smart Contract ID #{bundle._id}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                Are you sure you want to delete this smart contract ?

                        </Modal.Body>
                            <Modal.Footer>
                                <CancelButton style="float-left" onClick={hideDeleteModal} label="Cancel" />
                                <DangerButton onClick={deleteBundle} label="Delete" />
                            </Modal.Footer>
                        </Modal>
                    }

                </div>
            </div>
        </>
    );
}