import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import getCategories from './Functions/getCategories.js';
import SuccessButton from './Common/SuccessButton.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SmartContractDeployNotification from './SmartContractWizard/SmartContractDeployNotification.js';
import Checkbox from '@material-ui/core/Checkbox';
const Request = require('./Config/Request.js');


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export default function ImportSmartContract(props) {

    const { blockchainId, projectId } = useParams();
    const [errorCode, setErrorCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    const classes = useStyles();
    const [abi, setABI] = useState("");
    const [name, setName] = useState("");
    const [isPublic, setIsPublic] = useState(false);
    const [broadAvailable, setBroadAvailable] = useState(false);
    const [description, setDescription] = useState("");
    const [address, setAddress] = useState("");
    const [bytecode, setBytecode] = useState("");
    const [categories, setCategories] = useState(null);
    const [types, setTypes] = useState([]);
    const [subtypes, setSubtypes] = useState([]);
    const [receivedSmartContractObject, setReceivedContractObject] = useState(null);

    const importSmartContract = async () => {
        let abiToSend = [];
        try {
            abiToSend = JSON.parse(abi);
        } catch (err) {
            console.error(err);
        }
        try {
            let importTheSmartContract = await Request.h64HttpRequest("POST", "/importSmartContract", {
                blockchainId : blockchainId,
                projectId : projectId,
                type : types,
                subtype : subtypes,
                title : name,
                broadAvailable : broadAvailable,
                description : description,
                address : address,
                abi : abiToSend,
                bytecode : bytecode,
                isPublic : isPublic
            });
            if ( importTheSmartContract ) 
                if ( importTheSmartContract.errorCode !== undefined && importTheSmartContract.errorMessage !== undefined ) 
                    if ( importTheSmartContract.errorCode === null && importTheSmartContract.errorMessage === null )
                        if ( importTheSmartContract.smartContract )
                            setReceivedContractObject(importTheSmartContract.smartContract);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        try {
            const fetch = async () => {
                let typesSubtypes = await getCategories();
                setCategories(typesSubtypes);
            }
            fetch();
        } catch (err) {
            console.error(err);
        }
    }, []);

    let checkSubtype = (subtype) => {
        try {

            for (let i = 0, l = subtypes.length; i < l; ++i)
                if (subtypes[i] === subtype)
                    return true;
            return false;

        } catch (err) {
            console.error(err);
        }
    }

    let setTheSubtypes = (typeId, type, subtype) => {
        try{

            let typeIndex = null , subtypeIndex = null , aux = null;
            for (let i = 0, l = types.length; i < l; ++i)
                if (String(types[i]).toLowerCase() === String(type).toLowerCase()) {
                    typeIndex = i;
                    break;
                }

            for (let i = 0, l = subtypes.length; i < l; ++i)
                if (String(subtypes[i]).toLowerCase() === String(subtype).toLowerCase()) {
                    subtypeIndex = i;
                    break;
                }

            // New type and subtype
            if ( typeIndex === null && subtypeIndex === null ) {
                setTypes([ ...types, type ]);
                setSubtypes([ ...subtypes, subtype ]);
            } else if ( subtypeIndex === null && typeIndex !== null ) {
                setSubtypes([ ...subtypes, subtype ]);
            } else if ( subtypeIndex !== null && typeIndex !== null ) {
                setSubtypes(subtypes.filter((_subtype,_id) => _id !== subtypeIndex));
                let stillHasSubtypes = false;
                let newSubtypes = subtypes.filter((_subtype,_id) => _id !== subtypeIndex);

                for ( let i = 0 , l = categories[typeId].subcategories.length ; i < l ; ++i )
                    for ( let j = 0 , k = newSubtypes.length ; j < k ; ++j )
                        if ( String(categories[typeId].subcategories[i].name).toLowerCase() === String(newSubtypes[j]).toLowerCase() ) {
                            stillHasSubtypes = true;
                            break;
                        }
                
                if ( !stillHasSubtypes )
                    setTypes(types.filter((_type,_id) => _id !== typeIndex));
            }

        } catch (err) {
            console.error(err);
        }

    }

    return (
        <div>

            <div className="page-header m-0 p-0 w-100 d-flex">
                <div className="page-header-title">Import Smart Contract</div>
            </div>

            {
                receivedSmartContractObject === null ? (
                    <>
                        <div className="row">
                            {
                                categories && categories.map((cat, catid) =>
                                    <div className="col-sm-3" key={catid} >
                                        <ul className="list-infos">
                                            <li className="text-uppercase text-color60 mb-1 li-no-bullet">
                                                <div className="row">
                                                    {cat.name}
                                                </div>
                                            </li>
                                            {
                                                cat.subcategories && cat.subcategories.map((subcat, subcatid) =>
                                                    <li className="li-no-bullet" key={subcatid} >
                                                        <FormControlLabel
                                                            control={
                                                                <>
                                                                    <Checkbox
                                                                        checked={checkSubtype(subcat.name)}
                                                                        onChange={() => setTheSubtypes(catid, cat.name, subcat.name)}
                                                                        size="small"
                                                                        color="primary"
                                                                    />
                                                                    <i className={subcat.externalResourcesPath ? subcat.externalResourcesPath[0] : ""}></i>
                                                                </>
                                                            }
                                                            label={subcat.name}
                                                        />
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                )
                            }
                        </div>

                        <div className="container mt-3 mb-3 p-4">
                            <div className="row">
                                <div className="col-sm-4">
                                    <TextField
                                        label="Pass Smart Contract ABI here"
                                        multiline
                                        rows={12}
                                        defaultValue={abi}
                                        onChange={(e) => setABI(e.target.value)}
                                        variant="outlined"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isPublic}
                                                onChange={() => setIsPublic(!isPublic)}
                                                color="primary"
                                            />
                                        }
                                        label="Can be forked?"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={broadAvailable}
                                                onChange={() => setBroadAvailable(!broadAvailable)}
                                                color="primary"
                                            />
                                        }
                                        label="Can be used by others?"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <TextField
                                        label="Pass Smart Contract bytecode here"
                                        multiline
                                        rows={8}
                                        defaultValue={bytecode}
                                        onChange={(e) => setBytecode(e.target.value)}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-sm-4">
                                    <TextField
                                        label="Smart Contract name here"
                                        defaultValue={name}
                                        onChange={(e) => setName(e.target.value)}
                                        variant="outlined"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <TextField
                                        label="Smart Contract description here"
                                        defaultValue={address}
                                        onChange={(e) => setDescription(e.target.value)}
                                        variant="outlined"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <TextField
                                        label="Pass Smart Contract address here"
                                        defaultValue={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-center text-center mt-5">
                                <SuccessButton onClick={importSmartContract} label="Upload" />
                            </div>
                        </div>                    
                    </>
                ) : (
                    <SmartContractDeployNotification blockchainId={blockchainId} projectId={projectId} smartContractId={receivedSmartContractObject._id} />
                )
            }

        </div>
    );

}