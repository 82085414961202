import React, { useState, useEffect } from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
//import i18n from '../Libraries/I18n/config/i18n.js';
import moment from 'moment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LanguageIcon from '@material-ui/icons/Language';
import AppsIcon from '@material-ui/icons/Apps';
import Alert from '@material-ui/lab/Alert';
import Notification from './Notification.js';
import Web3 from 'web3';
import { makeStyles } from '@material-ui/core/styles';
import SuccessButton from './Common/SuccessButton.js';
const Request = require('./Config/Request.js');


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


export default function MyAccount(props) {

    const classes = useStyles();
    const [web3,setWeb3] = useState(null);
    let [errorCode, setErrorCode] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    let [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    let [userUpdated, setUserUpdated] = useState(false);
    let [blockchains, setBlockchains] = useState([]);
    let [currentLanguage, setCurrentLanguage] = useState(sessionStorage.getItem("i18nextLng"));
    let [projects, setProjects] = useState([]);

    let changeLanguage = (event) => {
        try {

            //i18n.changeLanguage(event.target.value);
            setCurrentLanguage(event.target.value);

        } catch ( err ) {
            console.error(err);
        }
    }

    let getBlockchains = async () => {
        try {
            let names = await Request.h64HttpRequest("GET", "/blockchains", {});
            if ( names )
                return names.blockchains || [];
            return [];
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    let getMyProjects = async () => {
        try {
            let projects = await Request.h64HttpRequest("GET", "/getApplications", {});
            if ( projects )
                return projects.applications || [];
            return [];
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    let getAccount = async () => {
        try {
            let account = await Request.h64HttpRequest("GET", "/v1/account/listOne", {});
            if ( account )
                return account.user || {};
            return {};
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    let updateUserInfos = async () => {
        try {

            let theUser = JSON.parse(sessionStorage.getItem('user'));
            let updateAccount = await Request.httpRequest("PATCH", "/updateAccount", {
                email: user.email,
                name: user.name,
                h64AccountId: theUser.h64AccountId
            });

            if ( updateAccount )
                if ( updateAccount.errorCode !== undefined && updateAccount.errorMessage !== undefined ) {
                    setErrorCode(updateAccount.errorCode);
                    setErrorMessage(updateAccount.errorMessage);
                    if ( updateAccount.errorCode === null && updateAccount.errorMessage === null ){
                        theUser.name = user.name;
                        theUser.email = user.email;
                        setUser(theUser);
                        sessionStorage.setItem("user", JSON.stringify(theUser));
                        props.setUser(theUser);
                        setUserUpdated(true);
                    }
                }

        } catch ( err ) {
            console.error(err);
        }
    }

    let getBlockchain = (id) => {
        try {
            for (let i = 0, l = blockchains.length; i < l; ++i)
                if (blockchains[i]._id === id)
                    return blockchains[i];
            return {};
        } catch ( err ) {
            console.error(err);
            return {};
        }
    }


    useEffect(() => {
        try {
            const fetch = async () => {
                props.setBlockchain(null);
                props.setProject(null);
                let newUser = await getAccount();
                let theBlockchains = await getBlockchains();
                let theProjects = await getMyProjects();
                setProjects(theProjects);

                let blockchainAccountBalances = await Request.h64HttpRequest("GET","/v1/account/balances",{});
                console.log("Balances are >> " + JSON.stringify(blockchainAccountBalances));
                if ( blockchainAccountBalances )
                    if ( blockchainAccountBalances.errorCode !== undefined && blockchainAccountBalances.errorMessage !== undefined ){
                        props.setError(blockchainAccountBalances.errorMessage);
                        if ( blockchainAccountBalances.errorMessage === null && blockchainAccountBalances.errorCode === null ){
                            props.setUser(blockchainAccountBalances.blockchainAccounts);
                            newUser.blockchainAccounts = blockchainAccountBalances.blockchainAccounts;
                        }
                    }

                if (window.ethereum) {
                    window.ethereum.enable();
                    let theWeb3 = new Web3(window.ethereum);
                    setWeb3(theWeb3);
                }
                setBlockchains(theBlockchains);
                setUser(newUser);
            }
            fetch();
        } catch (err) {
            console.error(err);
        }
    }, []);

    let renderErrorMessageDiv = () => {
        try {
            return <>
                <div className={"mt-5 mb-5" + (errorCode === null && errorMessage === null ? " d-none" : "")}>
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        {errorMessage}
                        <button type="button" className="close" data-dismiss="alert" onClick={() => { setErrorCode(null); setErrorMessage(null); }} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </>;
        } catch ( err ) {
            console.error(err);
            return <Notification errorMessage="Something went wrong, please reload the page" />
        }
    }

    const getProject = (id) => {
        for ( let i = 0 , l = projects.length ; i < l ; ++i )
            if ( String(projects[i]._id) === String(id) )
                return projects[i];
        return {};
    }

    let renderUserUpdateDiv = () => {
        try {
            return <div className="card p-4 mb-3">
                <div className="row mb-3 w-100 justify-content-center text-center">
                    {
                        userUpdated && 
                        <Alert onClose={() => setUserUpdated(false)} fullWidth className="w-100" >
                            Account successfully updated!
                        </Alert>
                    }
                </div>
                <div className="row">

                    <div className="col-6">
                        <InputLabel className="text-dark" >Email</InputLabel>
                        <Form.Control value={user ? user.email : ""} type="email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
                    </div>

                    <div className="col-6">
                        <InputLabel className="text-dark" >Name</InputLabel>
                        <Form.Control value={user ? user.name : ""} onChange={(e) => setUser({ ...user, name: e.target.value })} />
                    </div>
                </div>

                <div className="row justify-content-center text-center mt-2 mb-2">
                    <SuccessButton onClick={updateUserInfos} label="Update" />
                </div>
            </div>;
        } catch ( err ) {
            console.error(err);
            return <Notification errorMessage="Something went wrong, please reload the page" />
        }
    }

    let renderReadOnlyDiv = () => {
        try {
            return <>
                <div>
                    <Form.Group>
                        <InputLabel className="text-dark" >API Key</InputLabel>
                        <Form.Control value={user ? user.key : ""} readOnly />
                    </Form.Group>
                </div>

                <div className="mt-3">
                    <InputLabel className="text-dark" >Created</InputLabel>
                    <Form.Control value={user ? moment(user.creationDate).format("L HH:mm:ss") : ""} readOnly />
                </div>
            </>;
        } catch ( err ) {
            console.error(err);
            return <Notification errorMessage="Something went wrong, please reload the page" />
        }
    }

    let renderBlockchainAccountsDiv = () => {
        try { 
            return <>
                {
                    user && 
                    <>
                        {
                            typeof user.blockchainAccounts !== "undefined" && user.blockchainAccounts.length > 0 ? ( 
                            <div className="mt-5 mb-5">
                                <div className="m-0 p-0 w-100 mt-5 mb-5 h5">
                                    Blockchain accounts
                                </div>
                                {
                                    user.blockchainAccounts.map((ba, baid) =>
                                        <div key={baid}>
                                            <InputLabel className="text-dark" >
                                                <LanguageIcon/> Blockchain account : {getBlockchain(ba.blockchainId).name}
                                            </InputLabel>
                                            <InputLabel className="text-dark" >
                                                <AppsIcon/> Project name : {getProject(ba.projectId).name}
                                            </InputLabel>
                                            <Form.Group>
                                                <Form.Control value={ba.accountAddress} readOnly />
                                                {
                                                    getBlockchain(ba.blockchainId).publicBlockchain && 
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'wrap',
                                                    }}>
                                                        {
                                                            typeof ba.balance !== "undefined" && 
                                                            <>
                                                                <AccountBalanceWalletIcon />
                                                                <span className="text-dark ml-3 font-weight-bold" >
                                                                    Balance : {ba.balance} ETH
                                                                </span>
                                                            </>
                                                        }
                                                    </div> 
                                                }
                                            </Form.Group>
                                            <div className="row mb-5">
                                                <div className="col-sm-2">
                                                    <TextField 
                                                        label="Network name" 
                                                        type="search" 
                                                        className="mt-2 mb-2 mr-2 ml-2" 
                                                        variant="outlined" 
                                                        InputLabelProps={{ shrink: true }}
                                                        value={getBlockchain(ba.blockchainId).name}
                                                        readOnly={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <TextField 
                                                        label="RPC" 
                                                        type="search" 
                                                        className="mt-2 mb-2 mr-2 ml-2" 
                                                        variant="outlined" 
                                                        InputLabelProps={{ shrink: true }}
                                                        value={getBlockchain(ba.blockchainId).nodes && getBlockchain(ba.blockchainId).nodes[0] ? getBlockchain(ba.blockchainId).nodes[0].nodeRPCPort : ""}
                                                        readOnly={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <TextField
                                                        label="Network ID" 
                                                        type="search" 
                                                        className="mt-2 mb-2 mr-2 ml-2" 
                                                        variant="outlined" 
                                                        InputLabelProps={{ shrink: true }}
                                                        value={getBlockchain(ba.blockchainId).networkId}
                                                        readOnly={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <TextField 
                                                        label="Symbol" 
                                                        type="search" 
                                                        className="mt-2 mb-2 mr-2 ml-2" 
                                                        variant="outlined" 
                                                        InputLabelProps={{ shrink: true }}
                                                        value={""}
                                                        readOnly={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <TextField 
                                                        label="Explorer URL" 
                                                        type="search" 
                                                        className="mt-2 mb-2 mr-2 ml-2" 
                                                        variant="outlined" 
                                                        InputLabelProps={{ shrink: true }}
                                                        value={getBlockchain(ba.blockchainId).nodes && getBlockchain(ba.blockchainId).nodes[0] ? getBlockchain(ba.blockchainId).nodes[0].dashboard : ""}
                                                        readOnly={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            ) : (
                                <div className="mt-5 mb-5">
                                    <div className="alert alert-primary" role="alert">
                                        <h4 className="alert-heading">You have no blockchain accounts created yet</h4>
                                    </div>
                                </div>
                            )
                        }
                    </>
                }

            </>;
        } catch ( err ) {
            console.error(err);
            return <Notification errorMessage="Something went wrong, please reload the page" />
        }
    }

    let renderChangeLanguageDiv = () => {
        try {
            return <div className="card mt-5 mb-5 border-0">
                <Form.Group controlId="exampleForm.ControlSelect1" className="mb-3" >
                    <Form.Label>Language</Form.Label>
                    <div>
                        <select className="mt-2" onChange={changeLanguage} >
                            {
                                currentLanguage === "en" ? (
                                    <>
                                        <option value={"en"} name="en" id="en" key={"en"} >English</option>
                                        <option value={"fr"} name="fr" id="fr" key={"fr"} >French</option>
                                    </>
                                ) : (
                                        <>
                                            <option value={"fr"} name="fr" id="fr" key={"fr"} >French</option>
                                            <option value={"en"} name="en" id="en" key={"en"} >English</option>
                                        </>
                                    )
                            }
                        </select>
                    </div>
                </Form.Group>
            </div>;
        } catch ( err ) {
            console.error(err);
            return <Notification errorMessage="Something went wrong, please reload the page" />
        }
    }

    return (
        <div>

            <div className="page-header m-0 p-0 w-100 d-flex">
                <div className="page-header-title">Account & Profile</div>
            </div>

            {renderErrorMessageDiv()}

            <div className="container mt-3 mb-3 p-4">

                {renderUserUpdateDiv()}

                {renderReadOnlyDiv()}

                {renderBlockchainAccountsDiv()}

                {/*
                    renderChangeLanguageDiv()
                */}
                
            </div>
        </div >
    );

}