import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
//import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import Parameters from './Config/Parameters.js';
const Request = require('./Config/Request.js');


export default function NodesInfos(props) {
    const [errorCode, setErrorCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [blockchainId, setBlockchainId] = useState(props.blockchainId);
    const [blockchain, setBlockchain] = useState({});
    const [node, setNode] = useState({});
    const user = props.user;
    const [isUpdating, setUpdating] = useState(false);
    const [isRemoving, setRemoving] = useState(false);
    const [isViewingVPSInfos, setViewVPSInfos] = useState(false);
    const [sureToReboot, setSureToReboot] = useState(false);
    const [vpsInfos, setVpsInfos] = useState({});
    const [addingNode, setAddingNode] = useState(null);
    const [region, selectRegion] = useState(undefined);
    const [instance, setInstance] = useState(undefined);
    const [regions, setRegions] = useState([]);
    const [instances, setInstances] = useState([]);

    console.log("The blockchain ID is : " + blockchainId);

    let getBlockchain = async () => {
        try {
            let blockchain = await Request.h64HttpRequest("GET", "/blockchain/" + blockchainId, {});
            if (typeof blockchain.blockchain !== "undefined")
                return blockchain.blockchain;
            return [];
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    let chooseRegion = (e) => {
        selectRegion(e.target.value);
    }
    let chooseInstance = (instanceObj) => {
        setInstance(instanceObj.InstanceType);
    }

    let deploy = async () => {
        try {
            if (typeof addingNode.nodeName !== "undefined" && addingNode.nodeName.length === 0) {
                setErrorCode(404);
                setErrorMessage("Please specify a name for this node");
                return;
            }

            Request.h64HttpRequest("POST", "/addNewNode", { blockchainID: blockchainId, node: addingNode, region: region, instanceType: instance, accountID: user.h64AccountID });
            hideAddingNode();

        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        try {

            const fetch = async () => {

                let blockchain = await getBlockchain();
                setBlockchain(blockchain);

            }

            fetch();

        } catch (err) {
            console.error(err);
        }
    }, []);

    let dashboardRender = (cell, row) => {
        return <div className="ml-2 bc-node-text"><a href={row.dashboard} target="_blank"><i className="fa fa-bar-chart fa-2x mr-4"></i> {row.nodeName}</a></div>;
    }

    let update = (node) => {
        setNode(node);
        setUpdating(true);
    }

    let rebootNode = async () => {
        try {
            await Request.httpRequest("GET", "/rebootVPS/" + user.accountID + "/" + user.passwordRaw + "/" + node.VPSName);
        } catch (err) {
            console.error(err);
        }
    }

    let reboot = (node) => {
        setNode(node);
        setSureToReboot(true);
    }

    let hideSureToReboot = () => {
        setNode({});
        setSureToReboot(false);
    }

    let hideUpdate = () => {
        setNode({});
        setUpdating(false);
    }

    const options = {
        clickToEdit: true,
        clickToSelect: true,
    };

    let setVPSInfos = (node) => {
        setNode(node);
        setViewVPSInfos(true);
        getVPSInfos(node);
    }

    let remove = (node) => {
        setNode(node);
        setRemoving(true);
    }

    let buttons = (cell, row) => {
        return <div className="ml-3" >
            <div className="row">
                {
                    typeof user.blockchainAccounts !== "undefined" &&
                    <>
                        {
                            blockchain.owner === user.blockchainAccounts[0].accountAddress &&
                            <Button variant="danger" className="text-light" onClick={() => remove(row)} ><i className="fa fa-trash" aria-hidden="true"></i></Button>
                        }
                    </>
                }

                {/* <Button variant="primary" className="text-light ml-2" onClick={() => setVPSInfos(row)} ><i className="fa fa-industry" aria-hidden="true"></i></Button> */}

            </div>
            {
                typeof user.blockchainAccounts !== "undefined" &&
                <>
                    {
                        blockchain.owner === user.blockchainAccounts[0].accountAddress &&
                        <div className="row mt-2">
                            <Button variant="warning" className="text-light" onClick={() => update(row)} ><i className="fa fa-edit" aria-hidden="true"></i></Button>
                            <Button variant="danger" className="text-light ml-2" onClick={() => reboot(row)} ><i className="fa fa-refresh" aria-hidden="true"></i></Button>
                        </div>
                    }
                </>
            }
        </div>;
    }

    let hideVPSInfos = () => {
        setNode({});
        setViewVPSInfos(false);
    }

    let updateNode = async () => {
        try {
            let update = await Request.h64HttpRequest("PATCH", "/updateNode", { blockchainID: blockchainId, nodeId: node._id, node: node });
            if (update.errorCode === null && update.errorMessage === null) {
                let blockchainUpdated = blockchain;
                for (let i = 0, l = blockchainUpdated.nodes.length; i < l; ++i)
                    if (blockchainUpdated.nodes[i]._id === node._id)
                        blockchainUpdated.nodes[i] = node;
                setBlockchain(blockchainUpdated);
                setUpdating(false);
                setNode({});
            }
        } catch (err) {
            console.error(err);
        }
    }

    let hideSureToRemove = () => {
        setNode({});
        setRemoving(false);
    }

    let removeNode = async () => {
        try {
            let remove = await Request.h64HttpRequest("DELETE", "/deleteNode", { blockchainID: blockchainId, nodeID: node._id });
            setErrorCode(remove.errorCode);
            setErrorMessage(remove.errorMessage);
            if (remove.errorCode === null && remove.errorMessage === null) {
                let bchain = blockchain;
                let newNodes = [];
                for (let i = 0, l = bchain.nodes.length; i < l; ++i)
                    if (bchain.nodes[i]._id !== node._id)
                        newNodes.push(bchain.nodes[i]);
                bchain.nodes = newNodes;
                setBlockchain(bchain);
            }
            setRemoving(false);
            setNode({});
        } catch (err) {
            console.error(err);
        }
    }

    let hideAddingNode = () => {
        setAddingNode(null);
    }

    let addNode = async () => {
        try {
            setAddingNode(Parameters.nodeVessel);
            setRegions({ Regions: [{ RegionName: "eu-north-1" }] });
            let getInstances = await Request.h64HttpRequest("GET", "/provider/aws/describeInstanceTypes", {});
            setErrorCode(getInstances.errorCode);
            setErrorMessage(getInstances.errorMessage);
            if (getInstances.errorCode === null && getInstances.errorMessage === null) {
                let instancesToShow = [];
                for (let i = 0, l = getInstances.instances.InstanceTypes.length; i < l; ++i)
                    if (Number(getInstances.instances.InstanceTypes[i].VCpuInfo.DefaultVCpus) <= 2)
                        instancesToShow.push(getInstances.instances.InstanceTypes[i]);
                setInstances(instancesToShow);
            }
        } catch (err) {
            console.error(err);
        }
    }

    let getVPSInfos = async (node) => {
        try {
            let getVPSInfos = await Request.httpRequest("GET", "/getVPSInfos/" + user._id + "/" + user.password + "/" + node.VPSName, {});
            setVpsInfos(getVPSInfos.infos);
        } catch (err) {
            console.error(err);
        }
    }

    let nodeStatus = (cell, row) => {
        try {
            for (let i = 0, l = blockchain.nodes.length; i < l; ++i)
                if (blockchain.nodes[i].dashboard === row.dashboard && blockchain.nodes[i].provider === row.provider)
                    return <>
                        <span className="text-success"><i className="fas fa-long-arrow-alt-up fa-2x"></i></span>
                    </>;
            return <>
                {
                    typeof row.isRunning !== "undefined" && row.isRunning === true &&
                    <div className="row text-wrap ml-2 mr-2">
                        Node is running , please wait...
                    </div>
                }
                {
                    typeof row.statusOK !== "undefined" && row.statusOK === true &&
                    <div className="row text-wrap ml-2 mr-2">
                        Node status ok , please wait...
                    </div>
                }
                {
                    typeof row.installingBlockchain !== "undefined" && row.installingBlockchain === true &&
                    <div className="row text-wrap ml-2 mr-2">
                        Installing H64 blockchain core...
                    </div>
                }
            </>;
        } catch (err) {
            console.error(err);
        }
    }



    return (
        <div>
            {
                blockchain !== null && typeof blockchain.owner !== "undefined" && blockchain.owner === user.h64AccountID &&
                <Button className="btn-card-sumbit mb-3 mx-auto" onClick={addNode} >Add Node</Button>
            }
            <div className={"mt-5 mb-5" + (errorCode === null && errorMessage === null ? " d-none" : "")}>
                <div className="alert alert-danger" role="alert">{errorMessage}</div>
            </div>

            <BootstrapTable version='4' data={blockchain.nodes || []} keyField='_id'
                hover
                tableContainerClass='table-nodes'
                tableBodyClass='table-nodes-body'
                headerContainerClass='table-nodes-header'
            >
                <TableHeaderColumn dataFormat={dashboardRender}  width='70%'  >Nodes</TableHeaderColumn>
                <TableHeaderColumn dataFormat={nodeStatus} dataSort={true} width='20%' >Status</TableHeaderColumn>
                <TableHeaderColumn dataFormat={buttons} width='10%' >&nbsp;</TableHeaderColumn>
            </BootstrapTable>



            {
                node &&
                <>
                    <Modal show={isUpdating} onHide={hideUpdate}>
                        <Modal.Header closeButton>
                            <Modal.Title>Node ID #{node._id}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Form.Group>
                                <InputLabel className="text-dark" >Node name</InputLabel>
                                <Form.Control value={node.nodeName} onChange={(e) => setNode({ ...node, nodeName: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >VPS Name</InputLabel>
                                <Form.Control value={node.VPSName} onChange={(e) => setNode({ ...node, VPSName: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >VPS Localisation</InputLabel>
                                <Form.Control value={node.VPSlocalisation} onChange={(e) => setNode({ ...node, VPSlocalisation: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >VPS Login</InputLabel>
                                <Form.Control value={node.VPSLogin} onChange={(e) => setNode({ ...node, VPSLogin: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >VPS Password</InputLabel>
                                <Form.Control value={node.VPSPassword} onChange={(e) => setNode({ ...node, VPSPassword: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Node IPv4</InputLabel>
                                <Form.Control value={node.nodeIPv4} onChange={(e) => setNode({ ...node, nodeIPv4: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >VPS port</InputLabel>
                                <Form.Control value={node.VPSPort} onChange={(e) => setNode({ ...node, VPSPort: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Node RPC port</InputLabel>
                                <Form.Control value={node.nodeRPCPort} onChange={(e) => setNode({ ...node, nodeRPCPort: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Node network listening port</InputLabel>
                                <Form.Control value={node.nodeNetworkListeningPort} onChange={(e) => setNode({ ...node, nodeNetworkListeningPort: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Node constellation port</InputLabel>
                                <Form.Control value={node.nodeConstellationPort} onChange={(e) => setNode({ ...node, nodeConstellationPort: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Node Raft port</InputLabel>
                                <Form.Control value={node.nodeRaftPort} onChange={(e) => setNode({ ...node, nodeRaftPort: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Node manager port</InputLabel>
                                <Form.Control value={node.nodeNodeManagerPort} onChange={(e) => setNode({ ...node, nodeNodeManagerPort: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Node WS</InputLabel>
                                <Form.Control value={node.nodeWS} onChange={(e) => setNode({ ...node, nodeWS: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Dashboard</InputLabel>
                                <Form.Control value={node.dashboard} onChange={(e) => setNode({ ...node, dashboard: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Provider</InputLabel>
                                <Form.Control value={node.provider} onChange={(e) => setNode({ ...node, provider: e.target.value })} />
                            </Form.Group>

                            <Form.Group>
                                <InputLabel className="text-dark" >Node public key</InputLabel>
                                <Form.Control value={node.nodePublicKey} onChange={(e) => setNode({ ...node, nodePublicKey: e.target.value })} />
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="float-left" onClick={hideUpdate}>Cancel</Button>
                            <Button variant="warning" className="text-light" onClick={updateNode} >Ok</Button>
                        </Modal.Footer>
                    </Modal>

                    {
                        isViewingVPSInfos &&
                        <Modal show={isViewingVPSInfos} onHide={hideVPSInfos}>
                            <Modal.Header closeButton>
                                <Modal.Title>Node ID #{node._id}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="overflow-wrap-anywhere" >
                                {
                                    typeof vpsInfos.displayName !== "undefined" &&
                                    <>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Display Name : {vpsInfos.displayName}</InputLabel>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicCheckbox">
                                            <InputLabel className="text-dark" >SLA Monitoring :
                                                    {typeof vpsInfos.slaMonitoring !== "undefined" &&
                                                    <>{vpsInfos.slaMonitoring ? "YES" : "NO"}</>
                                                }
                                            </InputLabel>
                                        </Form.Group>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Cores : {vpsInfos.vcore}</InputLabel>
                                        </Form.Group>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Memory Limit : {vpsInfos.memoryLimit}</InputLabel>
                                        </Form.Group>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Maximum Additionnal IP :
                                                    {
                                                    typeof vpsInfos.model !== "undefined" &&
                                                    <>
                                                        <br />
                                                        {vpsInfos.model.maximumAdditionnalIp}
                                                    </>
                                                }
                                            </InputLabel>
                                        </Form.Group>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >AvailableOptions :
                                                {
                                                    typeof vpsInfos.model !== "undefined" &&
                                                    <>
                                                        <br />
                                                        {String(JSON.stringify(vpsInfos.model.availableOptions)).slice(1, String(JSON.stringify(vpsInfos.model.availableOptions)).length - 1)}
                                                    </>
                                                }
                                            </InputLabel>
                                        </Form.Group>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Blocked IP addresses :
                                                {
                                                    typeof vpsInfos.monitoringIpBlocks !== "undefined" &&
                                                    <>
                                                        {String(JSON.stringify(vpsInfos.monitoringIpBlocks)).slice(1, String(JSON.stringify(vpsInfos.monitoringIpBlocks)).length - 1)}
                                                    </>
                                                }
                                            </InputLabel>
                                        </Form.Group>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Disk :
                                                {
                                                    typeof vpsInfos.model !== "undefined" &&
                                                    <>
                                                        {vpsInfos.model.disk}
                                                    </>
                                                }
                                            </InputLabel>
                                        </Form.Group>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >State : {vpsInfos.state}</InputLabel>
                                        </Form.Group>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Net boot mode : {vpsInfos.netbootMode}</InputLabel>
                                        </Form.Group>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Zone : {vpsInfos.zone}</InputLabel>
                                        </Form.Group>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Cluster : {vpsInfos.cluster}</InputLabel>
                                        </Form.Group>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Name : {vpsInfos.name}</InputLabel>
                                        </Form.Group>
                                        <Form.Group>
                                            <InputLabel className="text-dark" >Data center :
                                                {
                                                    typeof vpsInfos.model !== "undefined" &&
                                                    <>
                                                        {String(JSON.stringify(vpsInfos.model.datacenter)).slice(1, String(JSON.stringify(vpsInfos.model.datacenter)).length - 1)}
                                                    </>
                                                }
                                            </InputLabel>
                                        </Form.Group>
                                    </>
                                }
                                {
                                    typeof vpsInfos.displayName === "undefined" &&
                                    <div className="text-center">
                                        <h5>Wait a few moments , we're getting the VPS infos for you</h5>
                                    </div>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="float-left" onClick={hideVPSInfos}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                    }


                    <Modal show={sureToReboot} onHide={hideSureToReboot}>
                        <Modal.Header closeButton>
                            <Modal.Title>Node ID #{node._id}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="overflow-wrap-anywhere" >
                            Are you sure you want to reboot this node ?
                                </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="float-left" onClick={hideSureToReboot}>Cancel</Button>
                            <Button variant="danger" className="float-left" onClick={rebootNode}>Reboot</Button>
                        </Modal.Footer>
                    </Modal>

                    {
                        addingNode !== null &&
                        <>
                            <Modal show={addingNode !== null} onHide={hideAddingNode}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Node ID #{node._id}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="overflow-wrap-anywhere" >
                                    <Form.Group>
                                        <InputLabel className="text-dark" >Node name</InputLabel>
                                        <Form.Control value={addingNode.nodeName} onChange={(e) => setAddingNode({ ...addingNode, nodeName: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group>
                                        <InputLabel className="text-dark" >Node RPCPort</InputLabel>
                                        <Form.Control value={addingNode.nodeRPCPort} type="number" onChange={(e) => setAddingNode({ ...addingNode, nodeRPCPort: Number(e.target.value), nodeNetworkListeningPort: Number(e.target.value) + 1, nodeConstellationPort: Number(e.target.value) + 2, nodeRaftPort: Number(e.target.value) + 3, nodeNodeManagerPort: Number(e.target.value) + 4, nodeWS: Number(e.target.value) + 5 })} />
                                    </Form.Group>
                                    <Form.Group>
                                        <InputLabel className="text-dark" >Node network listening port</InputLabel>
                                        <Form.Control value={addingNode.nodeNetworkListeningPort} type="number" onChange={(e) => setAddingNode({ ...addingNode, nodeNetworkListeningPort: Number(e.target.value) })} />
                                    </Form.Group>
                                    <Form.Group>
                                        <InputLabel className="text-dark" >Node constellation port</InputLabel>
                                        <Form.Control value={addingNode.nodeConstellationPort} type="number" onChange={(e) => setAddingNode({ ...addingNode, nodeConstellationPort: Number(e.target.value) })} />
                                    </Form.Group>
                                    <Form.Group>
                                        <InputLabel className="text-dark" >Node raft port</InputLabel>
                                        <Form.Control value={addingNode.nodeRaftPort} type="number" onChange={(e) => setAddingNode({ ...addingNode, nodeRaftPort: Number(e.target.value) })} />
                                    </Form.Group>
                                    <Form.Group>
                                        <InputLabel className="text-dark" >Node manager port</InputLabel>
                                        <Form.Control value={addingNode.nodeNodeManagerPort} type="number" onChange={(e) => setAddingNode({ ...addingNode, nodeNodeManagerPort: Number(e.target.value) })} />
                                    </Form.Group>
                                    <Form.Group>
                                        <InputLabel className="text-dark" >Node WS</InputLabel>
                                        <Form.Control value={addingNode.nodeWS} onChange={(e) => setAddingNode({ ...addingNode, nodeWS: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group className="mt-5">
                                        <InputLabel className="text-dark" >VPS Region</InputLabel>
                                        <InputLabel className="text-dark" >(click on the image to select)</InputLabel>
                                        {
                                            typeof region !== "undefined" && region.length > 0 &&
                                            <InputLabel className="text-success" >VPS Region selected : {region}</InputLabel>
                                        }
                                        <select onChange={(e) => chooseRegion(e)} >
                                            <option value={undefined}>Choose the region</option>
                                            {
                                                regions !== [] && typeof regions.Regions !== "undefined" && regions.Regions.map((region, id) =>
                                                    <option key={id}>{region.RegionName}</option>
                                                )
                                            }
                                        </select>

                                    </Form.Group>
                                    <Form.Group className="mt-5">
                                        <InputLabel className="text-dark" >VPS Type</InputLabel>
                                        <InputLabel className="text-dark" >(click to select)</InputLabel>
                                        {
                                            typeof instance !== "undefined" && instance.length > 0 &&
                                            <InputLabel className="text-success" >VPS Type selected : {instance}</InputLabel>
                                        }

                                        <div className="container mt-3" style={{ "maxHeight": "400px", "overflow": "scroll" }} >
                                            <div className="row justify-content-center text-center">
                                                {
                                                    instances !== [] && instances.map((instanceObj, id) =>
                                                        <a href="#?" className="mt-3" key={id} onClick={(e) => chooseInstance(instanceObj)} >
                                                            <div className="card" style={{ "width": "18rem" }} >
                                                                <div className="card-header">
                                                                    {instanceObj.InstanceType}
                                                                </div>
                                                                <ul className="list-group list-group-flush">
                                                                    <li className="list-group-item">VCPU : {instanceObj.VCpuInfo.DefaultVCpus}</li>
                                                                    {
                                                                        typeof instanceObj.InstanceStorageInfo !== "undefined" &&
                                                                        <li className="list-group-item">Total memory : {instanceObj.InstanceStorageInfo.TotalSizeInGB}</li>
                                                                    }
                                                                    <li className="list-group-item">Network performance : {instanceObj.NetworkInfo.NetworkPerformance}</li>
                                                                </ul>
                                                            </div>
                                                        </a>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Form.Group>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" className="float-left" onClick={hideAddingNode}>Cancel</Button>
                                    <Button variant="success" className="float-left" onClick={deploy}>Add</Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    }


                    <Modal show={isRemoving} onHide={hideSureToRemove}>
                        <Modal.Header closeButton>
                            <Modal.Title>Node ID #{node._id}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="overflow-wrap-anywhere" >
                            Are you sure you want to remove this node ?
                                </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="float-left" onClick={hideSureToRemove}>Cancel</Button>
                            <Button variant="danger" className="float-left" onClick={removeNode}>Remove</Button>
                        </Modal.Footer>
                    </Modal>

                </>
            }
        </div>
    );
}