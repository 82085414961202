import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { sendSocketEvent } from '../Config/Socket.js';
import RenderSmartContractMethodInputs from './renderSmartContractMethodInputs.js';
import MethodHeader from './MethodHeader.js';
import Output from './Output.js';

export default function Method(props) {

    const user = props.user;
    const [method, setMethod] = useState(props.method);
    const smartContractId = props.smartContractId;
    const bundleId = props.bundleId;
    const projectId = props.projectId;
    const blockchainId = props.blockchainId;
    const [contentVisible, setContentVisible] = useState(false);

    const generatePastTransactions = () => {
        let toReturn = [];
        if ( user )
            if ( user.notifications )
                for ( let i = user.notifications.length - 1 , l = 0 ; i >= l ; --i )
                    if ( user.notifications[i] )
                        if ( user.notifications[i].smartContractId === smartContractId && user.notifications[i].method.name === method.name && user.notifications[i].blockchainId === blockchainId && user.notifications[i].projectId === projectId )
                            toReturn.push(user.notifications[i]);
        return toReturn;
    }

    const sendSignedTransaction = () => {
        try {
            let reqUrl = "/v1/transactions/";
            let objToSend = { 
                projectId: projectId,
                bundleId: bundleId,
                smartContractId: smartContractId,
                scMethod: method
            };

            if ( ( method.stateMutability === "view" || method.stateMutability === "pure" ) && !method.payable ){
                reqUrl += "call";
                objToSend.method = reqUrl;
            }else{
                reqUrl += "send";
                objToSend.method = reqUrl;
            }
            sendSocketEvent(objToSend);

        } catch (err) {
            console.error(err);
        }
    }

    const setMethodParameter = (key, value) => {
        try {

            setMethod({ ...method , inputs : method.inputs.map((input) => {
                    if ( input.name === key ){
                        console.log(typeof value)
                        console.log("Input found : " + key + " , we set value >> " + value);
                        input.value = value;
                    }
                    return input;
                }) 
            });

        } catch (err) {
            console.error(err);
        }
    }


    return (
        <>
            {
                method.type === "constructor" ? (
                    <div className="card mb-4 pl-3 w-100 smart-contract-constructor" >
                        <MethodHeader 
                            method={method} 
                            constructor={true}
                            smartContractId={smartContractId}
                        />
                    </div>
                ) : (
                    <div className="card mb-4 pl-3 w-100" >
                        <MethodHeader 
                            user={user}
                            method={method} 
                            smartContractId={smartContractId}
                            setContentVisible={setContentVisible}
                            contentVisible={contentVisible}
                            bundleId={bundleId}
                            projectId={projectId}
                            notifications={generatePastTransactions().length}
                        />

                        <RenderSmartContractMethodInputs 
                            method={method} 
                            smartContractId={smartContractId} 
                            setMethodParameter={setMethodParameter}
                        />

                        <Button 
                            className="btn-ok-submit mt-3 mb-3" 
                            onClick={sendSignedTransaction}
                        >
                            Call it
                        </Button>

                        {
                            contentVisible && 
                            <>
                                <Output 
                                    smartContractId={smartContractId} 
                                    method={method} 
                                    user={user} 
                                    projectId={projectId}
                                    blockchainId={blockchainId}
                                    notifications={generatePastTransactions()}
                                />
                            </>
                        }
                    </div>
                )
            }                                
        </>
    );
}