import React from 'react';

export default function Step2(props) {

    return (
        <>
            <div className="container mt-5 mb-5">
                <div className="text-center project-title-label">Which Blockchain network’s protocol do you want to run ?</div>
                <div className="text-center project-mini-label">Not sure ? Click here to compare and make the right choice.</div>

                <div className="mt-5 mb-5 justify-content-center  row" >

                    <div className={"card-protocol ml-2 mr-2 clickableCard " + ( props.protocol === "ethereum" ? "clickable " : "")} onClick={() => props.setProtocol("ethereum") } >
                        <img className="card-img-top img-protocol mx-auto" src="/logo-ethereum.png" alt="Ethereum" />

                        <div className="card-body">
                            <p className="project-badge-learn">Learn more</p>
                        </div>
                    </div>

                    <div className="card-protocol ml-2 mr-2"  >
                        <img className="card-img-top img-protocol mx-auto state-disabled" src="/logo-stellar.png" alt="Stellar" />

                        <div className="card-body">
                            <p className="project-badge-comingsoon">Coming soon</p>
                        </div>
                    </div>

                    <div className="card-protocol ml-2 mr-2"  >
                        <img className="card-img-top img-protocol mx-auto state-disabled" src="/logo-corda.png" alt="Corda" />
                        <div className="card-body">
                            <p className="project-badge-comingsoon">Coming soon</p>
                        </div>

                    </div>
                </div>
            </div>

            <div className="container mt-3 mb-5">

                <div className="text-center project-title-label">Which Ethereum client do you want to run ?</div>
                <div className="text-center project-mini-label">Not sure ? Click here to compare and make the right choice.</div>

                <div className="mt-5 mb-5 justify-content-center  row" >
                    <div className={"card-protocol ml-2 mr-2 clickableCard " + ( props.client === "quorum" ? "clickable " : "")} onClick={() => props.setClient("quorum") } >
                        <img className="card-img-top mx-auto  img-protocol" src="/logo-proto-quorum.png" alt="Quorum" />
                        <div className="card-body">
                            <p className="project-badge-learn">Learn more</p>
                        </div>
                    </div>

                    <div className={"card-protocol ml-2 mr-2 clickableCard " + ( props.client === "enterprise ethereum alliance" ? "clickable " : "")} onClick={() => props.setClient("enterprise ethereum alliance") } >
                        <img className="card-img-top  mx-auto  img-protocol" src="/logo-proto-ethereum.png" alt="Ethereum" />
                        <div className="card-body">
                        <p className="project-badge-learn">Learn more</p>
                        </div>
                     </div>

                    <div className="card-protocol ml-2 mr-2" >
                        <img className="card-img-top mx-auto  img-protocol state-disabled" src="/logo-proto-baseline.png" alt="Baseline" />

                        <div className="card-body">
                            <p className="project-badge-comingsoon">Coming soon</p>
                        </div>
                     </div>
                </div>
            </div>
        </>
    );

}