import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Parameters from './Config/Parameters.js';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Badge from '@material-ui/core/Badge';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import makeBlockie from 'ethereum-blockies-base64';
import ForwardIcon from '@material-ui/icons/Forward';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
const Request = require('./Config/Request.js');


const useStyles = makeStyles((theme) => ({
   typography: {
     padding: theme.spacing(2),
   },
   root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
   },
   nested: {
      paddingLeft: theme.spacing(4),
   }
}));

const CenteredListItemText = withStyles({
   root: {
     textAlign: "center"
   }
})(ListItemText);

function Navigation(props) {

   const classes = useStyles();
   const [anchorEl, setAnchorEl] = useState(null);
   const [anchorNotifications, setAnchorNotifications] = useState(null);
   const open = Boolean(anchorEl);
   const id = open ? 'simple-popover' : undefined;
   const [openNotifications, setOpenNotifications] = useState(null);
   const [openPendingTransactions, setOpenPendingTransactions] = useState(false);
   const [openFinishedTransactions, setOpenFinishedTransactions] = useState(false);
   const [openCalls, setOpenCalls] = useState(false);
   const openAnchor = Boolean(anchorNotifications);
   const idNotifications = openAnchor ? 'simple-popover' : undefined;

   if (window.location.href === Parameters.CONSOLE_URL){
      sessionStorage.clear();
      localStorage.clear();
   }

   const logOut = () => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
   }

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClickNotifications = (event) => {
      setAnchorNotifications(event.currentTarget);
      setOpenNotifications(!openNotifications);
   };
  
   const handleClose = () => {
      setAnchorEl(null);
      setOpenNotifications(false);
   };

   const getPendingTransactions = () => {
      return 0;
   }

   const getFinishedTransactions = () => {
      return 0;
   }

   const generateFunctionInputsText = (notification) => {
      let parameters = "(";
      for ( let i = 0 , l = notification.method.inputs.length ; i < l ; ++i ){
         parameters += notification.method.inputs[i].internalType;
         if ( i < l-1 )
            parameters += ", ";
      }
      parameters += ")";
      return parameters;
   }

   const getCalls = () => {
      if ( props.user )
         if ( props.user.notifications ){
            let counter = 0;
            for ( let i = 0 , l = props.user.notifications.length ; i < l ; ++i )
               if ( props.user.notifications[i].method.payable === false && ( props.user.notifications[i].method.stateMutability === "view" || props.user.notifications[i].method.stateMutability === "pure" ) )
                  ++counter;
            return counter;
         }
      return 0;
   }

   const checkIfHasNotifications = () => {
      if ( props.user.notifications )
         for ( let i = 0 , l = props.user.notifications.length ; i < l ; ++i )
            if ( props.user.notifications[i].blockchainAccount || props.user.notifications[i].userId || props.user.notifications[i].method || props.user.notifications[i].error || props.user.notifications[i].smartContractId || props.user.notifications[i].receipt )
               return true;
      return false;
   }

   const deleteNotification = async (index) => {
      if ( props.user ) {
         let deleteTheNotification = await Request.httpRequest("PUT", "/removeNotification", {
            email : props.user.email,
            h64AccountId : props.user.h64AccountId,
            notification : props.user.notifications[index],
            notificationIndex : index
         });
         if ( deleteTheNotification ){
            props.setError(deleteTheNotification.errorMessage);
            if ( deleteTheNotification.errorCode === null && deleteTheNotification.errorMessage === null )
               props.setUser({ ...props.user , notifications : props.user.notifications.filter((_notif,_id) => _id !== index ) });
         }
      }
   }

   const deleteAllNotifications = async () => {
      if ( props.user ) {
         let deleteTheNotification = await Request.httpRequest("DELETE", "/removeNotifications", {
            email : props.user.email,
            h64AccountId : props.user.h64AccountId
         });
         if ( deleteTheNotification ){
            props.setError(deleteTheNotification.errorMessage);
            if ( deleteTheNotification.errorCode === null && deleteTheNotification.errorMessage === null )
               props.setUser({ ...props.user , notifications : [] });
         }
      }
   }

   const generateNotificationsList = () => {
      return <>
         {
            props.user.notifications && props.user.notifications.map((notification,id) => 
            <ListItem key={id}>
               
               {
                  ( notification.blockchainAccount || notification.userId ) && 
                  <ListItemIcon>
                     <IconButton aria-label="notifications" >
                        <Avatar alt="Remy Sharp" src={makeBlockie(notification.blockchainAccount ? notification.blockchainAccount : notification.userId)} />
                     </IconButton>
                  </ListItemIcon>
               }

               {
                  notification.method ? (
                        <CenteredListItemText
                           primary={
                              <ForwardIcon className={"justify-content-center text-center " + ( notification.error ? "text-danger" : "text-success" )} />
                           } 
                           secondary={
                              <>
                                 {
                                    notification.method && 
                                    <p>
                                       {notification.method.name}{generateFunctionInputsText(notification)}
                                       {
                                          notification.response && 
                                          <>
                                             : 
                                             {
                                                typeof notification.response !== "object" && 
                                                <>
                                                   {
                                                      typeof notification.response === "boolean" ? ( notification.response ? "true" : "false" ) : notification.response
                                                   }
                                                </>
                                             }
                                          </>
                                       }
                                    </p>
                                 }
                                 {
                                    notification.transactionHash && 
                                    <a href="#" title={notification.transactionHash} >
                                       {notification.transactionHash.substr(0,5) + "..." + notification.transactionHash.substr(notification.transactionHash.length-6,notification.transactionHash.length-1)}
                                    </a>
                                 }
                                 {
                                    notification.error &&
                                       <span title={notification.error.toString()}>>
                                          Error {notification.error.toString().substr(0,40)}
                                       </span>
                                 }
                              </>
                           }
                        />
                  ) : (
                     <CenteredListItemText
                        primary={
                           <ForwardIcon className={"justify-content-center text-center " + ( notification.error ? "text-danger" : "text-success" )} />
                        } 
                        secondary={
                           <>
                              {
                                 notification.method && 
                                 <p>{notification.method.name}{generateFunctionInputsText(notification)}</p>
                              }
                              {
                                 notification.transactionHash && 
                                 <a href="#" title={notification.transactionHash} >
                                    {notification.transactionHash.substr(0,5) + "..." + notification.transactionHash.substr(notification.transactionHash.length-6,notification.transactionHash.length-1)}
                                 </a>
                              }
                              {
                                 notification.error &&
                                    <span title={notification.error.toString()}>>
                                       Error {notification.error.toString().substr(0,40)}
                                    </span>
                              }
                              {
                                 notification.receipt && 
                                 <a href="#" className="font-weight-bold">
                                    Contract mined : {notification.receipt.contractAddress.substr(0,5) + "..." + notification.receipt.contractAddress.substr(notification.receipt.contractAddress.length-6,notification.receipt.contractAddress.length-1)}
                                 </a>
                              }
                           </>
                        }
                     />
                  )
               }

               {
                  notification.smartContractId ? (
                     <ListItemIcon>
                        <IconButton aria-label="notifications" >
                           <Avatar alt="Notification" src={makeBlockie(notification.smartContractId ? notification.smartContractId : notification.bundleId)} />
                        </IconButton>
                     </ListItemIcon>
                  ) : (
                     <>
                        {
                           notification.receipt ? ( 
                              <ListItemIcon>
                                 <IconButton aria-label="notifications" >
                                    <Avatar alt="Notification" src={makeBlockie(notification.receipt.contractAddress)} />
                                 </IconButton>
                              </ListItemIcon>
                           ) : (
                              <ListItemIcon>
                                 <IconButton aria-label="notifications" size="large" >
                                    <SettingsInputAntennaIcon/>
                                 </IconButton>
                              </ListItemIcon>
                           )
                        }
                     </>
                  )
               }

               <ListItemIcon>
                  <IconButton aria-label="notifications" onClick={() => deleteNotification(id)} >
                     <DeleteIcon alt="Delete notification" color="primary" />
                  </IconButton>
               </ListItemIcon>

            </ListItem>
            )
         }
      </>;
   }

   try {
      return (
         <Navbar sticky="top" expand="lg">
            <Navbar.Brand href="/home">
               <img
                  alt=""
                  src="/h64.png"
                  height="60"
                  className="d-inline-block align-top"
               />{' '}
               
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
               <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                     <Nav.Link as={Link} to="/home" title="Dashboard" className="nav-top-title mr-4" >
                        <i className="fa fa-home color-ico-1 mr-1"></i> Dashboard
                     </Nav.Link>
                  </Nav>

                  {
                     props.user && 
                     <>
                        {
                           checkIfHasNotifications() ? (
                              <>
                                 <Badge badgeContent={props.user.notifications.length} color="primary">
                                    <IconButton aria-label="notifications" onClick={handleClickNotifications} >
                                       <NotificationsActiveIcon fontSize="large" color="primary" />
                                    </IconButton>
                                 </Badge>

                                 <Popover
                                    id={idNotifications}
                                    open={openNotifications}
                                    anchorEl={anchorNotifications}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                       vertical: 'top',
                                       horizontal: 'center',
                                    }}
                                 >
                                    <List
                                       component="nav"
                                       aria-labelledby="nested-list-subheader"
                                       subheader={
                                       <ListSubheader component="div" id="nested-list-subheader">
                                          Notifications ( {props.user.notifications.length} new )
                                          <Link to="# ?" onClick={deleteAllNotifications} className="ml-2 font-weight-bold" >Delete all</Link>
                                       </ListSubheader>
                                       }
                                    >
                                       {
                                          props.user && 
                                          <>
                                             {generateNotificationsList()}
                                          </>
                                       }
                                    </List>
                                 </Popover>
                              </>
                           ) : (
                              <>
                                 <IconButton aria-label="notifications" onClick={handleClick} >
                                    <NotificationsIcon fontSize="large" color="primary" />
                                 </IconButton>
                                 <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                       vertical: 'top',
                                       horizontal: 'center',
                                    }}
                                 >
                                    <Typography className={classes.typography}>No notifications</Typography>
                                 </Popover>
                              </>
                           )
                        }
                     </>
                  }

                  <NavDropdown alignRight className="mr-4 user-menu" title={
                     <div className="pull-left">
                        {
                           props.user && 
                           <>
                              {
                                 props.user.name && 
                                 <>
                                    {props.user.name}
                                 </>
                              }
                           </>
                        }
                        <img className="thumbnail-image ml-1" 
                           src="/avatar-1.png"
                           alt="profile_picture"
                        />
                     </div>
                     }
                  >
                     <NavDropdown.Item >
                        <div>Account & Profile</div>
                        <div ><Link className="user-menu-link" to="/account" >Edit your profile</Link></div>   
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="# ?" onClick={logOut} >
                           <i className="fa fa-sign-out mr-2" aria-hidden="true"></i> 
                           Log out
                     </NavDropdown.Item>
                  </NavDropdown>

               </Navbar.Collapse>
         </Navbar>
      );
   } catch (err) {
      return (<></>);
   }
}

export default Navigation;