import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import CodeButton from '../Common/CodeButton.js';
import Badge from 'react-bootstrap/Badge';
const Request = require('../Config/Request.js');

export default function BundleFunctionalitiesSkeleton(props) {

    const bundle = props.bundle;
    const blockchainId = props.blockchainId;
    const projectId = props.projectId;
    const deleteBundle = props.deleteBundle;
    const templateId = props.templateId;

    const formatSubtypesTitle = (subtypes) => {
        if (typeof subtypes === "string")
            return subtypes;
        if (typeof subtypes === "object")
            if (subtypes.length <= 1)
                return subtypes[0];
            else {
                let toReturn = "";
                for (let i = 0, l = subtypes.length; i < l; ++i)
                    toReturn += subtypes[i] + (i < l - 1 ? "," : "");
                return toReturn;
            }
    }

    let bundle_methods = (row) => {
        try {
            return <Link to={"/panel/" + blockchainId + "/" + projectId + "/" + row._id} className="btn-card-sumbit  mx-auto mr-2">
                <i class="fas fa-tasks"></i>
            </Link>;
        } catch (err) {
            console.error(err);
            return <></>;
        }
    }

    let donwloadABI = async (templateId) => {
        try {
            if (typeof templateId === "undefined")
                return;
            let smartContractObject = await Request.h64HttpRequest("GET", "/getSmartContract/" + templateId);
            if (smartContractObject.errorMessage === null && smartContractObject.errorCode === null) {
                let fileName = "ABI";
                let mainSC = { abi: [], size: 0 };
                smartContractObject = smartContractObject.smartContract;
                for (let i = 0, l = smartContractObject.smartContracts.length; i < l; ++i)
                    if (JSON.stringify(smartContractObject.smartContracts[i]).length > mainSC.size) {
                        mainSC.size = JSON.stringify(smartContractObject.smartContracts[i]).length;
                        mainSC.abi = smartContractObject.smartContracts[i].abi;
                    }
                let blob = new Blob([mainSC.abi], { type: 'application/json' });
                let href = await URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.href = href;
                link.download = fileName + ".json";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (err) {
            console.error(err);
        }
    }

    let bundle_abi = (row) => {
        return <Button className="btn-mini" onClick={() => donwloadABI(row.templateId)} >
            ABI
        </Button>
    }

    let bundle_source = (row) => {
        try {
            return <>
                <CodeButton 
                    classInjector={"btn-mini"} 
                    i18={props.t} 
                    smartContracts={row.smartContracts} 
                    creationDate={new Date(row.creationDate).getTime()} 
                    bundleId={bundle._id}
                    templateId={templateId}
                />
            </>
        } catch (err) {
            console.error(err);
            return <></>;
        }
    }

    let bundle_delete = (row) => {
        try {
            return <Button className="btn-mini" onClick={() => props.deleteBundle(row)} >
                <i class="fas fa-trash"></i>
            </Button>;
        } catch (err) {
            console.error(err);
            return <></>;
        }
    }

    return (
        <div className="card-footer border-0">
            <div className="w-100 mb-1  border-top pt-1" >
                <Badge pill variant="warning" title={formatSubtypesTitle(bundle.subtype)} >
                    {
                        bundle.subtype && typeof bundle.subtype === "string" &&
                        <>{bundle.subtype}</>
                    }
                    {
                        bundle.subtype && typeof bundle.subtype === "object" &&
                        <>
                            {
                                bundle.subtype.length <= 1 ? (
                                    <>{bundle.subtype[0] || ""}</>
                                ) : (
                                        <>{bundle.subtype[0] + ", +" + (bundle.subtype.length - 1) + " more"}</>
                                    )
                            }
                        </>
                    }
                </Badge>
            </div>
            <div className="w-100 mb-3" >
                {bundle_methods(bundle)}
            </div>
            <div className="mb-1 row" >
                <div className="col-4" >{bundle_delete(bundle)}</div>
                <div className="col-4" >{bundle_abi(bundle)}</div>
                <div className="col-4" > {bundle_source(bundle)}</div>

            </div>
        </div>
    );
}