import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navigation from './components/Navigation.js';
import Sidebar from './components/Sidebar.js';
import ForgotPassword from './components/ForgotPassword.js';
import Explorer from './components/Explorer.js';
import SmartContractMenu from './components/SmartContractMenu.js';
import SmartContractInfos from './components/SmartContractInfos.js';
import SmartContracts from './components/SmartContracts.js';
import DeploySmartContract from './components/DeploySmartContract.js';
import UserSignIn from './components/UserSignIn.js';
import UserSignUp from './components/UserSignUp.js';
import Home from './components/Home.js';
import ProjectHome from './components/ProjectHome.js';
import ProjectBlockchain from './components/ProjectBlockchain.js';
import ProjectMembers from './components/ProjectMembers.js';
import OrderSmartContract from './components/OrderSmartContract.js';
import MyAccount from './components/MyAccount.js';
import Invite from './components/Invite.js';
import Panel from './components/SmartContract/Panel.js';
import SmartContractDocumentation from './components/SmartContractDocumentation.js';
import { translate } from 'react-i18next';
import Parameters from './components/Config/Parameters.js';
import ProjectWizard from './components/ProjectWizard/ProjectWizard.js';
import { socket } from './components/Config/Socket.js';
import SmartContractWizard from './components/SmartContractWizard/SmartContractWizard.js';
import UploadSmartContract from './components/UploadSmartContract.js';
import ErrorHandler from './components/Common/errorHandler.js';
const Request = require('./components/Config/Request.js');


export default function App() {

  const [blockchain,setBlockchain] = useState(null);
  const [project,setProject] = useState(null);
  const [user,setUser] = useState(JSON.parse(localStorage.getItem("user") || sessionStorage.getItem("user")));
  const [error, setError] = useState(null);

  if ( !localStorage.getItem("user") && !sessionStorage.getItem("user") )
    if ( window.location.pathname !== "/" && window.location.pathname !== "/signin" && window.location.pathname !== "/signup" && window.location.pathname !== "/forgotPassword" ){
      sessionStorage.clear();
      localStorage.clear();
      window.location.href="/";
    }
  
  const getDbNotifications = async () => {
    if ( user ) {
      let theNotifications = await Request.httpRequest("GET", "/getNotifications/" + user.h64AccountId, {});
      if ( theNotifications ){
        setError(theNotifications.errorMessage);
        if ( theNotifications.errorCode === null && theNotifications.errorMessage === null ){
          setUser({ ...user , notifications : theNotifications.notifications });
          sessionStorage.setItem('notifications', JSON.stringify(theNotifications.notifications));
        }
      }
    }
  }

  // Socket connection initialized here
  socket.onmessage = (message) => { 
    if ( user ){
      let obj = JSON.parse(message.data);

      switch ( obj.type ){
        case "txCall":
          setUser({ ...user , notifications : [ ...user.notifications || [], obj ] });
          sessionStorage.setItem('notifications', JSON.stringify([ ...user.notifications || [], obj ]));
          return;

        case "txCallError":
          setUser({ ...user , notifications : [ ...user.notifications || [], obj ] });
          sessionStorage.setItem('notifications', JSON.stringify([ ...user.notifications || [], obj ]));
          return;

        case "txCallInternalError":
          setUser({ ...user , notifications : [ ...user.notifications || [], obj ] });
          sessionStorage.setItem('notifications', JSON.stringify([ ...user.notifications || [], obj ]));
          return;

        case "txSendTransactionHash":
          for ( let i = 0 , l = user.blockchainAccounts.length ; i < l ; ++i )
            if ( user.blockchainAccounts[i].accountAddress.toLowerCase() === obj.blockchainAccount.toLowerCase() ){
              setUser({ ...user , notifications : [ ...user.notifications || [], obj ] });
              sessionStorage.setItem('notifications', JSON.stringify([ ...user.notifications || [], obj ]));
              return;
            }
          return;

        case "smartContractDeployTransactionHash":
          setUser({ ...user , notifications : [ ...user.notifications || [], obj ] });
          sessionStorage.setItem('notifications', JSON.stringify([ ...user.notifications || [], obj ]));
        return;

        case "smartContractDeployReceipt":
          setUser({ ...user , notifications : [ ...user.notifications || [], obj ] });
          sessionStorage.setItem('notifications', JSON.stringify([ ...user.notifications || [], obj ]));
        return;

        case "smartContractDeployError":
          setUser({ ...user , notifications : [ ...user.notifications || [], obj ] });
          sessionStorage.setItem('notifications', JSON.stringify([ ...user.notifications || [], obj ]));
        return;

        case "txSendError":
          setUser({ ...user , notifications : user.notifications.map((not) => {
              if ( not.transactionHash )
                if ( obj.error.toLowerCase().indexOf(not.transactionHash.toLowerCase()) !== -1 )
                  not.error = obj.error;
                return not;
            })
          });
          sessionStorage.setItem('notifications', JSON.stringify(user.notifications));
          return;

        case "txSendInternalError":
          setUser({ ...user , notifications : [ ...user.notifications || [], obj ] });
          sessionStorage.setItem('notifications', JSON.stringify([ ...user.notifications || [], obj ]));
          return;

        case "txSendReceipt":
          setUser({ ...user , notifications : user.notifications.map((not) => {
              if ( not.transactionHash )
                if ( not.transactionHash.toLowerCase() === obj.receipt.transactionHash )
                  not.receipt = obj.receipt;
                return not;
            })
          });
          sessionStorage.setItem('notifications', JSON.stringify(user.notifications));
          return;
      }
    }

  }


  /*
   * @DIIMIIM
   * Listening on localstorage & sessionStorage modifications
   */
  useEffect(() => {
    try {

      // We listen here
      let newUser = JSON.parse(localStorage.getItem("user") || sessionStorage.getItem("user"));
      setUser(newUser);

    } catch (err) {
        console.error(err);
    }
  }, [localStorage.getItem("user") || sessionStorage.getItem("user")]);

  useEffect(() => {
    try {
      getDbNotifications();
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <BrowserRouter>

        {
          user ? (
            <>

              {/* Main website navbar */}
              <Navigation 
                project={project} 
                blockchain={blockchain} 
                user={user} 
                setError={
                  (e) => setError(e)
                }
                setUser={
                  (e) => setUser(e)
                }
              />

              {/* Project side bar */}
              <Sidebar 
                project={project} 
                blockchain={blockchain} 
                setError={
                  (e) => setError(e)
                }
              />

              {/* Webpage content */}
              <div className="page-content">

                <Switch>

                <Route 
                  path="/" 
                  exact 
                  render={
                    () => <Home 
                            setBlockchain={
                              (e) => setBlockchain(e)
                            } 
                            setProject={
                              (e) => setProject(e)
                            } 
                            setError={
                              (e) => setError(e)
                            }
                            user={user}
                          />
                  }
                />

                  <Route 
                    path="/home" 
                    exact 
                    render={
                      () => <Home 
                              setBlockchain={
                                (e) => setBlockchain(e)
                              } 
                              setProject={
                                (e) => setProject(e)
                              } 
                              setError={
                                (e) => setError(e)
                              }
                              user={user}
                            />
                    }
                  />

                  <Route 
                    path="/project/:blockchainId/:projectId/infos" 
                    exact 
                    render={
                      (props) => <ProjectBlockchain 
                                  props={props} 
                                  setBlockchain={
                                    (e) => setBlockchain(e)
                                  } 
                                  setProject={
                                    (e) => setProject(e)
                                  } 
                                  setError={
                                    (e) => setError(e)
                                  }
                                  user={user}
                                />
                    } 
                  />
                  
                  <Route 
                    path="/project/:blockchainId/:projectId" 
                    exact 
                    render={
                      (props) => <ProjectHome 
                                  props={props} 
                                  setBlockchain={
                                    (e) => setBlockchain(e)
                                  } 
                                  setProject={
                                    (e) => setProject(e)
                                  } 
                                  setError={
                                    (e) => setError(e)
                                  }
                                />
                    }
                  />

                  <Route 
                    path="/myBlockchains" 
                    exact 
                    render={
                      () => <Home 
                              setError={
                                (e) => setError(e)
                              }
                            />
                    } 
                  />

                  <Route 
                    path="/members/:blockchainId/:projectId" 
                    exact 
                    render={
                      (props) => <ProjectMembers 
                                  props={props} 
                                  setBlockchain={
                                    (e) => setBlockchain(e)
                                  } 
                                  setProject={
                                    (e) => setProject(e)
                                  } 
                                  setError={
                                    (e) => setError(e)
                                  }
                                  user={user}
                                />
                    } 
                  />

                  <Route 
                    path="/explorer/:blockchainId/:projectId" 
                    exact 
                    render={
                      (props) => <Explorer 
                                  props={props} 
                                  setBlockchain={
                                    (e) => setBlockchain(e)
                                  } 
                                  setProject={
                                    (e) => setProject(e)
                                  } 
                                  setError={
                                    (e) => setError(e)
                                  }
                                />
                    } 
                  />

                  <Route 
                    path="/smartContracts/:blockchainId/:projectId" 
                    exact 
                    render={
                      (props) => <SmartContractInfos 
                                    props={props} 
                                    setBlockchain={
                                      (e) => setBlockchain(e)
                                    } 
                                    setProject={
                                      (e) => setProject(e)
                                    } 
                                    setError={
                                      (e) => setError(e)
                                    }
                                    user={user}
                                  />
                    } 
                  />

                  <Route 
                    path="/smartContractWizard/:blockchainId/:projectId/:smartContractId" 
                    exact
                    render={
                      (props) => <SmartContractWizard 
                                  props={props} 
                                  user={user}
                                  setError={
                                    (e) => setError(e)
                                  }
                                />
                    }
                  />

                  <Route
                    path="/uploadSmartContract/:blockchainId/:projectId"
                    exact
                    render={
                      (props) => <UploadSmartContract
                                  props={props} 
                                  user={user}
                                  setError={
                                    (e) => setError(e)
                                  }
                                />
                    }
                  />

                  <Route 
                    path="/smartContractsWizard/:blockchainId/:projectId" 
                    exact 
                    render={
                      (props) => <SmartContracts 
                                  props={props} 
                                  setBlockchain={
                                    (e) => setBlockchain(e)
                                  } 
                                  setProject={
                                    (e) => setProject(e)
                                  } 
                                  setError={
                                    (e) => setError(e)
                                  }
                                />
                    } 
                  />
                  
                  <Route 
                    path="/projectWizard" 
                    exact 
                    render={
                      () => <ProjectWizard 
                              setError={
                                (e) => setError(e)
                              }
                              user={user}
                              setUser={
                                (e) => setUser(e)
                              }
                            />
                    } 
                  />
                  
                  <Route 
                    path="/account" 
                    exact 
                    render={
                      () => <MyAccount 
                              setBlockchain={
                                (e) => setBlockchain(e)
                              } 
                              setProject={
                                (e) => setProject(e)
                              } 
                              setUser={
                                e => setUser(e)
                              }
                              setError={
                                (e) => setError(e)
                              }
                            />
                    } 
                  />
                  
                  <Route 
                    path="/panel/:blockchainId/:projectId/:smartContractId" 
                    exact 
                    render={
                      (props) => <Panel 
                                  props={props} 
                                  user={user} 
                                  blockchain={blockchain}
                                  project={project}
                                  setBlockchain={
                                    (e) => setBlockchain(e)
                                  } 
                                  setProject={
                                    (e) => setProject(e)
                                  } 
                                  setError={
                                    (e) => setError(e)
                                  }
                                />
                    }
                  />

                  <Route 
                    path="/smartContract/:blockchainId/:projectId/:smartContractId/documentation" 
                    exact 
                    render={
                      (props) => <SmartContractDocumentation 
                                  props={props} 
                                  setBlockchain={
                                    (e) => setBlockchain(e)
                                  } 
                                  setProject={
                                    (e) => setProject(e)
                                  } 
                                  setError={
                                    (e) => setError(e)
                                  }
                                />
                    } 
                  />

                  <Route 
                    path="/smartContractMenu/:blockchainId/:projectId" 
                    exact 
                    render={
                      (props) => <SmartContractMenu 
                                  props={props} 
                                  setError={
                                    (e) => setError(e)
                                  }
                                /> 
                    } 
                  />

                  <Route 
                    path="/deploySmartContract/:blockchainId/:projectId" 
                    exact 
                    render={
                      (props) => <DeploySmartContract 
                                  props={props} 
                                  setError={
                                    (e) => setError(e)
                                  }
                                  user={user}
                                /> 
                    } 
                  />

                  <Route 
                    path="/orderSmartContract/:projectId" 
                    exact 
                    render={
                      (props) => <OrderSmartContract 
                                  props={props} 
                                  setError={
                                    (e) => setError(e)
                                  }
                                /> 
                    } 
                  />

                </Switch>

              </div>

            </>
          ) : (
            <>

              <Route 
                path="/" 
                exact 
                render={
                  (props) => <UserSignIn 
                              props={props} 
                              setError={
                                (e) => setError(e)
                              }
                            /> 
                } 
              />

              <Route 
                path="/signup" 
                exact 
                render={
                  (props) => <UserSignUp 
                              props={props} 
                              setError={
                                (e) => setError(e)
                              }
                            /> 
                } 
              />

              <Route 
                path="/signin" 
                exact 
                render={
                  (props) => <UserSignIn 
                              props={props} 
                              setError={
                                (e) => setError(e)
                              }
                            /> 
                } 
              />

              <Route 
                path="/forgotPassword" 
                exact 
                render={
                  () => <ForgotPassword 
                          setError={
                            (e) => setError(e)
                          }
                        />
                } 
              />

            </>
          )
        }

        <ErrorHandler error={error} />

    </BrowserRouter>
  );

}