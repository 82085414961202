import React, { useState } from 'react';
import { Link } from "react-router-dom";
import SuccessButton from './Common/SuccessButton.js';
const Request = require('./Config/Request.js');


function SignIn(props) {

    sessionStorage.clear();
    localStorage.clear();
    let [errorCode, setErrorCode] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [fname, setFName] = useState("");
    let [lname, setLName] = useState("");
    let [rpassword, setRPassword] = useState("");

    let handleSubmit = async () => {
        try{

            if (password.length === 0 || email.length === 0) {
                setErrorCode(404);
                setErrorMessage("Merci de compléter tous les champs.");
                return;
            }

            if (rpassword !== password) {
                setErrorCode(404);
                setErrorMessage("Passwords do not match");
                return;
            }

            let register = await Request.httpRequest("POST", "/register", { 
                email: email , 
                password: password , 
                name: fname + " " + lname ,
            });

            if ( register )
                if ( register.errorCode !== undefined && register.errorMessage !== undefined ) {
                    setErrorCode(register.errorCode);
                    setErrorMessage(register.errorMessage);
                    let user;
                    if (register.errorCode === null && register.errorMessage === null) {
                        user = {
                            key : register.key,
                            name : fname + " " + lname,
                            email : email,
                            blockchainAccounts : register.blockchainAccounts,
                            h64AccountId : register.userId,
                            notifications : register.notifications || []
                        };
                        sessionStorage.setItem('user', JSON.stringify(user));
                        sessionStorage.setItem('notifications', JSON.stringify([]));
                        window.location.href = "/home";
                    }
                    Request.httpRequestLog(typeof user !== "undefined" ? user._id : undefined, "SignIn", register.errorCode === null && register.errorMessage === null ? "Success" : "Error", email, password);
                }

        }catch(err){
            console.error(err);
        }

    }

    return (
        <>

            <div id="bah-corner" ></div>
            <div className="rect-bg-1" ></div>
            <div className="dot-plain-1"></div>

            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-6 col-sm-12 pl-5 pr-5">
                        <div className="title-big">
                            <span className="title-bold">Blockchain for growth</span>
                            <span className="ml-2">plateforme collaborative</span></div>

                        <div className="mt-5"></div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                        <form className="form-sign-in" >
                            <div className="login-title mt-1 mb-4 ">Create an account</div>
                            <div className="row justify-content-center">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label" >First Name</label>
                                        <div className="input-group">
                                            <input type="email" className="form-control" value={fname} onChange={(e) => setFName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label" >Last name</label>
                                        <div className="input-group">
                                            <input type="email" className="form-control" value={lname} onChange={(e) => setLName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label" >E-mail</label>
                                <div className="input-group">
                                    <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label" >Mot de passe</label>
                                <div className="input-group">
                                    <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label" >Repeat password</label>
                                <div className="input-group">
                                    <input type="password" className="form-control" value={rpassword} onChange={(e) => setRPassword(e.target.value)} />
                                </div>
                            </div>

                            <div className="row justify-content-center text-center">
                                <SuccessButton onClick={handleSubmit} style="mt-1 mb-5 w-100" label="Sign up"/>
                            </div>

                            <div className="form-group">
                                By signing up, you agree to the Terms of Service and Privacy Policy.
                            </div>

                            <div className="form-group">
                                <p className="text-center" ><Link to="/signin" className="card-link" >Already have an account? Sign in</Link></p>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            <div className={"container mt-5 mb-5" + (errorCode === null && errorMessage === null ? " d-none" : "")}>
                <div className="alert alert-danger" role="alert">{errorMessage}</div>
            </div>
        </>
    );

}

export default SignIn;
