import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ProjectWatcher from './ProjectWatcher.js';
import Step1 from './Step1.js';
import Step2 from './Step2.js';
import Step3 from './Step3.js';
import Step4 from './Step4.js';
const Request = require('../Config/Request.js');


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['General Project Configuration', 'Core technology configuration', 'Architectural configuration', 'Project review'];
}

export default function ProjectWizard(props) {

    const classes = useStyles();
    const [user, setUser] = useState(props.user);
    let [activeStep, setActiveStep] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [requestedToDeploy, setRequestedToDeploy] = useState(false);
    const [responseReceived, setResponseReceived] = useState(false);
    const steps = getSteps();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [project, setProject] = useState({
      name : "",
      protocol : null,
      client : null,
      keywords : [],
      blockchainName : "",
      blockchainId : null,
      terms : false
    });
  
    const totalSteps = () => {
      return getSteps().length;
    };

    let setProtocol = (_protocol) => {
      if ( project.protocol ){
        let oldProtocol = project.protocol;
        setProject({ ...project , protocol : _protocol , keywords : [ 
            ...project.keywords.filter(_keyword => _keyword !== oldProtocol),
            _protocol 
          ] 
        });
      }else{
        setProject({ ...project , protocol : _protocol , keywords : [ ...project.keywords , _protocol ] });
      }
    }

    let setClient = (_client) => {
      if ( project.client ){
        let oldClient = project.client;
        setProject({ ...project , client : _client , keywords : [ 
            ...project.keywords.filter(_keyword => _keyword !== oldClient),
            _client 
          ] 
        });
      }else{
        setProject({ ...project , client : _client , keywords : [ ...project.keywords , _client ] });
      }
    }
  
    const isStepOptional = (step) => {
      return step === 1;
    };

    let getStepContent = () => {
      switch (activeStep) {
        case 0:
          return <Step1 
              name={project.name} 
              setName={(e) => setProject({ ...project , name : e.target.value })} 
            />;
        case 1:
          return <Step2 
            protocol={project.protocol} 
            client={project.client} 
            setProtocol={setProtocol} 
            setClient={setClient} 
          />;
        case 2:
          return <Step3
            protocol={project.protocol}
            client={project.client}
            keywords={project.keywords}
            blockchainName={project.blockchainName}
            user={user}
            blockchainId={project.blockchainId}
            setBlockchain={(blockchain) => setProject({ ...project , blockchainId : blockchain._id , blockchainName : blockchain.name })}
          />;
        case 3:
          return <Step4
            name={project.name}
            blockchainName={project.blockchainName}
            protocol={project.protocol}
            setTerms={() => setProject({ ...project , terms : !project.terms })}
          />;
      }
    }
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const handleNext = async () => {
      switch (activeStep) {

        case 0:
          if ( project.name.length < 3 ){
            setErrorMessage("Project name must have atleast 3 characters length");
            return;
          }
        break;

        case 1:
          if ( !project.protocol ){
            setErrorMessage("Project protocol not selected");
            return;
          }
          if ( !project.client ){
            setErrorMessage("Project client not selected");
            return;
          }
        break;

        case 2:
          if ( !project.blockchainId ){
            setErrorMessage("Project host not selected");
            return;
          }
        break;

        case 3:
          if ( !project.terms ){
            setErrorMessage("Blockadhoc terms not accepted");
            return;
          }
        break;
      }
      if ( isLastStep() ){
        if ( project.name.length < 3 ){
          setErrorMessage("Project name must have atleast 3 characters length");
          return;
        }
        if ( !project.protocol ){
          setErrorMessage("Project protocol not selected");
          return;
        }
        if ( !project.client ){
          setErrorMessage("Project client not selected");
          return;
        }
        if ( !project.blockchainId ){
          setErrorMessage("Project host not selected");
          return;
        }
        if ( !project.terms ){
          setErrorMessage("Blockadhoc terms not accepted");
          return;
        }

        setRequestedToDeploy(true);
        let createProject = await Request.h64HttpRequest("POST", "/createHostedProject", project);

        if ( createProject ){
          setResponseReceived(true);
          if ( createProject.errorCode !== undefined && createProject.errorMessage !== undefined ){

            setErrorMessage(createProject.errorMessage);

            if ( createProject.errorMessage === null && createProject.errorCode === null ){
                let newUser = user;
                if ( newUser ) {
                  let userBlockchainAccounts = props.user.blockchainAccounts;
                  let accountExists = false;
                  for ( let i = 0 , l = userBlockchainAccounts.length ; i < l ; ++i )
                    if ( userBlockchainAccounts[i] )
                      if ( userBlockchainAccounts[i].accountAddress ){
                        if ( userBlockchainAccounts[i].accountAddress.toLowerCase() === createProject.blockchainAccount.accountAddress.toLowerCase() ){
                          accountExists = true;
                          break;
                        }
                      }
                  if ( !accountExists ){
                    userBlockchainAccounts.push(createProject.blockchainAccount);
                    props.setUser({ ...props.user , blockchainAccounts : userBlockchainAccounts });
                  }
                }
                  newUser.blockchainAccounts.push(createProject.blockchainAccount);
                  sessionStorage.setItem("user",JSON.stringify(newUser));
                  setUser(newUser);
                }
            }

        }
      } else{
        setActiveStep(++activeStep);
      }
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };

    return (
      <>
        <div className="page-header  m-0 p-0 w-100 d-flex">
            <div className="w-100 page-header-title">Project set up</div>
        </div>

        {
          requestedToDeploy ? (
            <ProjectWatcher errorMessage={errorMessage} responseReceived={responseReceived} />
          ) : (
            <>
              <div className={classes.root}>
                <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};

                    /*
                    if (isStepOptional(index)) {
                        buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                    }
                    */

                    return (
                        <Step key={label} {...stepProps}>
                        <StepButton
                            onClick={handleStep(index)}
                            completed={index < activeStep}
                            {...buttonProps}
                        >
                            {label}
                        </StepButton>
                        </Step>
                    );
                    })}
                </Stepper>
                <div>
                  <div>
                    {getStepContent()}
                    <div className="row mt-5 mb-5">
                      <div className="col-sm-6 justify-content-center text-center">
                        <Button 
                          disabled={activeStep === 0} 
                          onClick={handleBack} 
                          className={classes.button + " mr-3"}
                          size="large"
                        >
                          Back
                        </Button>
                      </div>
                        <div className="col-sm-6 justify-content-center text-center">
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleNext}
                            className={classes.button}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Dialog
                  fullScreen={fullScreen}
                  open={errorMessage !== null}
                  onClose={() => setErrorMessage(null)}
                  aria-labelledby="responsive-dialog-title"
                >
                <DialogTitle id="responsive-dialog-title">Oops, we have a problem</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                      {errorMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setErrorMessage(null)} color="primary" autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )
        }
      </>
    );

}