import React from 'react';
import { Link } from "react-router-dom";


export default function SmartContractDeployNotification(props) {

    return (
      <div className="mt-5 mb-2">
        <div className="d-flex justify-content-center  mb-2 mt-5">
            <div className=" btn-add-project">
            <Link to={"/panel/" + props.blockchainId + "/" + props.projectId + "/" + props.smartContractId}>
                <img src='/icons/goto_folder_success.png' />
            </Link>
            </div>
        </div>
        <div className="d-flex justify-content-center mt-3 add-project-title">
          Your smart contract will be deployed very soon
        </div>
    </div>
    );

}