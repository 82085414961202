const uuidv4 = require("uuid/v4");
const admin = 99;
const client = 1;
const API_URL = "https://api.blockadhoc.com";
const reqHeader = { "Access-Control-Allow-Origin" : "*" , "Content-Type" : "application/json" };
const H64_API = "https://api.h64.fr";
const CONSOLE_URL = "https://console.blockadhoc.com";
const SOCKET = "wss://blockchainwss.blockadhoc.com";
const SmartContractWizard = {
    startingPageIndex : 0 ,
    finishPageIndex : 4 , // +1 to avoid bug
    urls : [ "/smartContractWizard/smartContractConstructor" , "/smartContractWizard/smartContractOverview" , "/smartContractWizard/smartContractDone" ]
};
const reqHeaderFile = { "headers" : { "Access-Control-Allow-Origin": "*", 'Content-Type': 'multipart/form-data;' } };
const smartContractSteps = [ "Setup" , "Overview" , "Deployment" ];
const smartContractVessel = {
    blockchainId : null,
    applicationId : null,
    smartContractId : null,
    abi : [],
    bytecode : "",
    title : "",
    description : "",
    smartContracts : []
};


/* THE USER SESSION OBJECT */
const userSession = {
    user : {},
    setUser: function(input) {
        this.user = input;
    },
    projectsInProgress : [],
    totalProjectsInProgress: function() {
        return this.projectsInProgress.length;
    },
    setTotalProjectsInProgress: function(input) {
        this.projectsInProgress = input;
    },
    pushTotalProjectsInProgress: function(input) {
        this.projectsInProgress.push(input);
    },
    popnTotalProjectsInProgress: function(input) {
        this.projectsInProgress = this.projectsInProgress.map((project,index) => {
            if ( index !== input )
                return project;
        });
    },
    projects : [],
    nodesInProgress : [],
    nodes : [],
    blockchainsInProgress : [],
    blockchains : []
};

module.exports.API_URL = API_URL;
module.exports.admin = admin;
module.exports.reqHeader = reqHeader;
module.exports.H64_API = H64_API;
module.exports.client = client;
module.exports.CONSOLE_URL = CONSOLE_URL;
module.exports.SmartContractWizard = SmartContractWizard;
module.exports.smartContractSteps = smartContractSteps;
module.exports.smartContractVessel = smartContractVessel;
module.exports.reqHeaderFile = reqHeaderFile;
module.exports.userSession = userSession;
module.exports.SOCKET = SOCKET;
module.exports.uuidv4 = uuidv4;