import React, { useState, useEffect } from 'react';
import Bundle from './Bundle.js';
const Request = require('../Config/Request.js');

export default function Panel(props) {

    const user = props.user;

    let blockchainId;
    try{
        blockchainId = props.props.match.params.blockchainId;
    }catch(err){
        console.error(err);
    }

    let bundleId;
    try{
        bundleId = props.props.match.params.smartContractId;
    }catch(err){
        console.error(err);
    }

    let projectId;
    try{
        projectId = props.props.match.params.projectId;
    }catch(err){
        console.error(err);
    }

    const [errorCode, setErrorCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [bundle, setBundle] = useState(null);

    const initializeBundle = async () => {
        let theBundle = await Request.h64HttpRequest("GET", "/getBundle/" + bundleId);
        if ( theBundle ){
            if ( theBundle.errorCode !== undefined && theBundle.errorMessage !== undefined ){
                setErrorCode(theBundle.errorCode);
                setErrorMessage(theBundle.errorMessage);
                if (theBundle.errorCode === null && theBundle.errorMessage === null)
                    setBundle(theBundle.bundle);
            }
        } else {
            setErrorCode(404);
            setErrorMessage("Unable to generate the smart contract methods , please perform a recheck and also make sure it's not a library");
        }
    }

    useEffect(() => {
        try {
            const fetch = async () => {
                await initializeBundle();
                props.setBlockchain(blockchainId);
                props.setProject(projectId);

            }
            if ( bundleId )
                fetch();
        } catch (err) {
            console.error(err);
        }
    }, []);

    const retry = async () => {
        setErrorCode(null); 
        setErrorMessage(null);
        await initializeBundle();
    }

    return (
        <>

            <div className="page-header  m-0 p-0 w-100 d-flex">
                <div className="w-100 page-header-title">Bundle Panel</div>
            </div>

            {
                bundle ? (
                    <Bundle 
                        bundle={bundle} 
                        blockchainId={blockchainId} 
                        projectId={projectId} 
                        user={user} 
                        bundleId={bundleId} 
                    />
                ) : (
                    <div className={"mt-5 mb-5" + (errorCode === null && errorMessage === null ? " d-none" : "")}>
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {errorMessage}
                            <div className="row">
                                <button 
                                    type="button" 
                                    className="close d-inline ml-2 mr-2" 
                                    data-dismiss="alert" 
                                    onClick={retry} 
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        &times;
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                )

            }
        </>
    );
}