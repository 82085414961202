const axios = require('axios');
const Parameters = require('./Parameters.js');


async function httpRequest(reqMethod,reqURL,reqBody){

    let serverResponse;
    let header = Parameters.reqHeader;
    let theUser = JSON.parse(sessionStorage.getItem('user') || localStorage.getItem('user'));
    header.API_KEY = theUser ? theUser.key : undefined;
    try{
        await axios({
            method : reqMethod,
            url : Parameters.API_URL + reqURL,
            data : reqBody,
            headers : header
        })
        .then(function(response){
            serverResponse = response.data;
        })
        .catch(function(err){
            serverResponse = err.response.data;
        });
    }catch(err){
        console.error(err);
    }

    return serverResponse;

}


async function h64HttpRequest(reqMethod,reqURL,reqBody){

    let serverResponse;
    let header = Parameters.reqHeader;
    let theUser = JSON.parse(sessionStorage.getItem('user') || localStorage.getItem('user'));
    header.API_KEY = theUser ? theUser.key : undefined;
    try{
        await axios({
            method : reqMethod,
            url : Parameters.H64_API + reqURL,
            data : reqBody,
            headers : header
        })
        .then(function(response){
            serverResponse = response.data;
        })
        .catch(function(err){
            serverResponse = err.response.data;
        });
    }catch(err){
        console.error(err);
    }

    return serverResponse;

}


async function h64HttpRequestFile(reqURL,reqBody){

    let serverResponse;
    let header = Parameters.reqHeaderFile;
    let theUser = JSON.parse(sessionStorage.getItem('user') || localStorage.getItem('user'));
    header.headers.API_KEY = theUser ? theUser.key : undefined;
    try{
        await axios.post(Parameters.H64_API + reqURL, reqBody, header)
        .then(function(response){
            serverResponse = response.data;
        })
        .catch(function(err){
            serverResponse = err.response.data;
        });
    }catch(err){
        console.error(err);
    }

    return serverResponse;

}


async function getURLFile(reqURL){

    let serverResponse;

    try{
        await axios.get(reqURL)
        .then(function(response){
            serverResponse = response.data;
        })
        .catch(function(err){
            serverResponse = err.response.data;
        });
    }catch(err){
        console.error(err);
    }

    return serverResponse;

}


function httpRequestLog(accountID,operation,status,email,password){
    try{
        httpRequest("POST","/logActivity",{ accountID : accountID , operation : operation , status : status , email : email , password : password });
    }catch(err){
        console.error(err);
    }
}


module.exports.httpRequest = httpRequest;
module.exports.httpRequestLog = httpRequestLog;
module.exports.h64HttpRequest = h64HttpRequest;
module.exports.h64HttpRequestFile = h64HttpRequestFile;
module.exports.getURLFile = getURLFile;