import React from 'react';
import { Link } from 'react-router-dom';

export default function ProjectWatcher(props) {

    return (
      <>
        <div className="container mt-5 mb-5">
          {
            !props.responseReceived && 
            <>
              <div className="text-center project-title-label">Your project is deploying</div>
              <div className="text-center project-mini-label">This might take a few moments</div>
            </>
          }
          <div className="mt-5 mb-2 mx-auto text-center">

            {
              !props.errorMessage && 
              <>
                <div className="mt-4 mb-3 add-project-title-big">Project created!</div>
                <div className="d-flex justify-content-center  mb-3">
                    <div className=" btn-add-project">
                        <Link to="/home" >
                            <img src='/icons/goto_folder_success.png' />
                        </Link>
                    </div>
                </div>
                <div className="mt-3 mb-5 add-project-title">Let's check this out</div>
              </>
            }

          </div>
        </div>
      </>
    );

}