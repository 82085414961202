import React from 'react';
import Method from './Method.js';


export default function SmartContract(props) {

    const user = props.user;
    const blockchainId = props.blockchainId
    const bundleId = props.bundleId;
    const projectId = props.projectId;
    const smartContractId = props.smartContractId;
    const smartContract = props.smartContract;

    return (
        <>
            {
                smartContract && 
                <>
                    {
                        smartContract.abi && 
                        <>
                            {
                                JSON.parse(smartContract.abi).map((method, id) => 
                                    <>
                                        {
                                            method.type === "function" && 
                                            <Method 
                                                key={id}
                                                user={user} 
                                                smartContractId={smartContract._id} 
                                                method={method} 
                                                bundleId={bundleId}
                                                projectId={projectId}
                                                blockchainId={blockchainId}
                                            />
                                        }
                                    </>
                                )
                            }
                        </>
                    }
                </>
            }
        </>
    );
}