import React from 'react';
import { Line } from 'react-chartjs-2';


export default function GradientChart(props) {

    const data = {
        labels: props.labels,
        datasets: [
            {
                label: props.datasetsLabelTemplate,
                data: props.datasetsData,
                fill: props.isFill,
                backgroundColor : props.gradient,
                borderColor: 'rgba(' + props.borderColor[0] + ', ' + props.borderColor[1] + ', ' + props.borderColor[2] + ', ' + props.borderColor[3] + ')',
                pointRadius: 0,
                borderWidth: 1,
                borderJoinStyle: 'round'
            }
        ],
    }
      
    const options = {
        responsive: true,
        maintainAspectRatio: true,
        showLine: false,
        scaleShowLabels: false,
        showGaps: false,
        steppedLine: false,
        legend: {
            display: false,
            labels: {
                fontSize: 0
            }
        },
        tooltips: {
            callbacks: {
               label: function(tooltipItem) {
                      return tooltipItem.yLabel;
               }
            }
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display:false,
                    drawBorder: false
                },
                scaleLabel: {
                    display: false,
                    labelString: ''
                },
                ticks: {
                    display: false
                }
            }],
            yAxes: [{
                gridLines: {
                    display:false,
                    drawBorder: false
                },
                scaleLabel: {
                    display: false,
                    labelString: ''
                },
                ticks: {
                    display: false
                }
            }]
        }
    }

    return (
        <Line 
            data={data} 
            options={options} 
            className="chart-container-align chart-size" 
        />
    )
}