import React from 'react';
import { Link } from 'react-router-dom';


export default function PageTitle(props) {


    return (
        <div className="page-header  m-0 p-0 w-100 d-flex">
            <div className="w-100 page-header-title">{props.title}</div>
            {
                props.button && 
                <div className="flex-shrink-1 mb-4 mr-4">
                    <Link to={props.button.path} className="link-action-sumbit">
                        {props.button.label}
                    </Link>
                </div>
            }
        </div>
    );
}