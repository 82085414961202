import React from 'react';
import { Link } from 'react-router-dom';


export default function IconNotification(props) {

    const iconURL = props.iconURL;
    const redirectTo = props.redirectTo;
    const notificationMessage = props.notificationMessage;

    return (
        <div className="mt-5 mb-2 mx-auto text-center">
            <div className="d-flex justify-content-center  mb-2">
                <div className=" btn-add-project">
                    <Link to={redirectTo} >
                        <img src={iconURL} />
                    </Link>
                </div>
                
            </div>
            <div className="mt-3 add-project-title">{props.notificationMessage}</div>
        </div>
    );
}