import React from 'react';
import Button from 'react-bootstrap/Button';

export default function DeployToProductionButton(props) {
    let onClick = props.onClick;
    let label = props.label;

    return (
        <Button onClick={onClick} className="link-back-sumbit d-inline">
            {label}
        </Button>
    )
}