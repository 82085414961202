import React from 'react';
import SmartContract from './SmartContract.js';
const Request = require('../Config/Request.js');


export default function Panel(props) {

    const user = props.user;
    const blockchainId = props.blockchainId;
    const projectId = props.projectId;
    const bundleId = props.bundleId;
    const bundle = props.bundle;

    return (
        <>
            {/* Bundle header */}
            <div className="container mt-3">
                <div className="bc-title" >
                    <i className="fas fa-box-open"></i> 
                    {bundle.title}
                </div>
                <p className="mb-2">
                    {bundle.description}
                </p>
                {
                    bundle.smartContracts &&
                    <p>Included Smart Contracts: {bundle.smartContracts.length} </p>
                }
                {
                    bundle.smartContracts.filter(sc => sc.address && sc.address.length > 10).length > 0 && 
                    <p>
                        Smart Contract Address : 
                        <span className="ml-2">
                            {
                                bundle.smartContracts.map((sc) => 
                                    <>
                                        {
                                            sc.address && sc.address.length > 5 && 
                                            <>
                                                {sc.address}
                                            </>
                                        }
                                    </>
                                )
                            }
                        </span>
                    </p>
                }
            </div>


            {/* Bundle smart contracts */}
            <div className="container">
                {
                    bundle.smartContracts.map((sc, id) =>
                        <SmartContract 
                            key={id}
                            smartContract={sc}
                            smartContractId={sc._id}
                            bundleId={bundleId}
                            projectId={projectId}
                            blockchainId={blockchainId}
                            user={user}
                        />
                    )
                }
            </div>
        </>
    );
}