import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


export default function SmartContractConstructorParameterHandler(props) {

    const constructor = props.constructor;
    const setSCToDeployProperty = props.setSCToDeployProperty;
    const addTheAddingSmartContract = props.addTheAddingSmartContract;
    const contractIndex = props.contractIndex;
    const constructorIndex = props.constructorIndex;
    const contractName = props.contractName;

    return (
        <>
            {
                ( constructor.dataType === "string" || constructor.dataType.indexOf("bytes") !== -1 ) && 
                <div className="mt-3">
                    <TextField 
                        id="outlined-basic" 
                        className="w-100" 
                        label={constructor.displayName} 
                        variant="outlined" 
                        value={constructor.value} 
                        onChange={(e) => setSCToDeployProperty(contractIndex, constructorIndex, e.target.value)} 
                    />
                </div>
            }
            {
                constructor.dataType === "bool" &&
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={constructor.value === "true" || constructor.value === true}
                            value={constructor.value}
                            onChange={(e) => setSCToDeployProperty(contractIndex, constructorIndex, e.target.value)}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label={constructor.codeName}
                />
            }
            {
                constructor.dataType.indexOf("int") !== -1 &&
                <div className="mt-3">
                    <TextField 
                        id="outlined-basic" 
                        className="w-100" 
                        label={constructor.displayName} 
                        variant="outlined" 
                        value={constructor.value} 
                        onChange={(e) => setSCToDeployProperty(contractIndex, constructorIndex, e.target.value)} 
                    />
                </div>
            }
            {
                constructor.dataType === "address" &&
                <div
                    className="card mt-3 mb-3 ml-5 mr-5 justify-content-center text-center lowPush shadow-lg border-0 clickableCard"
                    style={{ "width": "15rem", "height": "15rem" }}
                    onClick={() => addTheAddingSmartContract(constructor, contractIndex, contractName, constructorIndex)}
                >
                    {
                        props.constructor.value.startsWith("0x") ? (
                            <>
                                {
                                    props.constructor.value &&
                                    <>
                                        <p className="font-weight-bold">{props.constructor.value}</p>
                                        <i class="fas fa-file-contract fa-5x"></i>
                                    </>
                                }
                            </>
                        ) : (
                                <>
                                    <p className="font-weight-bold">{props.constructor.codeName}</p>
                                    <i className="fas fa-plus fa-5x"></i>
                                </>
                            )
                    }
                </div>
            }
        </>
    );

}