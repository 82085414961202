import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}


export default function ErrorHandler(props) {
    const [open, setOpen] = useState(false);
    const [transition, setTransition] = useState(undefined);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {
                props.error && 
                <Snackbar
                    severity="success"
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={transition}
                    message={props.error}
                    key={transition ? transition.name : ''}
                />
            }
        </>
    );
}