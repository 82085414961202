import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import BackButton from './Common/BackButton.js';
import DeployToProductionButton from './Common/DeployToProductionButton.js';
import Zoom from '@material-ui/core/Zoom';
const Request = require('./Config/Request.js');


export default function ProjectHome(props) {
    const { blockchainId, projectId } = useParams();
    let [errorCode, setErrorCode] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    let [blockchain, setBlockchain] = useState([]);
    let [application, setApplication] = useState(null);
    let history = useHistory();

    let getBlockchain = async () => {
        try {
            let theBlockchain = await Request.h64HttpRequest("GET", "/blockchain/" + blockchainId, {});
            if ( theBlockchain ) 
                if ( theBlockchain.errorCode !== undefined && theBlockchain.errorMessage !== undefined ) {
                    if ( theBlockchain.blockchain )
                        setBlockchain(theBlockchain.blockchain);
                }
        } catch (err) {
            console.error(err);
        }
    }

    const getApp = async () => {
        try {
            let app = await Request.h64HttpRequest("GET", "/getApplication/" + projectId, {});
            if ( app ) 
                if ( app.errorCode !== undefined && app.errorMessage !== undefined ) {
                    setErrorCode(app.errorCode);
                    setErrorMessage(app.errorMessage);
                    if ( app.application )
                        setApplication(app.application);
                }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        try {

            const fetch = async () => {

                await getBlockchain();

                await getApp();

                if (blockchainId && projectId) {
                    props.setBlockchain(blockchainId);
                    props.setProject(projectId);
                }

            }
            fetch();

        } catch (err) {
            console.error(err);
        }
    }, []);

    const deployProject = async () => {
        try {
            let deployTheProject = await Request.h64HttpRequest("PUT", "/deployProject/" + projectId, {});
            if ( deployTheProject ) 
                if ( deployTheProject.errorCode !== undefined && deployTheProject.errorMessage !== undefined ) {
                    setErrorCode(deployTheProject.errorCode);
                    setErrorMessage(deployTheProject.errorMessage);
                    if ( deployTheProject.status !== undefined )
                        setApplication({ ...application , production : deployTheProject.status });
                }
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    return (
        <>

            <div>
                <div className="page-header m-0 p-0 w-100 d-flex">
                    <div className="page-header-title">Blockchain project manager</div>
                </div>

                <div className="container mt-4">

                    <div className="mb-3 ml-3 mr-3 mt-3">
                        <div className="mb-1 text-mini-1 pb-4 pt-4" >
                            <div className="row">
                                <div className="col-sm-2">
                                    <BackButton></BackButton>
                                </div>
                                <div className="col-sm-8">
                                    {
                                        blockchain && 
                                        <>
                                            {blockchain.name}
                                        </>
                                    }
                                    <i className="fas fa-chevron-right ml-2 mr-2"></i>
                                    <div className="text-maxi-1" >
                                        {
                                            application && 
                                            <>
                                                {application.name}
                                            </>
                                        }
                                    </div>
                                </div>
                                {
                                    application && 
                                    <div className="col-sm-2">
                                        <DeployToProductionButton label={application.production ? "Back to development" : "Deploy to production"} onClick={deployProject} />
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="row">

                            <Zoom in={true} >
                                <div className="col-3 mt-5">
                                    <div className="card dashboard-bar" onClick={() => history.push("/project/" + blockchainId + "/" + projectId + "/infos")}  >
                                        <div className="row h-100">
                                            <div className="col-8  align-self-end p-0">
                                                <div className="bm-text " >Blockchain Management</div>
                                            </div>
                                            <div className="col-4 p-0 align-self-center">
                                                <img src="/icons/bcm_management_bg.svg" />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Zoom>

                            <Zoom in={true} >
                                <div className="col-3 mt-5">
                                    <div className="card dashboard-bar" onClick={() => history.push("/explorer/" + blockchainId + "/" + projectId)} >
                                        <div className="row h-100">
                                            <div className="col-8  align-self-end p-0">
                                                <div className="bm-text " >Blockchain Explorer</div>
                                            </div>
                                            <div className="col-4 p-0 align-self-center">
                                                <img src="/icons/bcm_explorer_bg.svg" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Zoom>

                            <Zoom in={true} >
                                <div className="col-3 mt-5">
                                    <div className="card dashboard-bar" onClick={() => history.push("/smartContracts/" + blockchainId + "/" + projectId )}  >
                                        <div className="row h-100">
                                            <div className="col-8  align-self-end p-0">
                                                <div className="bm-text " >Smart Contracts Library</div>
                                            </div>
                                            <div className="col-4 p-0 align-self-center">
                                                <img src="/icons/bcm_smartContacts_bg.svg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Zoom>

                            <Zoom in={true} >
                                <div className="col-3 mt-5">
                                    <div className="card dashboard-bar" onClick={() => history.push("/members/" + blockchainId + "/" + projectId)}  >
                                        <div className="row h-100">
                                            <div className="col-8  align-self-end">
                                                <div className="bm-text " >Members</div>
                                            </div>
                                            <div className="col-4 p-0 align-self-center">
                                                <img src="/icons/bcm_members_bg.svg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Zoom>

                        </div>
                    </div>
                </div>
                <div className="small-spacer mt-5"></div >
            </div>

        </>
    );
}