import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(30),
    marginRight: theme.spacing(30)
  },
}));

export default function Step1(props) {
    const classes = useStyles();

    return (
      <>
        <div className="container mt-5 mb-5">
          <div className="text-center project-title-label">Project identification</div>
          <div className="text-center project-mini-label">Choose a name for this project</div>

          <div className="mt-5 mb-5 justify-content-center row" >
            <TextField
              label="Project name"
              id="outlined-margin-normal"
              fullWidth
              defaultValue={props.name}
              onChange={(e) => props.setName(e)}
              className={classes.textField}
              helperText="Do not worry, you will be able to modify it later"
              margin="normal"
              variant="outlined"
            />
          </div>
        </div>
      </>
    );

}