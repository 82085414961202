import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Form from 'react-bootstrap/Form';

export default function RenderSmartContractMethodInputs(props) {

    const method = props.method;
    const smartContractId = props.smartContractId;
    const setMethodParameter = props.setMethodParameter;

    return <>
        {
            method && 
            <>
                {
                    method.inputs && 
                    <>
                        {
                            method.inputs.map((input, iid) =>
                                <div key={iid} className="container" >
                                    {
                                        input.type === "bool" &&
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={input.value === true}
                                                    value={input.value === true}
                                                    onChange={
                                                        () => setMethodParameter(input.name, input.value !== true)
                                                    }
                                                    color="primary"
                                                />
                                            }
                                            label={input.name}
                                            className="mt-2"
                                        />
                                    }
                                    {
                                        input.type && 
                                        <>
                                            {
                                                ( input.type === "string" || input.type.toLowerCase().indexOf("bytes") !== -1 || input.type === "address" ) &&
                                                <Form.Group className="mt-1 ml-3" >
                                                    <h6>{input.name}</h6>
                                                    <Form.Control 
                                                        type="text" 
                                                        value={input.value} 
                                                        onChange={
                                                            (e) => setMethodParameter(input.name, e.target.value)
                                                        }
                                                    />
                                                </Form.Group>
                                            }
                                            {
                                                input.type.toLowerCase().indexOf("int") !== -1 &&
                                                <Form.Group className="mt-1 ml-3" >
                                                    <h6>{input.name}</h6>
                                                    <Form.Control 
                                                        type="number" 
                                                        value={input.value} 
                                                        onChange={
                                                            (e) => setMethodParameter(input.name, e.target.value)
                                                        }
                                                    />
                                                </Form.Group>
                                            }
                                        </>
                                    }
                                </div>
                            )
                        }   
                    </>
                }
            </>
        }
    </>;

}