import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Parameters from '../Config/Parameters.js';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
const Request = require('../Config/Request.js');


export default function ProjectFrame(props) {
    const history = useHistory();
    const project = props.project;

    return (
        <div className="btn-container mx-auto mt-1">
            <div className="pt-1" >
                <Button className="btn-mini mx-auto" onClick={
                        () => history.push("/project/" + project.blockchainUsed.id + "/" + project._id)
                    } 
                >
                    <i className="fas fa-arrow-right"></i>
                </Button>
            </div>
            <div  >
                <Button className="btn-mini mt-1 mx-auto" onClick={
                        () => props.displayUpdateProject(project)
                    } 
                >
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            {
                project.owner.id === props.user.h64AccountID &&
                <div >
                    <Button className="btn-mini mt-1 mx-auto" onClick={
                            () => props.displayRemoveProject(project)
                        } 
                    >
                        <i className="fas fa-trash-alt"></i>
                    </Button>
                </div>
            }

        </div>
    );
}