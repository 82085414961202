import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Invite from './Invite.js';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import BackButton from './Common/BackButton.js';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import { translate } from 'react-i18next';
const Request = require('./Config/Request.js');


//function ProjectMembers(props) {
const ProjectMembers = (props) => {
    const { blockchainId, projectId } = useParams();
    //const [t, i18n] = useTranslation();
    // const { i18n } = useTranslation();
    let [errorCode, setErrorCode] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    const user = JSON.parse(sessionStorage.getItem('user'));
    let [referralSent, setReferralSent] = useState(null);
    let [members, setMembers] = useState([]);
    let [projectJoinRequests, setProjectJoinRequests] = useState([]);
    let [memberToDelete, setMemberToDelete] = useState(null);
    const [removeUserDialog, setRemoveUserDialog] = useState(false);

    const handleClickRemoveUserDialogOpen = () => {
        setRemoveUserDialog(true);
    };
  
    const handleClickRemoveUserDialogClose = () => {
        setRemoveUserDialog(false);
    };

    useEffect(() => {
        if ( blockchainId && projectId ){
            props.setBlockchain(blockchainId);
            props.setProject(projectId);
        }
    }, [blockchainId,projectId]);

    let getProjectMembers = async () => {
        let projectMembers = await Request.h64HttpRequest("GET", "/getAllProjectMembers/" + projectId, {});
        return projectMembers;
    }

    useEffect(() => {
        try {
            const fetch = async () => {
                let projectMembers = await getProjectMembers();
                setMembers(projectMembers.members);
                setProjectJoinRequests(projectMembers.projectJoinRequests);
            }
            fetch();
        } catch (err) {
            console.error(err);
        }
    }, []);

    let updateTheListWithNewUser = (newUser) => {
        setReferralSent("Congratulations, " + newUser.email + " has been successfully added!");
        setMembers(members => [...members, newUser]);
    }

    let getProjectsNumber = (cell, row) => {
        return <>{row.applications.length}</>;
    }

    let deleteMember = (member) => {
        setMemberToDelete(member);
        handleClickRemoveUserDialogOpen(true);
    }

    let membersOperations = (cell, row) => {
        if ( !checkIsPorjectOwner(row.accountAddress) )
            return <>
                <button className="btn btn-danger" onClick={() => deleteMember(row)} >
                    <i className="fas fa-trash-alt"></i>
                </button>
            </>;
    }

    let deleteMemberNow = async () => {
        let deleteMemberRequest = await Request.h64HttpRequest("DELETE", "/deleteMember/" + memberToDelete.accountAddress + "/fromProject/" + projectId, {});
        if (deleteMemberRequest) {
            setErrorCode(deleteMemberRequest.errorCode);
            setErrorMessage(deleteMemberRequest.errorMessage);
            let newMemebers = [];
            for (let i = 0, l = members.length; i < l; ++i)
                if (members[i].accountAddress.toLowerCase() !== memberToDelete.accountAddress.toLowerCase())
                    newMemebers.push(members[i]);
            setMembers(newMemebers);
            handleClickRemoveUserDialogClose();
        }
    }

    let getPendingRequests = () => {
        if ( members )
            for ( let i = 0 , l = members.length ; i < l ; ++i )
                if ( members[i]._id === props.user.h64AccountID ){
                    let requestsToReturn = [];
                    for ( let j = 0 , k = members[i].projectJoinRequests.length ; j < k ; ++j ){
                        if ( !members[i].projectJoinRequests[j].accepted )
                            requestsToReturn.push(members[i].projectJoinRequests[j]);
                    }
                    return requestsToReturn;
                }
        return [];
    }

    let checkIsPorjectOwner = ( accountAddress ) => {
        if ( props.user.blockchainAccounts )
            for ( let i = 0 , l = props.user.blockchainAccounts.length ; i < l ; ++i )
                if ( props.user.blockchainAccounts[i].accountAddress )
                    if ( props.user.blockchainAccounts[i].accountAddress.toLowerCase() === accountAddress.toLowerCase() )
                        return true;
        return false;
    }

    return (
        <>

        <Dialog
            open={removeUserDialog}
            onClose={handleClickRemoveUserDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"You're about to remove a member"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                You're about to remove a member from this project, are you sure?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClickRemoveUserDialogClose} color="primary">
                No
            </Button>
            <Button onClick={deleteMemberNow} color="primary" autoFocus>
                Yes, remove it
            </Button>
            </DialogActions>
        </Dialog>

            <div className="page-header  m-0 p-0 w-100 d-flex page-header-min">

                <div class="w-100 page-header-title">Project members</div>
                <div class="flex-shrink-1 mb-4 mr-4">
                    <Invite updateTheListWithNewUser={updateTheListWithNewUser} blockchainId={blockchainId} projectId={projectId} ></Invite>
                </div>

            </div>

            <div className="container ">

                <div className="mb-1 text-mini-1 pb-4 pt-4" >
                    <div className="row">
                        <div className="col-sm-2">
                            <BackButton></BackButton>
                        </div>
                        <div className="col-sm-8 ml-4">
                            {blockchainId} <i className="fas fa-chevron-right ml-2 mr-2"></i> {projectId}
                            <div className="text-maxi-1" >
                                Add members to your project
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"mt-3 mb-3" + (referralSent === null ? " d-none" : "")}>
                    <div className="alert alert-success" role="alert">{referralSent}</div>
                </div>

                <div className="mt-5 w-100">
                    <h6>Current members on the project</h6>
                </div>

                {
                    members && members.length > 0 ? (
                        <BootstrapTable version='4' data={members} search className="mb-5"
                            hover
                            tableContainerClass='table-nodes'
                            tableBodyClass='table-nodes-body'
                            headerContainerClass='table-nodes-header'>
                            <TableHeaderColumn dataField='email' dataSort={true} isKey={true}>Email</TableHeaderColumn>
                            <TableHeaderColumn dataField='name' dataSort={true} >Name</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={membersOperations} >Operations</TableHeaderColumn>
                        </BootstrapTable>
                    ) : (
                        <div class="alert alert-info" role="alert">
                            No members on this project
                        </div>
                    )
                }

                <div className={"mt-5 mb-5" + (errorCode === null && errorMessage === null ? " d-none" : "")}>
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        {errorMessage}
                        <button type="button" className="close" data-dismiss="alert" onClick={() => { setErrorCode(null); setErrorMessage(null); }} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );

}

export default ProjectMembers
