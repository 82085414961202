import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HttpIcon from '@material-ui/icons/Http';
import Badge from '@material-ui/core/Badge';
import SmartContractMethodRequestExampleProvider from './smartContractMethodRequestExampleProvider.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PopoverWrapper from '../Common/popover.js';

export default function MethodHeader(props) {

    const user = props.user;
    const method = props.method;
    const constructor = props.constructor;
    const smartContractId = props.smartContractId;
    const contentVisible = props.contentVisible;
    const bundleId = props.bundleId;
    const projectId = props.projectId;
    const notifications = props.notifications;

    return (
        <>
            {
                method && 
                <>
                    {
                        constructor === true ? (
                            <h5 className="mt-2" >

                                constructor

                                <span className="sc-method-text">
                                    ( {
                                        method.inputs && method.inputs.map((min, mid) =>
                                            <span key={mid} >
                                                {
                                                    mid === method.inputs.length - 1 ? (
                                                        <>
                                                            <span className="sc-method-address">{ min.type}</span> {min.name}
                                                        </>
                                                    ) : (
                                                            <>
                                                                <span className="sc-method-address">{ min.type}</span> {min.name + ", "} 
                                                            </>
                                                        )
                                                }
                                            </span>
                                        )
                                    } )
                                </span>
                            </h5>
                        ) : (
                            <h5 className="mt-2" >

                                {method.name}

                                <span className="sc-method-text">
                                    ( {
                                        method.inputs && method.inputs.map((min, mid) =>
                                            <span key={mid} >
                                                {
                                                    mid === method.inputs.length - 1 ? (
                                                        <>
                                                            <span className="sc-method-address">{ min.type}</span> {min.name}
                                                        </>
                                                    ) : (
                                                            <>
                                                                <span className="sc-method-address">{ min.type}</span> {min.name + ", "} 
                                                            </>
                                                        )
                                                }
                                            </span>
                                        )
                                    } )
                                </span>

                                <PopoverWrapper
                                    content={
                                        <SmartContractMethodRequestExampleProvider 
                                            user={user}
                                            method={method}
                                            smartContractId={smartContractId}
                                            projectId={projectId}
                                            bundleId={bundleId}
                                        />
                                    }
                                    icon={
                                        <Button color="primary" >JS</Button>
                                    }
                                />
                                <Badge 
                                    badgeContent={notifications} 
                                    color="primary" 
                                    className="sc-chevron-down mr-2" 
                                    onClick={
                                        () => props.setContentVisible(!contentVisible)
                                    }    
                                >
                                    <IconButton 
                                        color="primary" 
                                        aria-label="upload picture" 
                                        component="span" 
                                        disabled={!notifications}
                                        onClick={
                                            () => props.setContentVisible(!contentVisible)
                                        }
                                    >
                                        {
                                            contentVisible ? (
                                                <ExpandLessIcon/>
                                            ) : (
                                                <ExpandMoreIcon/>
                                            )
                                        }
                                    </IconButton>
                                </Badge>

                            </h5>
                        )
                    }
                    <div className="text-mini-1 mb-2">
                        {method.constant ? "Constant" : "Not constant"} - {method.stateMutability}
                    </div>
                </>
            }
        </>
    );
}