import React , { useState , useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
const Request = require('./Config/Request.js');


function SmartContractDocumentation(props) {
    const smartContractId = props.props.match.params.smartContractId;
    const projectId = props.props.match.params.projectId;
    const blockchainId = props.props.match.params.blockchainId;
    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);
    let [smartContract,setSmartContract] = useState(null);

    let getSmartContract = async () => {
        let theSmartContract = await Request.h64HttpRequest("GET","/getSmartContract/" + smartContractId,{});
        return theSmartContract.smartContract;
    }

    useEffect( () => {
        try{
            const fetch = async () => {
                let theSmartContract = await getSmartContract();
                setSmartContract(theSmartContract);
                props.setBlockchain(blockchainId);
                props.setProject(projectId);
            }
            fetch();
        }catch(err){
            console.error(err);
        }
    },[]);

    return (
        <>
            <div className="page-header  m-0 p-0 w-100 d-flex page-header-min">
                <div class="w-100 page-header-title"> Smart Contracts Documentation</div>
            </div>
            <div className={" mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                <div className="alert alert-danger" role="alert">{errorMessage}</div>
            </div>

            <div className="container mt-2 ml-2" >
                {
                    smartContract !== null && 
                    <div className="mt-6">
                        <Form.Group className="mt-3 mb-3" >
                            <InputLabel className="text-dark font-weight-bold" >Product overview</InputLabel>
                            <InputLabel className="text-dark ml-2" >{smartContract.productOverview}</InputLabel>
                        </Form.Group>

                        <Form.Group className="mt-3 mb-3" >
                            <InputLabel className="text-dark font-weight-bold" >Further reading</InputLabel>
                            <InputLabel className="text-dark ml-2" >
                                {
                                    smartContract.furtherReadingLinks && smartContract.furtherReadingLinks.map((link,linkid) => 
                                        <div className="row ml-3">
                                            <a key={linkid} href={link.text} target="_blank" >{link.url}</a>
                                        </div>
                                    )
                                }
                            </InputLabel>
                        </Form.Group>

                        <Form.Group className="mt-3 mb-3" >
                            <InputLabel className="text-dark font-weight-bold" >Highlights</InputLabel>
                            <InputLabel className="text-dark ml-2" >{smartContract.highlights}</InputLabel>
                        </Form.Group>

                        <Form.Group className="mt-3 mb-3" >
                            <InputLabel className="text-dark font-weight-bold" >Implementation time</InputLabel>
                            <InputLabel className="text-dark ml-2" >{smartContract.implementationTime}</InputLabel>
                        </Form.Group>

                        <Form.Group className="mt-3 mb-3" >
                            <InputLabel className="text-dark font-weight-bold" >Required experience</InputLabel>
                            <InputLabel className="text-dark ml-2" >{smartContract.requiredExperience}</InputLabel>
                        </Form.Group>
                    </div>
                }
            </div>
        </>
    );
}

export default SmartContractDocumentation;
