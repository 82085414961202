import React from 'react';
import { useHistory } from 'react-router-dom';
import Parameters from '../Config/Parameters.js';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import ProjectFramePanel from './ProjectFramePanel.js';


export default function ProjectFrame(props) {
    const history = useHistory();
    const project = props.project;

    return (
        <div className="row justify-content-center mt-5"  >
            <div className="card-project m-3 ">
                <div className="row">
                    <div className="col-4 d-flex align-items-center justify-content-center" >
                        {
                            project.blockchainUsed && 
                            <img className="img-fluid" src={Parameters.H64_API + "/public/blockchains/profilepictures/" + project.blockchainUsed.id + ".png"} alt={project.blockchainUsed.name} />
                        }
                    </div>
                    <div className="col-5 mx-auto" >
                        <div className="project-id">{project._id}</div>
                        <div className="project-title mt-1">{project.name}</div>

                        <div className="row">
                            <div className="col-2">
                                <div className="project-hour-ico d-flex align-items-center justify-content-center">
                                    <i className="far fa-clock "></i>
                                </div>
                            </div>
                            <div className="col-10">
                                <div className="card-project-date ml-3">{moment(project.creationDate).format("L")}</div>
                                <div className="card-project-hour ml-3">{moment(project.creationDate).format("HH:mm:ss")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-3" >
                        <ProjectFramePanel 
                            project={project} 
                            user={props.user} 
                            displayUpdateProject={props.displayUpdateProject}
                            displayRemoveProject={props.displayRemoveProject}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}