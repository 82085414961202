import React from 'react';
import MemoryIcon from '@material-ui/icons/Memory';

export default function ConstructorRadar(props) {

    const abi = props.abi;
    const constructorParameters = props.constructorParameters;

    return (
        <>
            {
                abi.map((_function,_id) => 
                    <div key={_id}>
                        {
                            _function.type === "constructor" && 
                            <div className="container row mt-5">
                                <span>
                                    {/*
                                    <MemoryIcon style={
                                        {
                                            width: 60,
                                            height: 60
                                        }
                                    } className="float-left" />
                                    <h6>
                                        constructor({
                                            _function.inputs.map((_func,_iid) => 
                                                <div key={_iid} className="d-contents" >
                                                    {_func.internalType + " " + _func.name + ( _iid === _function.inputs.length - 1 ? "" : ", ")}
                                                </div>
                                            )
                                        })
                                    </h6>
                                    <hr className="mt-4" />
                                    */}
                                    {/*
                                    <h6 className="material-ui-primary">
                                        constructor({
                                            _function.inputs.map((_func,_iid) => 
                                                <div key={_iid} className="d-contents" >
                                                    {_func.name + ": " + (
                                                        constructorParameters[_iid].value !== undefined ? constructorParameters[_iid].value : "..."
                                                    ) + ( _iid === _function.inputs.length - 1 ? "" : ", ")}
                                                </div>
                                            )
                                        })
                                    </h6>
                                    */}

                                </span>
                            </div>
                        }
                    </div>
                )
            }
        </>
    );

}