import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import getCategories from './Functions/getCategories.js';
import DangerButton from './Common/DangerButton.js';
import SuccessButton from './Common/SuccessButton.js';
const Request = require('./Config/Request.js');


export default function OrderSmartContract(props) {

    const { projectId } = useParams();
    let [categories, setCategories] = useState(null);
    let [description, setDescription] = useState("");
    let [title, setTitle] = useState("");
    let [isPublic, setIsPublic] = useState(false);
    let [furtherReadingLinks, setFurtherReadingLinks] = useState([{ url: "", text: "" }]);
    let [highlights, setHighlights] = useState("");
    let [implementationTime, setImplementationTime] = useState("");
    let [requiredExperience, setRequiredExperience] = useState("");
    let history = useHistory();

    useEffect(() => {
        try {
            const fetch = async () => {
                let typesSubtypes = await getCategories();
                setCategories(typesSubtypes);
            }
            fetch();
        } catch (err) {
            console.error(err);
        }
    }, []);

    let setSubtype = (type, subtype) => {
        for (let i = 0, l = categories.length; i < l; ++i)
            if (categories[i].name === type)
                for (let j = 0, k = categories[i].subcategories.length; j < k; ++j)
                    if (categories[i].subcategories[j].name === subtype)
                        categories[i].subcategories[j].checked = true;
    }

    let deploy = async () => {
        let types = categories.map((categ) => categ.name), subtypes = categories.map((categ) => categ.subcategories.map((subcat) => subcat.name));
        let demand = await Request.h64HttpRequest("POST", "/setSmartContractDemand", {
            projectId: projectId,
            types: types,
            subtypes: subtypes,
            title: title,
            description: description,
            isPublic: isPublic,
            description: description,
            links: furtherReadingLinks,
            highlights: highlights,
            implementationTime: implementationTime,
            requiredExperience: requiredExperience
        });
        if (demand) {
            if (demand.errorCode === null && demand.errorMessage === null)
                history.push("/smartContracts/" + demand.demand._blockchainId + "/" + projectId);
        }
    }

    let addLink = () => {
        setFurtherReadingLinks([...furtherReadingLinks, ...[{ url: "", text: "" }]]);
    }

    let removeLink = (id) => {
        setFurtherReadingLinks(furtherReadingLinks.filter((link, _id) => id !== _id));
    }

    let setLinkProperty = (index, prop, value) => {
        setFurtherReadingLinks(furtherReadingLinks.filter((link, _id) => {
            if (_id === index)
                link[prop] = value;
            return link;
        }));
    }

    return (
        <>
            <div className="page-header m-0 p-0 w-100 d-flex">
                <div className="page-header-title">Order a Smart Contract</div>
            </div>
            <div className="container mt-4" >

                <div className="row">
                    {
                        categories && categories.map((cat,catid) => 
                            <div className="col-sm-3" key={catid} >
                                <ul className="list-infos">
                                    <li className="text-uppercase text-color60 mb-1 li-no-bullet">
                                        <div className="row">
                                            {cat.name}
                                        </div>
                                    </li>
                                    {
                                        cat.subcategories && cat.subcategories.map((subcat,subcatid) => 
                                            <li className="li-no-bullet" key={subcatid} >
                                                <i className={( subcat.externalResourcesPath ? subcat.externalResourcesPath[0] : "" ) + " mr-1"}></i>
                                                <input type="checkbox" className="mr-3" checked={subcat.checked} onChange={() => setSubtype(cat.name,subcat.name) } /> {subcat.name}
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        )
                    }
                </div>

                <Form.Group>
                    <InputLabel className="text-dark" >Title</InputLabel>
                    <Form.Control value={title} onChange={(e) => setTitle(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <InputLabel className="text-dark" >Description</InputLabel>
                    <Form.Control as="textarea" value={description} onChange={(e) => setDescription(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Check type="checkbox" checked={isPublic} onChange={() => setIsPublic(!isPublic) } label="Public" />
                </Form.Group>

                {
                    furtherReadingLinks && furtherReadingLinks.map((link,linkid) => 
                        <div className="row" key={linkid} >
                            <div className="col-sm-6">
                                <Form.Group>
                                    <InputLabel className="text-dark" >Link title</InputLabel>
                                    <Form.Control value={link.text} onChange={(e) => setLinkProperty(linkid,"text",e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="col-sm-4">
                                <Form.Group>
                                    <InputLabel className="text-dark" >URL</InputLabel>
                                    <Form.Control value={link.url} onChange={(e) => setLinkProperty(linkid,"url",e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="col-sm-2">
                                {
                                    furtherReadingLinks.length > 1 ? (
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <SuccessButton style="mr-1 mt-4" onClick={() => addLink()} icon={<i className="fas fa-plus"></i>} />
                                            </div>
                                            <div className="col-sm-6">
                                                <DangerButton onClick={() => removeLink(linkid)} icon={<i className="fas fa-minus"></i>} />
                                            </div>
                                        </div>
                                    ) : (
                                        <SuccessButton style="mr-1 mt-4" onClick={() => addLink()} icon={<i className="fas fa-plus"></i>} />
                                    )
                                }
                            </div>
                        </div>
                    )
                }

                <Form.Group>
                    <InputLabel className="text-dark" >Highlights</InputLabel>
                    <Form.Control as="textarea" value={highlights} onChange={(e) => setHighlights(e.target.value)} />
                </Form.Group>

                <Form.Group>
                    <InputLabel className="text-dark" >Implementation time</InputLabel>
                    <Form.Control value={implementationTime} onChange={(e) => setImplementationTime(e.target.value)} />
                </Form.Group>

                <Form.Group>
                    <InputLabel className="text-dark" >Required experience</InputLabel>
                    <Form.Control value={requiredExperience} onChange={(e) => setRequiredExperience(e.target.value)} />
                </Form.Group>

                <div className="row justify-content-center mt-5 mb-5">
                    <SuccessButton onClick={deploy} label="Order" />
                </div>
            </div>
        </>
    );
}