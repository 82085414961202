import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import InputLabel from '@material-ui/core/InputLabel';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import CodeButton from './Common/CodeButton.js';
import { useHistory, Link } from 'react-router-dom';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import getCategories from './Functions/getCategories.js';
import PageTitle from './Common/pageTitle.js';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
const Request = require('./Config/Request.js');


export default function SmartContractWizard(props) {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const blockchainId = props.props.match.params.blockchainId;
    const projectId = props.props.match.params.projectId;
    let history = useHistory();
    let [smartContracts, setSmartContracts] = useState(null);
    let [librarySmartContracts, setLibrarySmartContracts] = useState([]);
    let [errorCode, setErrorCode] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    let [smartContract, setSmartContract] = useState(null);
    let [isEditing, setEditing] = useState(false);
    let [isDeleting, setDeleting] = useState(false);
    let [scToDeploy, setSCToDeploy] = useState(null);
    let [applicationIds, setApplicationIds] = useState([]);
    let [smartContractCode, setSmartContractCode] = useState({ code: "", file: null });
    let [categories, setCategories] = useState(null);
    let [categoryFilter, setCategoryFilter] = useState([]);
    let [displayCategories, setDisplayCategories] = useState(false);
    let [displayOptions, setDisplayOptions] = useState(false);
    let [displayLibrary, setDisplayLibrary] = useState(false);
    let [popperOpen, setPopperOpen] = useState(false);
    let [popperPlacement, setPopperPlacement] = useState();
    let [popperAnchorEl, setPopperAnchorEl] = useState(null);
    let [popperContent, setPopperContent] = useState([]);
    let [librarySmartContractPlugins,setLibrarySmartContractPlugins] = useState([]);
    let [keywords,setKeywords] = useState([]);


    let hideSmartContractCode = () => {
        setSmartContractCode({ code: "", file: null });
    }

    let getProjectBundles = async () => {
        try {
            let theBundles = await Request.h64HttpRequest("GET", "/getProjectBundles/" + projectId, {});
            return theBundles.bundles || [];
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    let getBlockchain = async (blockchainId) => {
        try {
            let blockchainInfos = await Request.h64HttpRequest("GET", "/myBlockchain/" + blockchainId, {});
            return blockchainInfos.blockchain || {};
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    let deploySmartContract = async () => {
        try {
            let _scToDeploy = scToDeploy, scForDeploy = { size: 0, id: null };
            for (let i = 0, l = _scToDeploy.smartContracts.length; i < l; ++i)
                if (JSON.stringify(_scToDeploy.smartContracts[i]).length > scForDeploy.size) {
                    scForDeploy.size = JSON.stringify(_scToDeploy.smartContracts[i]).length;
                    scForDeploy.id = _scToDeploy.smartContracts[i]._id;
                }

            let deployParameters = [];
            for (let i = 0, l = _scToDeploy.smartContracts.length; i < l; ++i)
                if (_scToDeploy.smartContracts[i]._id === scForDeploy.id) {
                    for (let j = 0, k = _scToDeploy.smartContracts[i].constructorParameters.length; j < k; ++j)
                        deployParameters.push(_scToDeploy.smartContracts[i].constructorParameters[j].value);
                }

            _scToDeploy.deploymentParameters = deployParameters;


            let deploy = await Request.h64HttpRequest("POST", "/v1/deployContracts/deploySmartContract", {
                ...scToDeploy,
                ...{ applicationId: projectId }
            });
            setErrorCode(deploy.errorCode);
            setErrorMessage(deploy.errorMessage);
            if (deploy.errorCode === null && deploy.errorMessage === null && deploy.smartContract)
                history.push({
                    pathname: '/smartContracts/' + blockchainId + "/" + projectId,
                    state: { errorCode: null, errorMessage: null, successMessage: "Your new smart contract is now available on the project smart contracts dashboard." }
                })
        } catch (err) {
            setErrorCode(404);
            setErrorMessage("Internal error");
            return;
        }
    }
    
    let getLibrarySmartContractsPlugins = async () => {
        try {
            let smartContracts = await Request.h64HttpRequest("GET", "/getLibrarySmartContractsPlugins", {});
            return smartContracts.smartContracts || [];
        } catch (err) {
            console.error(err);
        }
    }

    let getLibrarySmartContracts = async () => {
        try {
            let smartContracts = await Request.h64HttpRequest("GET", "/getLibrarySmartContracts", {});
            return smartContracts.smartContracts || [];
        } catch (err) {
            console.error(err);
        }
    }

    let hideDeploy = () => {
        setSCToDeploy(null);
    }

    let setSCToDeployProperty = (smartContractId, constructorParam, value) => {
        try {
            let newSc = scToDeploy.smartContracts;
            for (let i = 0, l = newSc.length; i < l; ++i)
                if (newSc[i]._id == smartContractId)
                    for (let j = 0, k = newSc[i].constructorParameters.length; j < k; ++j)
                        if (newSc[i].constructorParameters[j].codeName === constructorParam.codeName)
                            newSc[i].constructorParameters[j].value = value;
            setSCToDeploy({ ...scToDeploy, smartContracts: newSc });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        try {
            const fetch = async () => {
                let theBundles = await getProjectBundles();
                setSmartContracts(theBundles);
                let cores = await getLibrarySmartContracts();
                let plugins = await getLibrarySmartContractsPlugins();
                setLibrarySmartContracts([ ...cores, ...plugins ]);
                let typesSubtypes = await getCategories();
                setCategories(typesSubtypes);
                setLibrarySmartContractPlugins(plugins);
                props.setBlockchain(blockchainId);
                props.setProject(projectId);
                let blockchainInfos = await getBlockchain(blockchainId);
                setKeywords(blockchainInfos.keywords);
            }
            fetch();
        } catch (err) {
            console.error(err);
        }
    }, []);

    let formatSubtypesTitle = (subtypes) => {
        if (typeof subtypes === "string")
            return subtypes;
        if (typeof subtypes === "object")
            if (subtypes.length <= 1)
                return subtypes[0];
            else {
                let toReturn = "";
                for (let i = 0, l = subtypes.length; i < l; ++i)
                    toReturn += subtypes[i] + (i < l - 1 ? "," : "");
                return toReturn;
            }
    }

    let getBlockchainCreationDate = (row) => {
        try {
            return <>{moment(row.creationDate).format("L HH:mm:ss")}</>
        } catch (err) {
            console.error(err);
            return <></>;
        }
    }

    let handlePopperClick = (newPlacement: PopperPlacementType,content) => (
        event: React.MouseEvent<HTMLButtonElement>,
      ) => {
            setPopperAnchorEl(event.currentTarget);
            setPopperOpen((prev) => popperPlacement !== newPlacement || !prev);
            setPopperPlacement(newPlacement);
            setPopperContent(content);
      };
  

    let handleCategory = (category) => {
        let oldCategories = categoryFilter;
        let newCategories = [];
        if (oldCategories.indexOf(category) !== -1) {
            for (let i = 0, l = oldCategories.length; i < l; ++i)
                if (oldCategories[i] !== category)
                    newCategories.push(oldCategories[i]);
        } else {
            newCategories = oldCategories;
            newCategories.push(category);
        }
        setCategoryFilter(categoryFilter => ([...newCategories]));
    }

    let handleSubcategoryFilter = (subcategory) => {
        if (categoryFilter.length === 0)
            return true;
        if (typeof subcategory === "string")
            return categoryFilter.indexOf(subcategory) !== -1;
        if (typeof subcategory === "object")
            for (let i = 0, l = subcategory.length; i < l; ++i)
                if (categoryFilter.indexOf(subcategory[i]) !== -1)
                    return true;
    }

    let generatePluginsData = (plugins) => {
        let toReturn = [];
        if ( plugins )
            for ( let j = 0 , k = plugins.length ; j < k ; ++j )
                for ( let i = 0 , l = librarySmartContractPlugins.length ; i < l ; ++i )
                    if ( String(librarySmartContractPlugins[i]._id) === String(plugins[j]) )
                        toReturn.push(librarySmartContractPlugins[i]);
        return toReturn;
    }

    let handleKeywordsFilter = (inputKeywords) => {
        if ( !inputKeywords )
            return false;
        if ( !keywords )
            return false;
        let totalFound = 0 , minimalToFind = Math.min(inputKeywords.length,keywords.length-1);
        for ( let i = 0 , l = inputKeywords.length ; i < l ; ++i )
            for ( let j = 0 , k = keywords.length ; j < k ; ++j )
                if ( keywords[j] === inputKeywords[i] ){
                    ++totalFound;
                    break;
                }
        return totalFound >= minimalToFind;
    }

    let verifyPlugins = (plugins) => {
        if ( plugins )
            for ( let i = 0 , l = plugins.length ; i < l ; ++i )
                if ( plugins[i].length > 7 )
                    return true;
        return false;
    }

    return (
        <>
            <PageTitle title="Smart Contracts Wizard" />

            <Popper open={popperOpen} anchorEl={popperAnchorEl} placement={popperPlacement} transition>
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                    <div className="container p-3">
                        <h4>For plugins</h4>
                        <div>
                            {
                                popperContent.map((plugin,id) => 
                                    <p key={id} >Smart contract "{plugin.title}" : {plugin.description}</p>
                                )
                            }
                        </div>
                    </div>
                    </Paper>
                </Fade>
                )}
            </Popper>

            <div className="container mt-3 ml-2">

                <div className="pb-2 mt-3 mb-3 border-bottom" onClick={() => setDisplayCategories(!displayCategories)}>
                    <span className="text-mid-1">
                        Filter by use case
                    </span>
                    <span className="sc-chevron-down ml-2 float-none"  >
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            {
                                displayCategories ? (
                                    <KeyboardArrowUpIcon/>
                                ) : (
                                    <KeyboardArrowDownIcon/>
                                )
                            }
                        </IconButton>
                    </span>
                </div>

                <div className="container">
                    <div className="row">
                        {
                            displayCategories && categories && categories.map((cat, catid) =>
                                <div className="col-3" key={catid} >
                                    <ul className="list-infos">
                                        <li className="text-uppercase text-color60 mb-1">
                                            <div className="row">
                                                {cat.name}
                                            </div>
                                        </li>
                                        {
                                            cat.subcategories && cat.subcategories.map((subcat, subcatid) =>
                                                <li key={subcatid} >
                                                    <i className={(subcat.externalResourcesPath ? subcat.externalResourcesPath[0] : "") + " mr-1 ico-sc-width"}></i>
                                                    <input type="checkbox" className="mr-1" checked={categoryFilter.indexOf(subcat.name) !== -1} onChange={() => handleCategory(subcat.name)} /> {subcat.name}
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            )
                        }
                    </div>
                </div>

               
                <div className="mt-5 border-bottom" onClick={() => setDisplayLibrary(!displayLibrary)} >
                    <span className="text-mid-1">
                        Smart Contracts Library
                    </span>
                    <span className="sc-chevron-down ml-2 float-none"  >
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            {
                                displayLibrary ? (
                                    <KeyboardArrowUpIcon/>
                                ) : (
                                    <KeyboardArrowDownIcon/>
                                )
                            }
                        </IconButton>
                    </span>
                    
                </div>
                <div>Our smart contract library is composed of 
                    Core
                    and 
                     PlugIn
                      Smart contracts 
                </div>
                {
                    displayLibrary && 
                    <div className="row">
                        {
                            typeof librarySmartContracts !== "undefined" && librarySmartContracts.map((sc, id) =>
                                <div key={id}>
                                    {
                                        handleSubcategoryFilter(sc.subtype) && sc.smartContracts.length > 0 && handleKeywordsFilter(sc.keywords) && 
                                        <div className="card-smart m-2 overflow-hidden" >
                                            <Link 
                                                to={"/smartContract/" + blockchainId + "/" + projectId + "/" + sc._id + "/documentation"} 
                                                className="sc-badge-help "
                                            >
                                                <i className="fas fa-search"></i>
                                            </Link>
                                            <CodeButton 
                                                classInjector={"sc-badge-code"} 
                                                i18={props.t} 
                                                smartContracts={sc.smartContracts} 
                                                templateId={sc._id} 
                                                creationDate={new Date(sc.creationDate).getTime()} 
                                                bundleId={sc._id} 
                                            ></CodeButton>
                                            {
                                                verifyPlugins(sc.forPlugins) && 
                                                <Button className="sc-badge-plugins" onClick={handlePopperClick('bottom',generatePluginsData(sc.forPlugins))} >
                                                    <i className="fas fa-code-branch" ></i>
                                                </Button>
                                            }
                                            
                                            <div className="card-body overflow-hidden">
                                                <div className="card-title">{sc.title} </div>
                                                <div className="card-text">{sc.description}</div>
                                            </div>
                                            <div className="card-footer border-0">
                                                <div className="w-100  border-top mt-1" >
                                                    <Badge pill variant="warning" title={formatSubtypesTitle(sc.subtype)} >
                                                        {
                                                            sc.subtype && 
                                                            <>
                                                                {
                                                                    String(sc.subtype).indexOf(",") === -1 ? (
                                                                        <>{String(sc.subtype)}</>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                String(sc.subtype).split(",").length > 1 ? (
                                                                                    <>{String(sc.subtype).split(",")[0] || ""}</>
                                                                                ) : (
                                                                                    <>{String(sc.subtype).split(",")[0] + ", +" + (String(sc.subtype).split(",").length -1) + " more"}</>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        }
                                                    </Badge>
                                                </div>
                                                <div className="w-100" >
                                                    <Link 
                                                        className="btn-card-sumbit mx-auto mt-2 mb-1" 
                                                        to={
                                                            {
                                                                pathname : "/smartContractWizard/" + blockchainId + "/" + projectId + "/" + sc._id,
                                                                blockchainId: blockchainId,
                                                                applicationId: projectId,
                                                                smartContractId: sc._id,
                                                                smartContracts: sc.smartContracts,
                                                                smartContract: sc
                                                            }
                                                        }
                                                    >
                                                        <i className="fa fa-arrow-circle-up"></i> Deploy
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div>
                            )
                        }
                    </div>
                }

                {
                    scToDeploy !== null &&
                    <Modal show={scToDeploy !== null} onHide={hideDeploy}>
                        <Modal.Header closeButton className="border-0" >
                            <Modal.Title>Deploy</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="border-0" >

                            {
                                typeof scToDeploy.smartContracts !== "undefined" && scToDeploy.smartContracts.map((dp, id) =>
                                    <div key={id}>
                                        {
                                            dp.constructorParameters.length > 0 &&
                                            <div key={id} className="container mt-5 mb-5">
                                                <InputLabel className="text-dark font-weight-bold" >{dp.name}</InputLabel>
                                                {
                                                    dp.constructorParameters.map((cp, cpid) =>
                                                        <Form.Group className="mt-4" key={cpid} >
                                                            <InputLabel className="text-dark" >{cp.displayName}</InputLabel>
                                                            <Form.Control value={cp.value} onChange={(e) => setSCToDeployProperty(dp._id, cp, e.target.value)} />
                                                        </Form.Group>
                                                    )
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            }

                        </Modal.Body>
                        <Modal.Footer className="border-0" >
                            <Button className="btn-cancel-sumbit mr-3" onClick={hideDeploy}>Cancel</Button>
                            <Button className="btn-ok-submit" onClick={deploySmartContract} >Deploy</Button>
                        </Modal.Footer>
                    </Modal>
                }

                {
                    typeof smartContractCode.code !== "undefined" &&
                    <Modal show={smartContractCode.code.length > 0} onHide={hideSmartContractCode}>
                        <Modal.Body>

                            <div>
                                <code>
                                    {smartContractCode.code && smartContractCode.code.map((line, lineId) =>
                                        <div key={lineId} >
                                            {
                                                line && line.length > 0 &&
                                                <>
                                                    {line}<br />
                                                </>
                                            }
                                        </div>
                                    )}
                                </code>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="float-left" onClick={hideSmartContractCode}>Close</Button>
                            <a className="btn btn-primary ml-2" href={smartContractCode.file} download={smartContractCode.file} >Download</a>
                        </Modal.Footer>
                    </Modal>
                }

            </div>
        </>
    );
}