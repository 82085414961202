import React from 'react';
import Button from 'react-bootstrap/Button';


export default function DangerButton(props) {

    return (
        <Button className={"btn-del-submit " + (props.style ? props.style : "")} onClick={props.onClick} >
            {
                props.icon ? (
                    <>
                        {props.icon}
                    </>
                ) : (
                    <>
                        {props.label}
                    </>
                )
            }
        </Button>
    );
}