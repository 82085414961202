import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import SmartContractDeployNotification from './SmartContractDeployNotification.js';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import makeBlockie from 'ethereum-blockies-base64';
import { sendSocketEvent } from '../Config/Socket.js';
import Step1 from './Step1.js';
import Step2 from './Step2.js';
import Avatar from '@material-ui/core/Avatar';
const Request = require('../Config/Request.js');
const Parameters = require('../Config/Parameters.js');


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  }
}));

function getSteps() {
  return ['Configuration', 'Overview'];
}

export default function SmartContractWizard(props) {

    const classes = useStyles();
    const user = props.user;
    const [activeStep, setActiveStep] = useState(0);
    const blockchainId = props.blockchainId || props.props.match.params.blockchainId;
    const projectId = props.projectId || props.props.match.params.projectId;
    const smartContractId = props.smartContractId || props.props.match.params.smartContractId;
    const [smartContract,setSmartContract] = useState(props.props.history.location.smartContract || null);
    const [errorCode, setErrorCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [userSmartContracts, setUserSmartContracts] = useState([]);
    const [isAddingContracts, setAddingContracts] = useState(null);
    const [availableAccounts, setAvailableAccounts] = useState([]);
    const [responseReceived, setResponseReceived] = useState(false);
    const [deployRequestId, setDeployRequestId] = useState(null);
    const steps = getSteps();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    let getSmartContractsDependencies = async () => {
      let theDependencies = await Request.h64HttpRequest("PUT", "/getSmartContractDependencies", { smartContract: smartContract.smartContractId });
      if (theDependencies) {
          setErrorCode(theDependencies.errorCode);
          setErrorMessage(theDependencies.errorMessage);
          if (theDependencies.errorCode === null && theDependencies.errorMessage === null)
              return theDependencies;
      }
      return {};
    }

    let generateAvailableAccounts = () => {
        let available = [];
        for (let i = 0, l = user.blockchainAccounts.length; i < l; ++i)
          if ( user.blockchainAccounts[i] && smartContract )
            if (user.blockchainAccounts[i].blockchainId === smartContract.blockchainId)
              available.push(user.blockchainAccounts[i]);
        setAvailableAccounts(available);
    }

    let hideAddingContracts = () => {
        setAddingContracts(null);
    }

    let setSCToDeployProperty = (smartContractId, constructorParamId, value) => {
        try {
            let newSmartContracts = smartContract.smartContracts;
            if (newSmartContracts[smartContractId].constructorParameters[constructorParamId].dataType === "bool")
                newSmartContracts[smartContractId].constructorParameters[constructorParamId].value = !newSmartContracts[smartContractId].constructorParameters[constructorParamId].value;
            else
                newSmartContracts[smartContractId].constructorParameters[constructorParamId].value = value;
            setSmartContract({ ...smartContract, smartContracts: newSmartContracts });
        } catch (err) {
            console.error(err);
        }
    }

    let chooseSmartContract = (contract, scId, scName) => {
        let newSmartContract = smartContract;
        let newSmartContracts = newSmartContract.smartContracts;
        newSmartContracts[isAddingContracts.scId].constructorParameters[isAddingContracts.ctId].value = contract.address;
        setSmartContract({ ...smartContract, smartContracts: newSmartContracts });
        newSmartContract.smartContracts = newSmartContracts;
        hideAddingContracts();
    }

    let handleCustomAddress = (address) => {
        let newSmartContract = smartContract;
        let newSmartContracts = newSmartContract.smartContracts;
        newSmartContracts[isAddingContracts.scId].constructorParameters[isAddingContracts.ctId].value = address;
        setSmartContract({ ...smartContract, smartContracts: newSmartContracts });
        newSmartContract.smartContracts = newSmartContracts;
    }

    let chooseAccountToUse = (account) => {
        let newSmartContract = smartContract;
        let newSmartContracts = newSmartContract.smartContracts;
        newSmartContracts[isAddingContracts.scId].constructorParameters[isAddingContracts.ctId].value = account.accountAddress;
        setSmartContract({ ...smartContract, smartContracts: newSmartContracts });
        newSmartContract.smartContracts = newSmartContracts;
        hideAddingContracts();
    }

    let addTheAddingSmartContract = (constructor, scId, scName, ctId) => {
      let newSmartContract = smartContract;
      let newSmartContracts = newSmartContract.smartContracts;
      newSmartContracts[scId].constructorParameters[ctId].value = "";
      setSmartContract({ ...smartContract, smartContracts: newSmartContracts });
      setAddingContracts({ ...constructor, scId: scId, scName: scName, ctId: ctId });
    }

    let getUserSmartContracts = async () => {
        let userSCs = await Request.h64HttpRequest("GET", "/getBlockchainMySmartContracts/" + smartContract.blockchainId, {});
        if (userSCs) {
            setErrorCode(userSCs.errorCode);
            setErrorMessage(userSCs.errorMessage);
            if (userSCs.errorCode === null && userSCs.errorMessage === null)
                setUserSmartContracts(userSCs.smartContracts);
        }
    }

    let checkIfAnyMatchingContractsToChoose = (smartContracts, id) => {
        for (let i = 0, l = smartContracts.length; i < l; ++i)
            if (smartContracts[i].templateId === id)
                return true;
        return false;
    }

    useEffect(() => {
      try {
          const getSmartContract = async () => {
            let theSmartContract = await Request.h64HttpRequest("GET","/getBundle/" + smartContractId,{});
            if ( theSmartContract ){
              if ( theSmartContract.errorCode !== undefined && theSmartContract.errorMessage !== undefined ){
                setErrorMessage(theSmartContract.errorMessage);
                if ( theSmartContract.errorCode === null && theSmartContract.errorMessage === null )
                  setSmartContract(theSmartContract.bundle);
              }
            }
          }
          if ( !smartContract )
            getSmartContract();
      } catch (err) {
          console.error(err);
      }
    }, []);
  
    const totalSteps = () => {
      return getSteps().length;
    };


    const isStepOptional = (step) => {
      return step === 1;
    };

    const deploySmartContract = () => {
      let theDeployRequestId = Parameters.uuidv4();
      setDeployRequestId(theDeployRequestId);
      sendSocketEvent({
        method : "/v1/smartcontract/deploy",
        projectId : projectId,
        smartContractId : smartContractId,
        blockchainId : blockchainId,
        smartContract : smartContract,
        requestId : theDeployRequestId
      });
    }

    let getStepContent = () => {
      if ( smartContract )
        switch (activeStep) {
          case 0: 
            return <Step1 
              smartContract={smartContract} 
              smartContractId={smartContractId} 
              blockchainId={blockchainId} 
              projectId={projectId} 
              setSCToDeployProperty={setSCToDeployProperty}
              addTheAddingSmartContract={addTheAddingSmartContract}
            />;
          case 1:
            return <Step2
              smartContract={smartContract} 
              smartContractId={smartContractId} 
              blockchainId={blockchainId} 
              projectId={projectId}  
            />;
        }
    }

    const formatSubtypesTitle = (subtypes) => {
      if (typeof subtypes === "string")
          return subtypes;
      if (typeof subtypes === "object")
          if (subtypes.length <= 1)
              return subtypes[0];
          else {
              let toReturn = "";
              for (let i = 0, l = subtypes.length; i < l; ++i)
                  toReturn += subtypes[i] + (i < l - 1 ? "," : "");
              return toReturn;
          }
    }

  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const handleNext = async () => {
      setActiveStep(activeStep+1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };

    const watchNotificationsForSmartContractDeploy = () => {
      if ( user )
        if ( user.notifications )
          for ( let i = 0 , l = user.notifications.length ; i < l ; ++i ) {
            if ( user.notifications[i] )
              if ( user.notifications[i].requestId !== undefined )
                if ( user.notifications[i].requestId === deployRequestId )
                  return user.notifications[i].bundleId;
          }
      return null;
    }

    return (
      <>
        <div className="page-header  m-0 p-0 w-100 d-flex">
            <div className="w-100 page-header-title">Smart Contract Wizard</div>
        </div>

        {
          watchNotificationsForSmartContractDeploy() ? (
            <div className={classes.root}>
              <SmartContractDeployNotification blockchainId={blockchainId} projectId={projectId} smartContractId={watchNotificationsForSmartContractDeploy()} />
            </div>
          ) : (
            <div className={classes.root}>
              <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                  {steps.map((label, index) => {
                  const stepProps = {};
                  const buttonProps = {};
    
                  /*
                  if (isStepOptional(index)) {
                      buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                  }
                  */
    
                  return (
                      <Step key={label} {...stepProps}>
                      <StepButton
                          onClick={handleStep(index)}
                          completed={index < activeStep}
                          {...buttonProps}
                      >
                          {label}
                      </StepButton>
                      </Step>
                  );
                  })}
              </Stepper>
            <div>
              <div>
                {getStepContent()}
                <div className="row mt-5 mb-5">
                  <div className="col-sm-6 justify-content-center text-center">
                    <Button 
                      disabled={activeStep === 0} 
                      onClick={handleBack} 
                      className={classes.button + " mr-3"}
                      size="large"
                    >
                      Back
                    </Button>
                  </div>
                    <div className="col-sm-6 justify-content-center text-center">
                      {
                        isLastStep() ? (
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={deploySmartContract}
                            className={classes.button}
                          >
                            Deploy
                            <ArrowForwardIosIcon/>
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleNext}
                            className={classes.button}
                          >
                            Next
                          </Button>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }

          <Dialog
            fullScreen={fullScreen}
            open={errorMessage !== null}
            onClose={() => setErrorMessage(null)}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">Oops, we have a problem</DialogTitle>
            <DialogContent>
              <DialogContentText>
                  {errorMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setErrorMessage(null)} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Modal show={isAddingContracts !== null} onHide={hideAddingContracts}>
            <Modal.Body className="border-0">
                <div className="justify-content-center">
                    <div className="h4 mb-5 justify-content-center text-center" >
                        Please select the smart contract to use
                    </div>
                    {
                        isAddingContracts && userSmartContracts && userSmartContracts.length > 0 && checkIfAnyMatchingContractsToChoose(userSmartContracts, isAddingContracts.value) ? (
                            <>
                                {
                                    userSmartContracts.map((userSC, uscid) =>
                                        <div key={uscid} >
                                            {
                                                userSC.templateId === isAddingContracts.value &&
                                                <div className="card-smart m-2" >
                                                    <div className="card-body overflow-scroll">
                                                        <div className="card-title">{userSC.title} </div>
                                                        <p className="card-text">{userSC.description}</p>
                                                    </div>
                                                    <div className="card-footer border-0 overflow-scroll">
                                                        <div className="w-100  border-top pt-1" >
                                                            <Badge pill variant="warning" title={formatSubtypesTitle(userSC.subtype)} >
                                                                {
                                                                    userSC.subtype && typeof userSC.subtype === "string" &&
                                                                    <>{userSC.subtype}</>
                                                                }
                                                                {
                                                                    userSC.subtype && typeof userSC.subtype === "object" &&
                                                                    <>
                                                                        {
                                                                            userSC.subtype.length <= 1 ? (
                                                                                <>{userSC.subtype[0] || ""}</>
                                                                            ) : (
                                                                                    <>{userSC.subtype[0] + ", +" + (userSC.subtype.length - 1) + " more"}</>
                                                                                )
                                                                        }
                                                                    </>
                                                                }
                                                            </Badge>
                                                        </div>
                                                        <div className="w-100" >
                                                            {
                                                                userSC.smartContracts && userSC.smartContracts.map((usc, uscId) =>
                                                                    <div className="row">
                                                                        <Button className="btn-card-sumbit  mx-auto mt-2" onClick={() => chooseSmartContract(userSC, uscId, usc.name)} >
                                                                            <i className="fas fa-plus"></i> {usc.name}
                                                                        </Button>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            </>
                        ) : (
                          <div class="alert alert-primary justify-content-center text-center" role="alert">
                              Looks like you have no deployed smart contracts to match the requirements for this smart contract.
                          </div>
                        )
                    }
                    <div className="h4 mt-5 justify-content-center text-center" >
                        Or maybe one of your accounts ?
                    </div>

                    <div className="row justify-content-center text-center">
                      {
                        user && user.blockchainAccounts && user.blockchainAccounts.map((bacc, baccId) =>
                          <>
                              {
                                bacc && 
                                <>
                                    {
                                      bacc.blockchainId === blockchainId &&
                                      <div key={baccId} className="card-smart-mini m-2 justify-content-center text-center" onClick={() => chooseAccountToUse(bacc)}  >
                                        <div className="row justify-content-center text-center mt-4">
                                          <Avatar alt={bacc.accountAddress} src={makeBlockie(bacc.accountAddress)} className={classes.large} />
                                        </div>
                                        <div className="card-body overflow-scroll">
                                          <div className="card-title">{bacc.accountAddress} </div>
                                        </div>
                                      </div>
                                  }
                                </>
                              }
                            </>
                          )
                      }
                    </div>

                    <div className="h4 mt-5 justify-content-center text-center" >
                        Or maybe a custom address ?
                        <div className={classes.root + " mt-3"}>
                            <TextField
                                id="outlined-basic"
                                label="Add it here"
                                variant="outlined"
                                error={
                                    isAddingContracts && (smartContract.smartContracts[isAddingContracts.scId].constructorParameters[isAddingContracts.ctId].value.startsWith("0x") || smartContract.smartContracts[isAddingContracts.scId].constructorParameters[isAddingContracts.ctId].value.length < 2) ?
                                        false
                                        :
                                        true
                                }
                                value={
                                    isAddingContracts ?
                                        smartContract.smartContracts[isAddingContracts.scId].constructorParameters[isAddingContracts.ctId].value
                                        :
                                        ""
                                }
                                onChange={(e) => handleCustomAddress(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-0" >
                <Button className="float-left" onClick={hideAddingContracts}>Close</Button>
            </Modal.Footer>
        </Modal>

      </>
    );

}