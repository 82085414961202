import React from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';


export default function Step2(props) {

    const smartContract = props.smartContract;

    return (
        <div className="container d-flex flex-column mt-5 mb-5 justify-content-center ">
            <div className="mx-auto card-project-review ">

                <div className="mt-0 mb-3 card-title mx-auto">
                    <AssignmentIcon/>
                    <span className="ml-2">{smartContract.title}</span>
                </div>
                    
                <div>
                    <p>Description : <span className="review-title">{smartContract.description}</span></p>
                    <p>Included Smart Contracts : <span className="review-title">{smartContract.smartContracts.length}</span></p>
                </div>

            </div>
        </div>
    );

}