import React from 'react';
import Button from 'react-bootstrap/Button';


export default function VanillaButton(props) {

    return (
        <Button className={props.style} onClick={props.onClick} title={props.title} >
            {
                props.icon ? (
                    <>
                        {props.icon}
                    </>
                ) : (
                    <>
                        {props.label}
                    </>
                )
            }
        </Button>
    );
}