import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';


export default function PopoverWrapper(props) {

    const [anchorEl,setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const anchorOrigin = props.anchorOrigin || {
        vertical: 'bottom',
        horizontal: 'center'
    };
    const transformOrigin = props.transformOrigin || {
        vertical: 'top',
        horizontal: 'center'
    };
    const content = props.content;
    const icon = props.icon;
    const iconProps = props.iconProps ? props.iconProps : {
        "color" : "primary",
        "aria-label" : "Get HTTPS request example",
        "component" : "span" 
    }

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    }

    return (
        <>
            <IconButton 
                {...iconProps}
                onClick={handleClick} 
            >
                {icon}
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                {content}
            </Popover>
        </>
    );
}