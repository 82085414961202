import React, { useState } from 'react';
import { Link } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SuccessButton from './Common/SuccessButton.js';
const Request = require('./Config/Request.js');


function SignIn(props) {

    sessionStorage.clear();
    localStorage.clear();
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [remember, setRemember] = useState(false);

    let handleSubmit = async () => {
        try {
            if (password.length === 0) {
                props.setError("Please input the password");
                return;
            }

            if (email.length === 0) {
                props.setError("Please input the email");
                return;
            }

            let login = await Request.httpRequest("PUT", "/login", {
                email : email , 
                password : password
            });
            if (typeof login.errorCode !== "undefined" && typeof login.errorMessage !== "undefined") {
                props.setError(login.errorMessage);
                let user;
                if (login.errorCode === null && login.errorMessage === null) {
                    user = {
                        name : login.name, 
                        key : login.key,
                        blockchainAccounts : login.blockchainAccounts,
                        email : email,
                        h64AccountId : login.h64AccountId,
                        notifications : login.notifications
                    };
                    if ( remember ) {
                        localStorage.setItem('user', JSON.stringify(user));
                    } else {
                        sessionStorage.setItem('user', JSON.stringify(user));
                    }
                    sessionStorage.setItem('notifications', JSON.stringify(user.notifications));
                    window.location.href = "/home";
                }
                Request.httpRequestLog(typeof user !== "undefined" ? user._id : undefined, "SignIn", login.errorCode === null && login.errorMessage === null ? "Success" : "Error", email, password);
            }

        } catch (err) {
            console.error(err);
        }

    }

    return (
        <>
            <div id="bah-corner" ></div>
            <div className="rect-bg-1" ></div>
            <div className="dot-plain-1"></div>

            <div className="container mt-big mb-5">
                <div className="row mt-5">
                    <div className="col-md-6 col-sm-12 pl-1 pr-1">
                        <div className="title-20 mt-4">
                            <div className="title-39 mt-4">
                                Encountering difficulties to deliver blockchain solutions at the speed and price
                            business demands ?
                            </div>
                            <div className="title-16 mt-4">
                                The  Blockadhoc platform allows you to configure and deploy a complete and customized Blockchain
                                solution with no need to dedicate significant time, talent and R&D costs.
                            </div>

                            <div className="title-20 mt-4">
                                <span className="title-bold">Join below and get early access to Blockcadhoc </span>
                            </div>
                        </div>

                        <div className="mt-3 mb-3">
                            <div className="input-group">
                                <input type="email" className="form-control join-us" placeholder="Add your email..." />
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="btn-join-addon"><i className="fas fa-chevron-right"></i></span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6 col-sm-12">
                        <form className="form-sign-in" >
                            <div className="login-title mb-3 ">Sign in</div>
                            <div className="form-group">
                                <label className="control-label" >E-mail</label>
                                <div className="input-group">
                                    <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>

                            </div>
                            <div className="form-group">
                                <label className="control-label" >Password</label>
                                <div className="input-group">
                                    <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            </div>

                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={remember}
                                    onChange={() => setRemember(!remember)}
                                    color="primary"
                                />
                                }
                                label="Keep me signed in"
                            />

                            <div className="row justify-content-center text-center">
                                <SuccessButton onClick={handleSubmit} style="mt-1 mb-5 w-100" label="Sign in" />
                            </div> 


                                <p className="text-center" >
                                    <Link to={"/forgotPassword"} className="card-link" >
                                        Forgot your password?
                                    </Link>
                                </p>


                                <p className="text-center" >
                                    <Link to={"/signup"} className="card-link" >
                                        New here? Create an account
                                    </Link>
                                </p>


                        </form>

                    </div>

                </div>
            </div>
        </>
    );

}

export default SignIn;
