import React, { useState } from 'react';
import { Link } from "react-router-dom";
const Request = require('./Config/Request.js');


function ForgotPassword(props) {

    sessionStorage.clear();
    localStorage.clear();
    let [email,setEmail] = useState("");
    let [errorCode,setErrorCode] = useState(null);
    let [errorMessage,setErrorMessage] = useState(null);

    let handleSubmit = async (event) => {
        try{
            event.preventDefault();
            let resetPassword = await Request.httpRequest("PATCH","/resetPassword",{ email : email });
            setErrorCode(resetPassword.errorCode);
            setErrorMessage(resetPassword.errorMessage);
            if ( resetPassword.errorCode === null && resetPassword.errorMessage === null )
                document.location.href = "/SignIn";
        }catch(err){
            console.error(err);
        }
    }

        return (
            <>
                <div id="bah-corner" ></div>
                <div className="rect-bg-1" ></div>
                <div className="dot-plain-1"></div>
                <div className="container mt-5 mb-5">

                    <div className={"container mt-5 mb-5" + ( errorCode === null && errorMessage === null ? " d-none" : "")}>
                        <div className="alert alert-danger" role="alert">{errorMessage}</div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-12 pl-5 pr-5">

                            <div className="title-big">
                                <span className="title-bold">Blockadhoc </span>plateforme Blockchain collaborative
                        </div>
                            <div className="title-medium mt-4">
                                One account to launch and scale
                                decentralized networks and applications.
                        </div>

                            <div className="mt-5"></div>
                        </div>
                        <div className="col-md-6 col-sm-12">

                            <form onSubmit={handleSubmit} className="form-sign-in">
                            <div className="login-title mt-3 mb-4 ">Reset your password</div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <span className="input-group-addon"><i className="glyphicon glyphicon-envelope color-blue"></i></span>
                                        <input placeholder="Your email address" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" type="email" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input className="btn-form-sumbit mt-5 mb-5" value="Submit" type="submit" />
                                </div>

                                <div className="form-group">
                                    <p className="text-right" >Already have an account? <Link to={"/signin"} className="card-link" >Sign in</Link></p>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </>
        );

}

export default ForgotPassword;
