import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { Link, useHistory , useParams } from 'react-router-dom';
import PageTitle from './Common/pageTitle.js';
import getCategories from './Functions/getCategories.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CancelButton from './Common/CancelButton.js';
import SuccessButton from './Common/SuccessButton.js';
import DangerButton from './Common/DangerButton.js';
import { sendSocketEvent } from './Config/Socket.js';
import SmartContractDeployNotification from './SmartContractWizard/SmartContractDeployNotification.js';
const Parameters = require('./Config/Parameters.js');
const Request = require('./Config/Request.js');


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    }
}));

export default function DeploySmartContract(props) {

    const classes = useStyles();
    const { blockchainId, projectId } = useParams();
    const user = props.user;
    const scDescriptionMinLength = 5;
    const scTitleMinLength = 3;
    const [smartContract, setSmartContract] = useState("to get...");
    const [smartContracts, setSmartContracts] = useState("to get...");
    const [ABIs,setABIs] = useState([]);
    const [deployRequestId, setDeployRequestId] = useState(null);
    let [deploySC, setDeploySC] = useState({
        abi: "",
        bytecode: "",
        title: "",
        description: "",
        constructorParameters: [],
        errors: [],
        type: [],
        subtype: []
    });
    let [isEditing, setEditing] = useState(false);
    let [solFiles, setSolFiles] = useState([]);
    let [constructor, setConstructor] = useState(null);
    let [categories, setCategories] = useState(null);
    let [isChoosingSmartContract, setChoosingSmartContract] = useState(null);
    let history = useHistory();

    let getSmartContracts = async () => {
        let smartContracts = await Request.h64HttpRequest("GET", "/getAllLibrarySmartContracts", {});
        return smartContracts.smartContracts || [];
    }

    const options = {
        clickToEdit: true,
        clickToSelect: true,
    };

    let getBlockchainCreationDate = (cell, row) => {
        return <>{moment(row.creationDate).format("L HH:mm:ss")}</>
    }

    let chooseSmartContract = (contract) => {
        let toDeploySCConstructors = deploySC.constructorParameters;
        toDeploySCConstructors[isChoosingSmartContract.scId][isChoosingSmartContract.scName][isChoosingSmartContract.pId].chosenContract = {
            _id: contract._id,
            isPublic: contract.isPublic,
            _userAccountId: contract._userAccountId,
            type: contract.type,
            subtype: contract.subtype,
            title: contract.title,
            description: contract.description
        };
        setDeploySC({ ...deploySC, constructorParameters: toDeploySCConstructors });
        hideChoosingSmartContract();
    }

    let operations = (cell, row) => {
        return <SuccessButton onClick={() => chooseSmartContract(row)} icon={<i className="fas fa-plus"></i>} />;
    }

    useEffect(() => {
        try {
            const fetch = async () => {
                let typesSubtypes = await getCategories();
                setCategories(typesSubtypes);
                let contracts = await getSmartContracts();
                setSmartContracts(contracts);
            }
            fetch();
        } catch (err) {
            console.error(err);
        }
    }, []);

    let edit = (row) => {
        setSmartContract(row);
        setEditing(true);
    }

    let hideChoosingSmartContract = () => {
        setChoosingSmartContract(null)
    }

    let showConstructor = (sc) => {
        setConstructor(sc.constructorParameters);
    }

    let getCategorySubcategoriesBySmartContractCategoryName = () => {
        if (smartContract && smartContract.type)
            for (let i = 0, l = categories.length; i < l; ++i)
                if (categories[i].name === smartContract.type)
                    return categories[i].subcategories;
        return [];
    }

    let hideEditModal = () => {
        setEditing(false);
        setSmartContract(null);
    }

    const watchNotificationsForSmartContractDeploy = () => {
        if ( user )
          if ( user.notifications )
            for ( let i = 0 , l = user.notifications.length ; i < l ; ++i ) {
              if ( user.notifications[i] )
                if ( user.notifications[i].requestId !== undefined )
                  if ( user.notifications[i].requestId === deployRequestId )
                    return user.notifications[i].bundleId;
            }
        return null;
    }

    let deploySmartContract = async () => {
        try {

            let deploySCToSend = deploySC;
            if (deploySCToSend.description.length < scDescriptionMinLength) {
                props.setError("Description length too short , minimum " + scDescriptionMinLength + " characters");
                return;
            }
            if (deploySCToSend.title.length < scTitleMinLength) {
                props.setError("Description length too short , minimum " + scTitleMinLength + " characters");
                return;
            }
            for (let key in deploySCToSend.constructorParameters)
                for (let i in deploySCToSend.constructorParameters[key])
                    for (let j = 0, k = deploySCToSend.constructorParameters[key][i].length; j < k; ++j) {
                        if (deploySCToSend.constructorParameters[key][i][j].chosenContract) {
                            deploySCToSend.constructorParameters[key][i][j].chosenContract = deploySCToSend.constructorParameters[key][i][j].chosenContract._id;
                            if (deploySCToSend.constructorParameters[key][i][j].codeName.length === 0) {
                                props.setError("Please complete all deployment parameters values");
                                return;
                            }
                        }
                    }

            let theDeployRequestId = Parameters.uuidv4();
            setDeployRequestId(theDeployRequestId);
            let contractsToDeploy = [];
            for ( let k in ABIs )
                for ( let kk in ABIs[k] )
                    contractsToDeploy.push({ ...ABIs[k][kk] , contract: kk });

            sendSocketEvent({
                method : "/v1/smartcontract/deployByClient",
                projectId : projectId,
                blockchainId : blockchainId,
                requestId : theDeployRequestId,
                smartContracts : contractsToDeploy,
                types : deploySC.type,
                subtypes : deploySC.subtype,
                title : deploySC.title,
                description : deploySC.description,
                isPublic : smartContract.isPublic,
                files : solFiles,
                constructorParameters : deploySC.constructorParameters
            });

        } catch (err) {
            console.error(err);
            props.setError("Internal error");
            return;
        }
    }

    let handleJsonRowObj = (row) => {
        for (let i in row)
            return { scName: i, params: row[i] };
    }

    let handleChange = async (file) => {
        let fd = new FormData();
        for (let i = 0, l = file.target.files.length; i < l; ++i){
            fd.append(file.target.files[i].name, file.target.files[i], file.target.files[i].name);
            const reader = new FileReader();
            reader.onload = (e) => { 
                setSolFiles([ ...solFiles, e.target.result ]);
            }
            reader.readAsText(file.target.files[i]);
        }
        let ABI = await Request.h64HttpRequestFile("/convertToABI", fd);
        let paramsToDeploy = [];
        let errors = [];
        if ( ABI )
            if (ABI.errorCode === null && ABI.errorMessage === null) {
                //console.log(ABI.ABI);
                setABIs(ABI.ABI);
                for (let o = 0, p = ABI.ABI.length; o < p; ++o)
                    if (typeof ABI.ABI[o].error === "undefined")
                        for (let i in ABI.ABI[o]) {
                            let params = {};
                            params[i] = [];
                            for (let j = 0, k = ABI.ABI[o][i].abi.length; j < k; ++j)
                                if (ABI.ABI[o][i].abi[j].type === "constructor")
                                    for (let m = 0, n = ABI.ABI[o][i].abi[j].inputs.length; m < n; ++m)
                                        params[i].push({ codeName: ABI.ABI[o][i].abi[j].inputs[m].name, displayName: "", value: "", type: ABI.ABI[o][i].abi[j].inputs[m].type , contract: i });
                            paramsToDeploy.push(params);
                        }
                    else
                        errors.push(ABI.ABI[o]);
                setDeploySC({ ...deploySC, errors: errors, constructorParameters: paramsToDeploy });
            }
    }

    let setconstructorParameters = (obj, position, value) => {
        let currentconstructorParameters = deploySC.constructorParameters;
        for (let i in currentconstructorParameters[obj.sc])
            currentconstructorParameters[obj.sc][i][obj.id][position] = value;
        setDeploySC({ ...deploySC, constructorParameters: currentconstructorParameters });
    }

    let addLink = () => {
        setDeploySC({ ...deploySC, furtherReadingLinks: [...deploySC.furtherReadingLinks, { url: "", text: "" }] });
    }

    let removeLink = (id) => {
        let newLinks = [];
        for (let i = 0, l = deploySC.furtherReadingLinks.length; i < l; ++i)
            if (i !== id)
                newLinks.push(deploySC.furtherReadingLinks[i]);
        setDeploySC({ ...deploySC, furtherReadingLinks: newLinks });
    }

    let setLinkProperty = (index, prop, value) => {
        let theLinks = deploySC.furtherReadingLinks;
        theLinks[index][prop] = value;
        setDeploySC({ ...deploySC, furtherReadingLinks: theLinks });
    }

    let hideConstructor = () => {
        setConstructor(null);
    }

    let checkSubtype = (subtype) => {
        let subtypes = deploySC.subtype;
        for (let i = 0, l = subtypes.length; i < l; ++i)
            if (subtypes[i] === subtype)
                return true;
        return false;
    }

    let setSubtype = (type, subtype) => {
        let subtypes = deploySC.subtype, types = deploySC.type;
        let typeExists = false, subtypeExists = false;
        for (let i = 0, l = types.length; i < l; ++i)
            if (types[i] === type) {
                typeExists = true;
                types.splice(i, 1);
            }
        if (!typeExists)
            types.push(type);
        for (let i = 0, l = subtypes.length; i < l; ++i)
            if (subtypes[i] === subtype) {
                subtypeExists = true;
                subtypes.splice(i, 1);
            }
        if (!subtypeExists)
            subtypes.push(subtype);
        setDeploySC({ ...deploySC, type: types, subtype: subtypes });
    }

    let setChooseSmartContract = (param, scId, scName, pId) => {
        setChoosingSmartContract(param.type === "address" ? { ...param, scId: scId, scName: scName, pId: pId } : null);
    }

    return (
        <>
            <PageTitle title="Create Smart Contract Template" />

            {
                watchNotificationsForSmartContractDeploy() ? (
                    <div className={classes.root}>
                    <SmartContractDeployNotification blockchainId={blockchainId} projectId={projectId} smartContractId={watchNotificationsForSmartContractDeploy()} />
                    </div>
                ) : (
                    <div className="container mt-4" >

                        <div>
                            <h6>Choose use case</h6>
                        </div>

                        {
                            deploySC !== null &&
                            <div>
                                {
                                    deploySC.errors.length > 0 &&
                                    <Form.Group className="mt-5" >
                                        {
                                            deploySC.errors.map((err, eid) =>
                                                <div className="container mt-5 mb-5" key={eid} >
                                                    <div className="alert alert-danger" role="alert">{err.smartContract + " : " + err.error}</div>
                                                </div>
                                            )
                                        }
                                    </Form.Group>
                                }
                            </div>
                        }

                        <div>

                            {
                                deploySC !== null &&
                                <>

                                
                                        <div className="row">
                                            {
                                                categories && categories.map((cat, catid) =>
                                                    <div className="col-sm-3" key={catid} >
                                                        <ul className="list-infos">
                                                            <li className="text-uppercase text-color60 mb-1 li-no-bullet">
                                                                <div className="row">
                                                                    {cat.name}
                                                                </div>
                                                            </li>
                                                            {
                                                                cat.subcategories && cat.subcategories.map((subcat, subcatid) =>
                                                                    <li className="li-no-bullet" key={subcatid} >
                                                                        <FormControlLabel
                                                                            control={
                                                                                <>
                                                                                    <Checkbox
                                                                                        checked={checkSubtype(subcat.name)}
                                                                                        onChange={() => setSubtype(cat.name, subcat.name)}
                                                                                        size="small"
                                                                                        color="primary"
                                                                                    />
                                                                                    <i className={subcat.externalResourcesPath ? subcat.externalResourcesPath[0] : ""}></i>
                                                                                </>
                                                                            }
                                                                            label={subcat.name}
                                                                        />
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                )
                                            }
                                        </div>
                                

                                    <div className="container">
                                        <Form.Group>
                                            <InputLabel className="text-dark">Smart Contract Source Code</InputLabel>
                                            <Form.Control type="file" className="form-control-file" onChange={handleChange} multiple accept=".sol" />
                                        </Form.Group>

                                        <Form.Group>
                                            <InputLabel className="text-dark" >Title</InputLabel>
                                            <Form.Control value={deploySC.title} onChange={(e) => setDeploySC({ ...deploySC, title: e.target.value })} />
                                        </Form.Group>

                                        <Form.Group>
                                            <InputLabel className="text-dark" >Description</InputLabel>
                                            <Form.Control as="textarea" value={deploySC.description} onChange={(e) => setDeploySC({ ...deploySC, description: e.target.value })} />
                                        </Form.Group>

                                        <Form.Group>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={smartContract.isPublic}
                                                onChange={() => setSmartContract({ ...smartContract, isPublic: !smartContract.isPublic })}
                                                color="primary"
                                            />
                                            }
                                            label="Publish smart contract"
                                        />
                                        </Form.Group>

                                    </div>

                                    {
                                        deploySC.constructorParameters.length > 0 &&
                                        <>
                                            <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom mt-8">
                                                <h6>Constructor parameters </h6>
                                                <>( {deploySC.constructorParameters ? deploySC.constructorParameters.length : 0} smart contracts )</>
                                            </div>

                                            <div className="container">
                                                <Form.Group className="mt-5" >
                                                    <div className="mt-3">
                                                        {
                                                            deploySC.constructorParameters.map((sc, id) =>
                                                                <div key={id} className="mt-5 mb-5">
                                                                    <div>
                                                                        <h5>{handleJsonRowObj(sc).scName}</h5>
                                                                    </div>
                                                                    {
                                                                        handleJsonRowObj(sc).params.length > 0 ? (
                                                                            <div className="mt-5 mb-5">
                                                                                {
                                                                                    handleJsonRowObj(sc).params.map((param, pid) =>
                                                                                        <div className="row justify-content-center text-center" key={pid} >
                                                                                            <div className="justify-content-center text-center">
                                                                                                <div
                                                                                                    className={"card mt-3 mb-3 ml-3 mr-3 justify-content-center text-center lowPush shadow-lg border-0 " + (param.type === "address" ? "lowPush clickableCard" : "")}
                                                                                                    style={{ "width": "18rem", "height": "18rem" }}
                                                                                                    onClick={() => setChooseSmartContract(param, id, handleJsonRowObj(sc).scName, pid)}
                                                                                                >
                                                                                                    {
                                                                                                        param.type === "address" ? (
                                                                                                            <>
                                                                                                                {
                                                                                                                    typeof param.chosenContract === "undefined" && !param.chosenContract ? (
                                                                                                                        <>
                                                                                                                            <span className="badge badge-primary shadow-lg adminAddTemplateBadge">{param.codeName}</span>
                                                                                                                            <i className="fas fa-plus fa-5x text-primary"></i>
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                            <>
                                                                                                                                <p>Added , Smart Contract : </p>
                                                                                                                                <p className="font-weight-bold">{param.chosenContract.title}</p>
                                                                                                                                <i className="fas fa-file-contract fa-5x text-success"></i>
                                                                                                                            </>
                                                                                                                        )
                                                                                                                }

                                                                                                            </>
                                                                                                        ) : (
                                                                                                                <div className="container">
                                                                                                                    <div className="form-row" >
                                                                                                                        <InputLabel className="text-dark" >Code Name</InputLabel>
                                                                                                                        <input type="text" value={param.codeName} className="form-control" onChange={(e) => setconstructorParameters({ id: pid, sc: id }, "codeName", e.target.value)} readOnly />
                                                                                                                    </div>
                                                                                                                    <div className="form-row">
                                                                                                                        <InputLabel className="text-dark" >Display Name</InputLabel>
                                                                                                                        <input type="text" value={param.displayName} className="form-control" onChange={(e) => setconstructorParameters({ id: pid, sc: id }, "displayName", e.target.value)} />
                                                                                                                    </div>
                                                                                                                    <div className="form-row">
                                                                                                                        <InputLabel className="text-dark" >Value</InputLabel>
                                                                                                                        <input type="text" value={param.value} className="form-control" onChange={(e) => setconstructorParameters({ id: pid, sc: id }, "value", e.target.value)} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        ) : (
                                                                                <div className="alert alert-primary" role="alert">
                                                                                    This smart contract has no constructor parameter. Very light coded !!
                                                                                </div>
                                                                            )
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </>
                                    }

                                    <div className="container">
                                        {
                                            deploySC.errors.length > 0 &&
                                            <Form.Group className="mt-5" >
                                                <InputLabel className="text-dark" >Errors</InputLabel>
                                                {
                                                    deploySC.errors.map((err, eid) =>
                                                        <div className="container mt-5 mb-5" key={eid} >
                                                            <div className="alert alert-danger" role="alert">{err.smartContract + " : " + err.error}</div>
                                                        </div>
                                                    )
                                                }
                                            </Form.Group>
                                        }
                                    </div>
                                </>
                            }

                            <div className="container mb-8 mt-8">
                                {
                                    smartContract.smartContracts && smartContract.smartContracts.map((psc, pscId) =>
                                        <div key={pscId}>
                                            <div>
                                                <h5>{psc.name}</h5>
                                            </div>
                                            {
                                                psc.constructorParameters.length > 0 ? (
                                                    <>
                                                        <Form.Group className="mt-5" >
                                                            <div className="mt-3">
                                                                {
                                                                    psc.constructorParameters.map((sc, id) =>
                                                                        <div key={id} className="mt-5 mb-5">
                                                                            <div className="mt-5 mb-5">
                                                                                <div className="row justify-content-center text-center" >
                                                                                    <div className="justify-content-center text-center">
                                                                                        <div
                                                                                            className={"card mt-3 mb-3 ml-3 mr-3 justify-content-center text-center lowPush shadow-lg border-0 " + (sc.dataType === "address" ? "lowPush clickableCard" : "")}
                                                                                            style={{ "width": "18rem", "height": "18rem" }}
                                                                                            onClick={() => setChooseSmartContract(sc, pscId, psc.name, id)}
                                                                                        >
                                                                                            {
                                                                                                sc.dataType === "address" ? (
                                                                                                    <>
                                                                                                        {
                                                                                                            typeof sc.chosenContract === "undefined" && !sc.chosenContract ? (
                                                                                                                <>
                                                                                                                    <span className="badge badge-primary shadow-lg adminAddTemplateBadge">{sc.codeName}</span>
                                                                                                                    <i className="fas fa-plus fa-5x text-primary"></i>
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            sc.chosenContract.title ? (
                                                                                                                                <>
                                                                                                                                    <p>Added , Smart Contract : </p>
                                                                                                                                    <p className="font-weight-bold">{sc.chosenContract.title}</p>
                                                                                                                                    <i className="fas fa-file-contract fa-5x text-success"></i>
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                    <>
                                                                                                                                        <i className="fas fa-file-contract fa-5x text-success"></i>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                        }
                                                                                                                    </>
                                                                                                                )
                                                                                                        }

                                                                                                    </>
                                                                                                ) : (
                                                                                                        <div className="container">
                                                                                                            <div className="form-row" >
                                                                                                                <InputLabel className="text-dark" >Code Name</InputLabel>
                                                                                                                <input type="text" value={sc.codeName} className="form-control" onChange={(e) => setconstructorParameters({ id: id, sc: pscId }, "codeName", e.target.value)} readOnly />
                                                                                                            </div>
                                                                                                            <div className="form-row">
                                                                                                                <InputLabel className="text-dark" >Display Name</InputLabel>
                                                                                                                <input type="text" value={sc.displayName} className="form-control" onChange={(e) => setconstructorParameters({ id: id, sc: pscId }, "displayName", e.target.value)} />
                                                                                                            </div>
                                                                                                            <div className="form-row">
                                                                                                                <InputLabel className="text-dark" >Value</InputLabel>
                                                                                                                <input type="text" value={sc.value} className="form-control" onChange={(e) => setconstructorParameters({ id: id, sc: pscId }, "value", e.target.value)} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </Form.Group>
                                                    </>
                                                ) : (
                                                        <div className="alert alert-primary" role="alert">
                                                            This smart contract has no constructor parameter. Very light coded !!
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    )
                                }
                            </div>

                            <div className="container mt-5 mb-5">
                                <div className="row">
                                    <div className="col-sm-6 justify-content-center text-center">
                                        <CancelButton style="justify-content-center text-center float-right" onClick={() => history.push("/smartContractMenu/" + blockchainId + "/" + projectId)} label="Cancel" />
                                    </div>
                                    <div className="col-sm-6 justify-content-center text-center">
                                        <SuccessButton style="justify-content-center text-center" onClick={deploySmartContract} label="Deploy" />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <Modal show={isChoosingSmartContract} onHide={hideChoosingSmartContract} size="lg" >
                            <Modal.Header closeButton className="border-0" >
                                <Modal.Title>Choose the smart contract</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="overflow-wrap-anywhere border-0" >
                                <BootstrapTable data={smartContracts} keyField='_id' striped hover pagination search options={options} >
                                    <TableHeaderColumn dataField='title' dataSort={true} >Title</TableHeaderColumn>
                                    <TableHeaderColumn dataField='description' dataSort={true} >Description</TableHeaderColumn>
                                    <TableHeaderColumn dataField='type' dataSort={true} >Category</TableHeaderColumn>
                                    <TableHeaderColumn dataField='subtype' dataSort={true} >Subcategory</TableHeaderColumn>
                                    <TableHeaderColumn dataFormat={getBlockchainCreationDate} dataField='creationDate' dataSort={true}>Creation Date</TableHeaderColumn>
                                    <TableHeaderColumn dataFormat={operations} >Operations</TableHeaderColumn>
                                </BootstrapTable>
                            </Modal.Body>
                            <Modal.Footer className="border-0" >
                                <CancelButton style="float-left" onClick={hideChoosingSmartContract} label="Cancel" />
                            </Modal.Footer>
                        </Modal>

                    </div>
                )
            }
        </>
    );
}