import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
const Request = require('../Config/Request.js');


export default function Step3(props) {

    let [errorCode, setErrorCode] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    let [blockchains, setBlockchains] = useState([]);

    let getBlockchains = async () => {
        try {
            let myBlockchains = await Request.h64HttpRequest("GET", "/ownedBlockchains", {});
            setErrorCode(myBlockchains.errorCode);
            setErrorMessage(myBlockchains.errorMessage);
            console.log(myBlockchains.blockchains);
            return myBlockchains.blockchains || [];
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    let getPublicBlockchains = async () => {
        try {
            let publicBlockchains = await Request.h64HttpRequest("GET", "/publicBlockchains", {});
            console.log("Public blockchains >> " + JSON.stringify(publicBlockchains));
            setErrorCode(publicBlockchains.errorCode);
            setErrorMessage(publicBlockchains.errorMessage);
            return publicBlockchains.blockchains || [];
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    let compareKeywords = (keywordsMatched,blockchainKeywords) => {
        try {
            let found = [];
            let minimumToAccept = Math.min(keywordsMatched.length,blockchainKeywords.length);
            for ( let i = 0 , l = keywordsMatched.length ; i < l ; ++i )
                for ( let j = 0 , k = blockchainKeywords.length ; j < k ; ++j )
                    if ( blockchainKeywords[j].toLowerCase() === keywordsMatched[i].toLowerCase() )
                        found.push(blockchainKeywords[j]);
            return found.length >= minimumToAccept;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    let checkBlockchainsForMathingKeywords = () => {
        try{
            for ( let j = 0 , k = blockchains.length ; j < k ; ++j ){
                console.log(JSON.stringify(props.keywords) + " && " + JSON.stringify(blockchains[j]));
                if ( compareKeywords(props.keywords,blockchains[j].keywords) ){
                    console.log("We return true here");
                    return true;
                }
            }
            return false;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    let getOwnedBlockchains = () => {
        let owned = 0;
        for (let i = 0, l = blockchains.length; i < l; ++i)
            if (blockchains[i].owner === props.user._id)
                ++owned;
        return owned;
    }

    useEffect(() => {
        const fetchData = async () => {
            let result = await getBlockchains();
            let publicBlockchains = await getPublicBlockchains();
            setBlockchains([ ...result , ...publicBlockchains ]);
        }
        fetchData();
    }, []);

    return (
        <>
            <div className="container mt-5 mb-5" >
                <div className="text-center project-title-label">Choose your hosting</div>
                <div className="text-center project-mini-label">Not sure ? Click here to compare and make the right choice.</div>

                <div className="mt-4 mb-5">

                    {
                        checkBlockchainsForMathingKeywords() && 
                        <div className="row" >
                            <div className="col-3 project-host-label my-auto">Proof of concept:<br />learn, build and test </div>
                            <div className="col-9">

                                {
                                    blockchains.map((blockchain, id) =>
                                        <div key={id}>
                                            {
                                                compareKeywords(props.keywords,blockchain.keywords) &&  
                                                <div className={"card-hosting clickableCard " + ( props.blockchainId === blockchain._id ? "clickable shadow-lg" : "")} key={id} onClick={() => props.setBlockchain(blockchain)} >
                                                    <div className="card-body">

                                                        <div className="row ">
                                                            <div className="col-3 ">
                                                                <img src="/logo-price-free.png" alt="Ethereum" width="135" />
                                                            </div>
                                                            <div className="col-9">
                                                                <div className="project-mini-label ">Free shared blockchain</div>
                                                                <div className="project-title-label">{blockchain.name}</div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    }

                    <div className="row" >
                        <div className="col-3 project-host-label my-auto">Production Cloud</div>
                        <div className="col-9">
                            <div className="card-hosting" >
                                <div className="card-body">
                                    <div className="row ">
                                        <div className="col-3 ">
                                            <img src="/logo-price-cloud.png" alt="Ethereum" width="135" />
                                        </div>
                                        <div className="col-9">
                                            <div className="project-mini-label ">Starting from 350 euro/month/node</div>
                                            <div className="project-title-label">Amazon, Azure, OVH</div>
                                            <p className="project-badge-comingsoon mt-2">Coming soon</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-3 project-host-label my-auto">Production Hybrid</div>
                        <div className="col-9">
                            <div className="card-hosting">
                                <div className="card-body">
                                    <div className="row ">
                                        <div className="col-3 ">
                                            <img src="/logo-price-premise.png" alt="Ethereum" width="125" />
                                        </div>
                                        <div className="col-9">
                                            <div className="project-mini-label ">Starting from 250 euro/month/node</div>
                                            <div className="project-title-label">Premise</div>
                                            <p className="project-badge-comingsoon mt-2">Coming soon</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/*
                <div className="container mt-5 mb-5" >
                    <div className="mt-5 mb-5">
                        <div className="pb-2 mt-3 mb-2 border-bottom">
                            <h6>Available hosting options</h6>
                        </div>
                    </div>

                    <div className="mb-5 justify-content-center row" >

                        <div className="card ml-2 mr-2  mb-3" >
                            <img className="card-img-top state-disabled" src="/hosting-amazon.png" alt="Amaeon cloud" />
                            <div className="card-body">
                                <p className="card-text">Amazon</p>
                            </div>
                            <div className="card-footer">
                                <p className="card-text"></p>
                            </div>
                        </div>

                        <div className="card ml-2 mr-2 mb-3" >
                            <img className="card-img-top state-disabled" src="/hosting-azure.png" alt="Azure cloud" />
                            <div className="card-body">
                                <p className="card-text ">Azure</p>
                            </div>
                            <div className="card-footer">
                                <p className="card-text card-subtitle">Coming Soon</p>
                            </div>
                        </div>

                        <div className="card ml-2 mr-2 mb-3" >
                            <img className="card-img-top state-disabled" src="/hosting-ovh.png" alt="Ovh cloud" />
                            <div className="card-body">
                                <p className="card-text">OVH</p>
                            </div>
                            <div className="card-footer">
                                <p className="card-text card-subtitle">Coming Soon</p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5 mb-5 justify-content-center container" >
                        <Form.Group>
                            <InputLabel className="text-dark" >Name your blockchain</InputLabel>
                            <Form.Control value={props.blockchainName} />
                        </Form.Group>
                    </div>
                </div>
                */}


            </div>
        </>
    );

}