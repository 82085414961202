import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputLabel from '@material-ui/core/InputLabel';
import Button from 'react-bootstrap/Button';
const Request = require('./Config/Request.js');

export default function Invite(props) {

    const blockchainId = props.blockchainId;
    const projectId = props.projectId;
    let [email, setEmail] = useState("");
    let [isAddingMember,setAddingMember] = useState(false);
    let history = useHistory();

    let invite = async () => {
        try{
            let inviteUser = await Request.h64HttpRequest("POST","/v1/projects/addMember",{
                email : email ,
                projectId : projectId
            });
            if ( inviteUser )
                if ( inviteUser.errorCode === null && inviteUser) {
                    props.updateTheListWithNewUser({
                        accountAddress : inviteUser.accountAddress ,
                        key : inviteUser.key ,
                        email : email ,
                        name : email ,
                        applications : [{ "test" : true }]
                    });
                    hideAddAMemberModal();
                }
        }catch(err){
            console.error(err);
        }
    }

    let hideAddAMemberModal = () => {
        setAddingMember(false);
        setEmail("");
    }

    let activateAdd = () => {
        setAddingMember(true);
        setEmail("");
    }

    return (
        <>
        <div>
            <Button className="btn btn-success link-action-sumbit" onClick={activateAdd} >Add a member</Button>
        </div>
        <Modal show={isAddingMember} onHide={hideAddAMemberModal}>
            <Modal.Header>
                Add a member to project
            </Modal.Header>
            <Modal.Body className="mt-3 mb-3">

                <Form.Group>
                    <InputLabel className="text-dark" >Email address</InputLabel>
                    <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="float-left" onClick={hideAddAMemberModal}>Cancel</Button>
                <Button className="btn btn-success" onClick={invite} >Ok</Button>
            </Modal.Footer>
        </Modal>

        </>
    );

}